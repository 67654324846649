/* =============================================================================
 * List inline
 *
 * 1. Fallback for when flex is unsupported.
============================================================================= */

@define-mixin o-list-inline $container-query, $suffix {
	$container-query .o-list-inline$(suffix) {
		display: flex;
	}

	$container-query .o-list-inline$(suffix).o-list-inline--wrap {
		flex-wrap: wrap;
	}

	$container-query .o-list-inline$(suffix).o-list-inline--center {
		justify-content: center;
	}

	$container-query .o-list-inline$(suffix).o-list-inline--middle {
		align-items: center;
	}

	$container-query .o-list-inline$(suffix).o-list-inline--bottom {
		align-items: flex-end;;
	}

	$container-query .o-list-inline$(suffix) .o-list-inline__item {
		display: inline-block; /* [1] */
	}

	$container-query .o-list-inline$(suffix).o-list-inline--stretch .o-list-inline__item {
		flex-grow: 1;
		flex-basis: 0;
	}

	$container-query .o-list-inline$(suffix).o-list-inline--padded .o-list-inline__item + .o-list-inline__item {
		padding-left: var(--spacing--horizontal-gutter);
    }

	$container-query .o-list-inline$(suffix).o-list-inline--padded--right .o-list-inline__item {
		padding-right: var(--spacing--horizontal-gutter);
	}

	$container-query .o-list-inline$(suffix).o-list-inline--padded-tighter .o-list-inline__item + .o-list-inline__item {
		padding-left: calc(0.5 * var(--spacing--horizontal-gutter));
	}
}

@mixin o-list-inline;
@mixin o-list-inline .o-container:container(width > 300px), \@300;
@mixin o-list-inline .o-container:container(width > 500px), \@500;
@mixin o-list-inline .o-container:container(width > 700px), \@700;
@mixin o-list-inline .o-container:container(width > 900px), \@900;
@mixin o-list-inline .o-container:container(width > 1100px), \@1100;

/* =============================================================================
 * Floats
============================================================================= */
.t-float-left {
	float:left;
	margin:0 var(--spacing--horizontal-gutter) var(--spacing--vertical-spacing-unit) 0;
}

.t-float-right {
	float:right;
	margin:0 0 var(--spacing--vertical-spacing-unit) var(--spacing--horizontal-gutter);
}
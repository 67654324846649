/* =============================================================================
 * Logo
 *
 * 1. For text fallback if image doesn't load
============================================================================= */
.c-logo {
	/* [1] */
	@mixin text-style-alpha;
	margin:0 auto;
	font-weight: 400;
}
.c-logo__link,
.c-logo__link:visited {
	display:block;
	color:inherit; /* [1] */
	text-decoration:none;
}

@media (--sm) {
	.c-logo{
		max-width: 100px;
		font-size: 16px;
	}
}
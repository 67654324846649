@define-mixin c-buffer-box $container-query, $suffix {
	$container-query .c-buffer-box$(suffix) {
		padding:var(--spacing--vertical-spacing-unit) var(--spacing--horizontal-gutter);
		border: 0 solid var(--color--grey-2);
	}

	$container-query .c-buffer-box$(suffix):after {
		display: table;
		clear: both;
		content: "";
	}

	/* 1. Only grow if the box is the last (or only) child */
	$container-query .c-buffer-box$(suffix):last-child {
		flex-grow: 1; /*[1]*/
	}

	/* Disable padding for certain directions
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--no-top$(suffix) {
		padding-top: 0;
	}

	$container-query .c-buffer-box--no-right$(suffix) {
		padding-right:0;
	}

	$container-query .c-buffer-box--no-bottom$(suffix) {
		padding-bottom: 0;
	}

	$container-query .c-buffer-box--no-left$(suffix) {
		padding-left:0;
	}

	$container-query .c-buffer-box--no-padding$(suffix) {
		padding:0;
	}

	/* Set padding for a certain direction ONLY.
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--only-top$(suffix) {
		padding:var(--spacing--horizontal-gutter) 0 0;
	}

	$container-query .c-buffer-box--only-right$(suffix) {
		padding:0 var(--spacing--horizontal-gutter) 0 0;
	}

	$container-query .c-buffer-box--only-bottom$(suffix) {
		padding:0 0 var(--spacing--horizontal-gutter);
	}

	$container-query .c-buffer-box--only-left$(suffix) {
		padding:0 0 0 var(--spacing--horizontal-gutter);
	}

	/* Set extra padding
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--extra-padding$(suffix) {
		padding: calc(2 * var(--spacing--horizontal-gutter));
	}

	$container-query .c-buffer-box--extra-top$(suffix) {
		padding-top:calc(2 * var(--spacing--horizontal-gutter));
	}

	$container-query .c-buffer-box--extra-right$(suffix) {
		padding-right: calc(2 * var(--spacing--horizontal-gutter));
	}

	$container-query .c-buffer-box--extra-bottom$(suffix) {
		padding-bottom: calc(2 * var(--spacing--horizontal-gutter));
	}

	$container-query .c-buffer-box--extra-left$(suffix) {
		padding-left: calc(2 * var(--spacing--horizontal-gutter));
	}

	/* Set tighter padding
	---------------------------------------------------------------------------- */
	.c-buffer-box--tight-padding {
		padding: calc(0.5 * var(--spacing--horizontal-gutter));
	}

	.c-buffer-box--tight-top[class] {
		padding-top:calc(0.5 * var(--spacing--horizontal-gutter));
	}

	.c-buffer-box--tight-right[class] {
		padding-right: calc(0.5 * var(--spacing--horizontal-gutter));
	}

	.c-buffer-box--tight-bottom[class] {
		padding-bottom: calc(0.5 * var(--spacing--horizontal-gutter));
	}

	.c-buffer-box--tight-left[class] {
		padding-left: calc(0.5 * var(--spacing--horizontal-gutter));
	}

	/* Set border for only a certain direction.
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--border-top$(suffix) {
		border-top-width: 1px;
	}

	$container-query .c-buffer-box--border-bottom$(suffix) {
		border-bottom-width: 1px;
	}

	$container-query .c-buffer-box--border-left$(suffix) {
		border-left-width: 1px;
	}

	$container-query .c-buffer-box--border-right$(suffix) {
		border-right-width: 1px;
	}

	/* Remove border for only a certain direction.
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--no-border-top$(suffix) {
		border-top-width: 0;
	}

	$container-query .c-buffer-box--no-border-bottom$(suffix) {
		border-bottom-width: 0;
	}

	$container-query .c-buffer-box--no-border-left$(suffix) {
		border-left-width: 0;
	}

	$container-query .c-buffer-box--no-border-right$(suffix) {
		border-right-width: 0;
	}

	/* Set all borders.
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--border-all$(suffix) {
		border-width: 1px;
	}

	/* Center components
	---------------------------------------------------------------------------- */
	$container-query .c-buffer-box--centered$(suffix) {
		display: flex;
		justify-content: center;
	}
}

@mixin c-buffer-box;
@mixin c-buffer-box .o-container:container(width > 300px), \@300;
@mixin c-buffer-box .o-container:container(width > 500px), \@500;
@mixin c-buffer-box .o-container:container(width > 700px), \@700;
@mixin c-buffer-box .o-container:container(width > 900px), \@900;
@mixin c-buffer-box .o-container:container(width > 1100px), \@1100;

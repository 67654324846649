.c-campaign-footer {
	min-height: 200px;
	width: 100%;
	background-color: #212121; /* Todo variable? */
	color: white;
}

.c-campaign-footer__contact-links {
	@media (--lg-xl) {
		max-width: 1200px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	max-width: 400px;
	margin: auto;
	padding: 24px 0 20px 0;
	text-align: center;
}

.c-campaign-footer__contact-link {
	@media (--lg-xl) {
		display: inline-block;
	}
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;

}

.c-campaign-footer__contact-link a {
	@media (--lg) {
		margin: 0 15px;
	}
	display: inline-block;
	min-height: 59px;
	width: 100%;
	margin: auto;
	padding: 16px 48px 12px 24px;
	border-radius: 2px;
	font-size: 22px;
}

.c-campaign-footer a:hover {
	background-color: rgb(65, 65, 65);
}

.c-campaign-footer a:focus {
	outline: 2px solid white;
	outline-offset: 2px;
}

.c-campaign-footer__contact-links a span.text {
	display: inline-block;
	z-index: 10;
	padding-left: 10px;
	text-decoration: underline;
}

.c-campaign-footer__social-media-links {
	padding: 12px 0 24px 0;
	text-align: center;
}

.c-campaign-footer__social-media-links a {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 6px;
	padding-top: 6px;
	border-radius: 2px;
}

.c-full-width-container-block {
    padding: 50px 0;
}

@media (--md-lg-xl) {
    .c-full-width-container-block {
        padding: 50px 20px 50px 20px;
    }
}

.c-full-width-container-block__width-limiter {
    max-width: var(--width-limiter--narrow);
    margin: auto;
}

.c-full-width-container-block[class] h1,
.c-full-width-container-block[class] h2,
.c-full-width-container-block[class] h3,
.c-full-width-container-block[class] h4,
.c-full-width-container-block[class] h5 {
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
}
.c-form__fieldset-item {
    display: inline;
}

.c-form__fieldset-item--block {
    display: block;
}

.c-form__item--no-label[class] {
    margin-top: 32px;
}

.c-form__item {
    position: relative;
    justify-content: flex-end;
    margin: 0 0 calc(var(--spacing--vertical-spacing-unit) * 2);
}

.c-form__item.c-form__item--no-bottom-spacing {
    margin: 0;
}

.c-form__item.c-form__item--less-bottom-spacing {
    margin-bottom: 16px;
}

.c-form__item--error {
    color: var(--color--primary-orange);
}

.c-form__item--error>label,
.c-form__item--error>legend {
    color: var(--color--primary-orange);
}

.c-form__item--error input,
.c-form__item--error textarea {
    background: color(var(--color--primary-orange) tint(80%));
}

.c-form__error-message {
    @mixin text-style-sigma;
    display: block;
    color: var(--color--primary-orange);
}

.c-form .o-grid__column .s-text:last-child {
    margin-bottom: -var(--spacing--vertical-spacing-unit);
}

.c-form .o-grid__column .s-text:first-child {
    margin-top: var(--spacing--vertical-spacing-unit);
}

[data-size~="24"]>.c-form__button {
    margin-top: 0;
}
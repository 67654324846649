/* =============================================================================
 * Logo Cloud
============================================================================= */
.c-logo-cloud {
	padding: 50px 0;
	background-color: var(--color--white);
}

@media (--md-lg-xl) {
	.c-logo-cloud {
		padding: 50px 20px 50px 20px;
	}
}

.c-logo-cloud--title {
	@mixin text-style-beta;
	font-weight: 400;
	font-size: 36px;
	line-height: 44px;
	text-align: center;
}

@media (--sm-md) {
	.c-logo-cloud--title {
		font-size: 30px;
	}
}

.c-logo-cloud--content {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0 48px 0 72px;
}
.c-logo-cloud--logo {
	width: 230px;
	height: auto;
	padding-right: 24px;
	padding-bottom: 30px;
}

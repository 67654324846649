/* =============================================================================
 * Icon button
============================================================================= */
.c-icon-button {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

.c-icon-button__icon-holder {
	display: block;
	position: relative;
	width: 24px;
	height: 24px;
	overflow: hidden;
	margin: 0 auto;
	border-radius: 50%;
	background: var(--color--primary-blue);
	color: #fff;
	transition-property: background-color;;
}

.c-icon-button__icon-holder--border {
	box-shadow: inset 0 0 0 1px #fff;
}

.c-icon-button:hover .c-icon-button__icon-holder {
	background: color(var(--color--primary-blue) tint(30%));
}

.c-icon-button--large .c-icon-button__icon-holder {
	width: 40px;
	height: 40px;
}

.c-icon-button__icon {
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
}

.c-icon-button__label {
	@mixin text-style-iota;
	display:block;
	margin-top: 7px;
	color: var(--color--primary-blue);
	text-align: center;
	transition-property: text-decoration;
}

.c-icon-button:hover .c-icon-button__label {
	color: color(var(--color--primary-blue) tint(30%));
}

/* Colors
---------------------------------------------------------------------------- */
@define-mixin c-icon-button-color $number, $color {
	.c-icon-button--color-$(number) .c-icon-button__icon-holder {
		background-color: $color;
	}

	.c-icon-button--color-$(number):hover .c-icon-button__icon-holder {
		background: color($color tint(30%));
	}

	.c-icon-button--color-$(number) .c-icon-button__label {
		color: $color;
	}

	.c-icon-button--color-$(number):hover .c-icon-button__label {
		color: color($color tint(30%));
	}
}

@mixin c-icon-button-color 1, var(--color--primary-purple);
@mixin c-icon-button-color 2, var(--color--primary-orange);
@mixin c-icon-button-color 3, var(--color--primary-green);
@mixin c-icon-button-color 4, color(black a(20%));

/* Icon
---------------------------------------------------------------------------- */
.c-icon-button__icon {
	fill:#fff;
}

 .c-news-list-item {
     max-width: 786px;
     padding: var(--spacing--vertical-spacing-unit);
 }
 
 .c-news-list-item:after {
     display: block;
     visibility: hidden;
     clear: both;
     height: 0;
     content: " ";
 }
 
 .c-news-list-item--border-bottom {
     border-bottom: 1px solid var(--color--grey-2);
 }
 
 .c-news-list-item__title {
     @mixin text-style-delta;
     color: var(--color--link-color);
 }
 
 .c-news-list-item__title:--hocus {
     text-decoration: underline;
 }
 
 .c-news-list-item__image-container {
     position: absolute;
     width: 124px;
     height: 124px;
     margin-right: var(--spacing--vertical-spacing-unit);
     margin-bottom: var(--spacing--vertical-spacing-unit);
 }
 
 .c-news-list-item__content {
     float: left;
 }
 
 .c-news-list-item__text {
     @mixin text-style-reset;
 }
 
 .c-news-list-item__image-container+.c-news-list-item__content {
     min-height: 124px;
     padding-left: calc(124px + var(--spacing--horizontal-gutter));
 }
 
 .c-news-list-item__image {
     width: 100%;
     height: auto;
 }
 
 @media (--sm) {
     .c-news-list-item__image-container {
         position: initial;
     }

     .c-news-list-item__image-container+.c-news-list-item__content {
         padding-left: 0;
     }

     .c-news-list-item__image-container+.c-news-list-item__content {
         min-height: 0;
     }
 }
.c-social-media-block {
	padding: var(--spacing--vertical-spacing-unit) var(--spacing--horizontal-spacing-unit);
	border: 1px solid var(--color--grey-2);
	background: #fff;
}
.c-social-media-block__title {
	@mixin text-style-epsilon;
	margin: 0 0 var(--spacing--vertical-spacing-unit);
}

.c-social-media-block__iframe-container {
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-social-media-block__iframe-container--full-width {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
}

.c-social-media-block__iframe-container iframe {
	max-width: 100%;
}

.c-social-media-block__iframe-container--full-width iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.c-circle-number {
	@mixin text-style-pi;
	@mixin border-grey-light;
	display: inline-block;
	width:50px;
	height: 50px;
	padding-top:12px;
	border-radius: 100%;
	background: white;
	text-align: center;
}

.c-circle-number--72x72 {
	width: 72px;
	height: 72px;
}

.c-circle-number--72x72 + .c-circle-number--72x72 {
	margin-left: -calc(var(--spacing--horizontal-gutter) / 2);
}

.c-circle-number__label {
	@mixin text-style-nu;
	display:block;
	margin-bottom: -4px;
	color: var(--color--grey-4);
}
/* =============================================================================
 * Icon
============================================================================= */

/* Import Grunticon generated files
--------------------------------------------------------------------------- */
[class*="c-icon--"] {
	display: inline-block;
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	text-align: center;
	text-indent: 0;
	transition-property: fill, background;
	fill:currentColor;
}

[class*="c-icon--"] > svg {
	display: inline-block;
	vertical-align: middle;
}

[class*="c-icon--"] path {
	transition-property: fill;
}

/* Circle – Show icon on a circle
--------------------------------------------------------------------------- */
.c-icon--circle {
	border-radius: 50%;
	background:currentColor;
	fill:#fff;
}


/* Sizes
--------------------------------------------------------------------------- */
.c-icon--8x8 > svg,
.c-icon--8x8 {
	width: 8px;
	height: 8px;
}

.c-icon--13x13 > svg,
.c-icon--13x13 {
	width: 13px;
	height: 13px;
}

.c-icon--13x13.c-icon--circle {
	line-height: 9px;
}

.c-icon--13x13.c-icon--circle > svg {
	width: 7px;
	height: 7px;
}

.c-icon--16x16 > svg,
.c-icon--16x16 {
	width: 16px;
	height: 16px;
}

.c-icon--16x16.c-icon--circle {
	line-height: 13px;
}

.c-icon--16x16.c-icon--circle > svg {
	width: 10px;
	height: 10px;
}

.c-icon--24x24 > svg,
.c-icon--24x24 {
	width: 24px;
	height: 24px;
}

.c-icon--24x24.c-icon--circle {
	line-height: 18px;
}

.c-icon--24x24.c-icon--circle > svg {
	width: 14px;
	height: 14px;
}

.c-icon--32x32 > svg,
.c-icon--32x32 {
	width: 32px;
	height: 32px;
}

.c-icon--32x32.c-icon--circle {
	line-height: 26px;
}

.c-icon--32x32.c-icon--circle > svg {
	width: 20px;
	height: 20px;
}

.c-icon--48x48 > svg,
.c-icon--48x48 {
	width: 48px;
	height: 48px;
}

.c-icon--48x48.c-icon--circle {
	line-height: 40px;
}

.c-icon--48x48.c-icon--circle > svg {
	width: 30px;
	height: 30px;
}

.c-icon--64x64 > svg,
.c-icon--64x64 {
	width: 64px;
	height: 64px;
}

.c-icon--64x64.c-icon--circle {
	line-height: 56px;
}

.c-icon--64x64.c-icon--circle > svg {
	width: 42px;
	height: 42px;
}

.c-icon--68x68 > svg,
.c-icon--68x68 {
    width: 68px;
    height: 68px;
}

.c-segmented-control__button[class][class] {
	@mixin border-grey-light;
	@mixin text-style-iota;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	padding:0 18px;
	background-color: #fff;
	color: var(--color--link-color);
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing:antialiased;
	line-height:38px;
	text-align:center;
	text-decoration:underline transparent;
	text-transform:uppercase;
	cursor: pointer;
	transition-property: color, background-color, border-color, text-decoration-color;
}

.c-segmented-control__button:not(:first-child) {
	margin-left: -1px;
}

.c-segmented-control__button:not(.c-segmented-control__button--selected):--hocus {
	border-color: color(var(--color--grey-2) shade(3%));
	background-color: color(#fff shade(3%));
	text-decoration: underline;
}

.c-segmented-control__radio-input:checked~.c-segmented-control__button {
	background-color: var(--color--link-color);
	color: #fff;
}

.c-segmented-control__radio-input:checked~.c-segmented-control__button path{
	fill: #fff;
}

.c-segmented-control__button[class]:before,
.c-segmented-control__button[class]:after {
	content: none;
}


.c-segmented-control__button--selected:--hocus {
	border-color: color(var(--color--grey-2) tint(15%));
	background-color: color(var(--color--link-color) tint(15%));
	text-decoration: underline;
}

.c-segmented-control__button-container[class] {
	display: flex;
	position: relative;
	flex-wrap: wrap;
}

.c-breadcrumbs {
    color: var(--color--primary-blue);
}

.c-breadcrumbs__item,
.c-breadcrumbs__current-page {
    display: inline-flex;
    margin-bottom: 8px;
    white-space: nowrap;
}

.c-breadcrumbs__level[class] {
    text-decoration: none;
}

.c-breadcrumbs__level[class]:--hocus {
    text-decoration: underline;
}

.c-breadcrumbs__level[class]:focus {
    @mixin box-shadow-focus;
}

.c-breadcrumbs__current-page {
    color: var(--color--black);
}

.c-breadcrumbs__separator {
    margin: 0 4px;
}
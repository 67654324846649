.c-datepicker {
	@mixin border-grey-light;
	position: absolute;
	top: 58px;
	left: -6px;
	z-index: 3;
	width: 280px;
	border-radius: 4px;
	background-color: #fff;
}

.c-datepicker__weekday{
	@mixin text-style-psi;
	display:block;
	border-bottom: 9px solid #fff;
	background-color: var(--color--primary-blue);
	color: #fff;
}

.c-datepicker__header {
	display: inline-block;
	position: relative;
	width: 100%;
	padding: 0 40px;
	background-color: var(--color--primary-blue);
	color: #fff
}
.c-datepicker__prev-month {
	position: absolute;
	top:9px;
	left:9px;
}

.c-datepicker__next-month {
	position: absolute;
	top:9px;
	right:9px;
}

.c-datepicker__month {
	@mixin text-style-iota;
	min-height: 40px;
	width:100%;
	margin-top: var(--spacing--vertical-spacing-unit);
	text-align: center;
}

.c-datepicker__calendar {
	outline: none;
	font-size: 1.2em;
	text-align: center;
}

.c-datepicker__calendar th,
.c-datepicker__calendar td {
	width: 35px;
	padding: 0;
	text-align: center;
}

.c-datepicker__calendar td {
	width:42px;
	height: 42px;
	transition-property: color, background-color;
}
.c-datepicker__calendar td:not(.empty) {
	@mixin text-style-reset;
	padding: calc(var(--spacing--vertical-spacing-unit)/2);
	border-radius: 100%;
	cursor: pointer;
}

.c-datepicker__calendar td.today {
	color: var(--color--primary-blue);
	font-weight: 500;
}

.c-datepicker__calendar td:not(.empty):hover,
.c-datepicker__calendar td:not(.empty).focus {
	background-color: var(--color--primary-blue);
	color: #fff;
	font-weight: 300;
}
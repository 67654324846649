.c-alert {
	@mixin border-grey-light;
	position:fixed;
	top: var(--spacing--vertical-spacing-unit);
	left: 50%;
	z-index:9999;
	padding: var(--spacing--horizontal-gutter);
	background: rgba(255,255,255,0.8);
	opacity: 0!important;
	transition: opacity 0.3s ease-in-out;
	transform: translateX(-50%);
	pointer-events: none;
}

.c-alert--success {
	border-color: var(--color--primary-green);
	color: var(--color--primary-green);
}

.c-alert--error {
	border-color: var(--color--primary-orange);
	color: var(--color--primary-orange);
}

.c-alert.c-alert--visible {
	opacity: 1!important;
	transition: opacity 0.3s ease-in-out;
	pointer-events: auto;
}

.c-tag {
	@mixin text-style-zeta;
	display:inline-block;
	position: relative;
	width:auto;
	overflow:visible;
	margin-right: calc(var(--spacing--horizontal-gutter) / 2);
	margin-bottom: calc(var(--spacing--horizontal-gutter) / 2);
	padding:4px 18px;
	border:1px solid color(var(--color--primary-purple) shade(15%));
	border-radius: 100px;
	background-color: var(--color--primary-purple);
	color:#fff;
	vertical-align: top;
	text-align:center;
	cursor: pointer;
	transition-property: color, background-color, border-color;
}

.c-tag__delete-item{
	padding-left: calc(var(--spacing--horizontal-gutter) / 2);
	font-size: 24px;
	vertical-align: middle;
}

.c-tag:--hocus,
.c-tag--selected {
	border-color: var(--color--primary-purple);
	background-color: color(var(--color--primary-purple) tint(15%));
}
.c-stream-item {
	display: block;
	border-bottom: 1px solid var(--color--grey-2);
	background-color: #fff;
}

.c-stream-item__type {
	@mixin text-style-psi;
	padding: 2px var(--spacing--horizontal-spacing-unit);
	background: var(--color--grey-5);
}

.c-stream-item__message {
	padding: calc(var(--spacing--vertical-spacing-unit) / 2) var(--spacing--horizontal-spacing-unit);
}

.c-stream-item__title {
	@mixin text-style-rho;
	margin: 5px 0;
	color: var(--color--link-color);
	line-height: 18px;
}

.c-stream-item:--hocus .c-stream-item__title {
	text-decoration: underline;
}

.c-stream-item__content {
	@mixin text-style-rho;
	margin-top: calc(var(--spacing--vertical-spacing-unit) / 3);
	color: var(--color--grey-4);
}

.c-stream-item--unread .c-stream-item__content {
	color: #000;
}

.c-circle-icon {
	@mixin border-grey-light;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height:100%;
	overflow: hidden;
	margin: auto;
	border-radius: 50%;
}

.c-circle-icon--bottom-spacing {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-circle-icon--48x48 {
	width: 48px;
	height: 48px;
}

.c-circle-icon--78x78 {
	width: 78px;
	height: 78px;
}

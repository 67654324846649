.c-system-message {
	padding: var(--spacing--vertical-spacing-unit) var(--spacing--horizontal-spacing-unit);
	border: 1px solid var(--color--primary-blue);
}

.c-system-message__icon {
	color: var(--color--primary-blue);
}

.c-system-message__heading {
	@mixin text-style-beta;
}

.c-system-message--error {
	border-color: var(--color--secondary-red);
}

.c-system-message--error .c-system-message__icon {
	color: var(--color--secondary-red);
}

.c-system-message--success {
	border-color: var(--color--primary-green);
}

.c-system-message--success .c-system-message__icon {
	color: var(--color--primary-green);
}
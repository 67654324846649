/* =============================================================================
 * Text styles
============================================================================= */

.t-text-style-alpha[class][class] {
    @mixin text-style-alpha;
}

.t-text-style-beta[class][class] {
    @mixin text-style-beta;
}

.t-text-style-gamma[class][class] {
    @mixin text-style-gamma;
}

.t-text-style-delta[class][class] {
    @mixin text-style-delta;
}

.t-text-style-epsilon[class][class] {
    @mixin text-style-epsilon;
}

.t-text-style-zeta[class][class] {
    @mixin text-style-zeta;
}

.t-text-style-eta[class][class] {
    @mixin text-style-eta;
}

.t-text-style-theta[class][class] {
    @mixin text-style-theta;
}

.t-text-style-iota[class][class] {
    @mixin text-style-iota;
}

.t-text-style-kappa[class][class] {
    @mixin text-style-kappa;
}

.t-text-style-lambda[class][class] {
    @mixin text-style-lambda;
}

.t-text-style-mu[class][class] {
    @mixin text-style-mu;
}

.t-text-style-nu[class][class] {
    @mixin text-style-nu;
}

.t-text-style-xi[class][class] {
    @mixin text-style-xi;
}

.t-text-style-omicron[class][class] {
    @mixin text-style-omicron;
}

.t-text-style-pi[class][class] {
    @mixin text-style-pi;
}

.t-text-style-rho[class][class] {
    @mixin text-style-rho;
}

.t-text-style-sigma[class][class] {
    @mixin text-style-sigma;
}

.t-text-style-tau[class][class] {
    @mixin text-style-tau;
}

.t-text-style-upsilon[class][class] {
    @mixin text-style-upsilon;
}

.t-text-style-phi[class][class] {
    @mixin text-style-phi;
}

.t-text-style-chi[class][class] {
    @mixin text-style-chi;
}

.t-text-style-psi[class][class] {
    @mixin text-style-psi;
}

.t-text-style-omega[class][class] {
    @mixin text-style-omega;
}

.t-text-style-digamma[class][class] {
    @mixin text-style-digamma;
}

.t-text-style-heta[class][class] {
    @mixin text-style-heta;
}

.t-text-style-san[class][class] {
    @mixin text-style-san;
}

.t-text-style-stigma[class][class] {
    @mixin text-style-stigma;
}

.t-text-style-koppa[class][class] {
    @mixin text-style-koppa;
}


/*
 * Text styles 2.0
---------------------------------------------------------------------------- */

.t-text-style-heading-l[class][class] {
    @mixin text-style-heading-l;
}

.t-text-style-heading-m[class][class] {
    @mixin text-style-heading-m;
}

.t-text-style-heading-s[class][class] {
    @mixin text-style-heading-s;
}

.t-text-style-heading-s--link[class][class] {
    @mixin text-style-heading-s--link;
}

.t-text-style-heading-s--link[class][class]:--hocus {
    @mixin text-style-heading-s--link;
    color: var(--color--black);
    text-decoration: none;
}

.t-text-style-preamble[class][class] {
    @mixin text-style-preamble;
}

.t-text-style-link[class][class] {
    @mixin text-style-link;
}

.t-text-style-link[class][class]:hover {
    @mixin text-style-link;
    text-decoration: underline;
}

.t-text-style-body[class][class] {
    @mixin text-style-body;
}

.t-text-style-body-medium[class][class] {
    @mixin text-style-body-medium;
}

.t-text-style-body--link[class][class] {
    @mixin text-style-body--link;
}

.t-text-style-date[class][class] {
    @mixin text-style-date;
}

.t-text-style-label[class][class] {
    @mixin text-style-label;
}
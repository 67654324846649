/* Margin top
----------------------------------------------------------------------------- */
.t-margin-top-none {
	margin-top: 0;
}

.t-margin-top-xs {
	margin-top: var(--spacing--xs);
}

.t-margin-top-s {
	margin-top: var(--spacing--s);
}

.t-margin-top-m {
	margin-top: var(--spacing--m);
}

.t-margin-top-l {
	margin-top: var(--spacing--l);
}

.t-margin-top-xl {
	margin-top: var(--spacing--xl);
}

/* Margin right
----------------------------------------------------------------------------- */
.t-margin-right-none {
	margin-right: 0;
}

.t-margin-right-xs {
	margin-right: var(--spacing--xs);
}

.t-margin-right-s {
	margin-right: var(--spacing--s);
}

.t-margin-right-m {
	margin-right: var(--spacing--m);
}

.t-margin-right-l {
	margin-right: var(--spacing--l);
}

.t-margin-right-xl {
	margin-right: var(--spacing--xl);
}


/* Margin bottom
----------------------------------------------------------------------------- */
.t-margin-bottom-none {
	margin-bottom: 0;
}

.t-margin-bottom-xs {
	margin-bottom: var(--spacing--xs);
}

.t-margin-bottom-s {
	margin-bottom: var(--spacing--s);
}

.t-margin-bottom-m {
	margin-bottom: var(--spacing--m);
}

.t-margin-bottom-l {
	margin-bottom: var(--spacing--l);
}

.t-margin-bottom-xl {
	margin-bottom: var(--spacing--xl);
}


/* Margin left
----------------------------------------------------------------------------- */
.t-margin-left-none {
	margin-left: 0;
}

.t-margin-left-xs {
	margin-left: var(--spacing--xs);
}

.t-margin-left-s {
	margin-left: var(--spacing--s);
}

.t-margin-left-m {
	margin-left: var(--spacing--m);
}

.t-margin-left-l {
	margin-left: var(--spacing--l);
}

.t-margin-left-xl {
	margin-left: var(--spacing--xl);
}

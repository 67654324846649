.c-circle-image {
	display: inline-block;
	overflow: hidden;
	border-radius: 50%;
	vertical-align: top;
}

.c-circle-image--border {
	@mixin border-grey-light;
}

/* Ability to force 32x32
----------------------------------------------------------------------------- */

.c-circle-image--small > img {
	width:32px;
	height:32px;
}

@media (--sm) {
	.c-circle-image--small\@sm > img {
		width:32px;
		height:32px;
	}
}
.c-table-form {
	display: block;
}

.c-table-form thead {
	display: none;
}

.c-table-form tbody {
	display: block;
}

.c-table-form tr {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--color--grey-2);
}

.c-table-form td {
	display: block;
	padding: 0 calc(var(--spacing--vertical-spacing-unit) / 2) calc(var(--spacing--vertical-spacing-unit) / 2) 0;
}

.c-table-form tr > :first-child {
	width: 100%;
	padding: calc(var(--spacing--vertical-spacing-unit) / 2) 0;
}

/* Table form larger than 500px
----------------------------------------------------------------------------- */
.c-table-form:container(width > 500px) {
	display: table;
}

.c-table-form:container(width > 500px) thead {
	display: table-header-group;
}

.c-table-form:container(width > 500px) tbody {
	display: table-row-group;
}

.c-table-form:container(width > 500px) tr {
	display: table-row;
}

.c-table-form:container(width > 500px) th {
	width: auto;
}

.c-table-form:container(width > 500px) input[type="checkbox"] ~ label::before {
	margin-right: 0;
}

.c-table-form:container(width > 500px) th,
.c-table-form:container(width > 500px) td {
	display: table-cell;
	max-width: 25%;
	width: 140px;
	padding: calc(var(--spacing--vertical-spacing-unit) / 2) 0;
	border-bottom: 1px solid var(--color--grey-2);
	vertical-align: middle;
}

.c-table-form:container(width > 500px) thead th {
	border-color: transparent;
}

.c-table-form:container(width > 500px) tr:last-child td,
.c-table-form:container(width > 500px) tr:last-child th {
	border-bottom: none;
}

.c-table-form:container(width > 500px) th {
	padding: calc(var(--spacing--vertical-spacing-unit) / 2) 0;
}

.c-table-form:container(width > 500px) th:first-child,
.c-table-form:container(width > 500px) td:first-child {
	max-width: none;
	width: auto;
}

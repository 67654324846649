/* =============================================================================
 * Header
============================================================================= */

.c-header {
    background-color: #fff;
    text-align: right;
}

.c-header-desktop__userbar-container {
    text-align: left;
}

.c-header__mobile {
    display: block;
}

@media print {
    .c-header {
        display: none;
    }
}

.c-header .c-logo__image {
    width: auto;
    height: 62px;
}

.c-header--sub-web {
    padding: 0 0 16px
}

.c-header--sub-web .c-logo__image {
    height: 93px;
}

.c-header--sub-web .c-logo__image.bitmap-logo {
    min-height: 40px;
    max-height: 93px;
    height: auto;
}

.c-header__top-bar {
    display: block;
    margin-bottom: 16px;
    padding: 6px 0 12px;
    background-color: var(--color--primary-blue);
}

.c-header__top-bar>.o-width-limiter {
    display: flex;
    justify-content: space-between;
}

.c-header__top-bar-icon {
    margin-right: calc(var(--spacing--horizontal-gutter) / 2);
    color: #fff;
}

.nacka-theme-1.c-header .c-header__top-bar,
[class^="nacka-theme-"] .nacka-theme-1.c-header .c-header__top-bar {
    background-color: var(--color--primary-green)
}

.nacka-theme-2.c-header .c-header__top-bar,
[class^="nacka-theme-"] .nacka-theme-2.c-header .c-header__top-bar {
    background-color: var(--color--primary-orange);
}

.nacka-theme-3.c-header .c-header__top-bar,
[class^="nacka-theme-"] .nacka-theme-3.c-header .c-header__top-bar {
    background-color: var(--color--primary-purple);
}

.nacka-theme-4.c-header .c-header__top-bar,
[class^="nacka-theme-"] .nacka-theme-4.c-header .c-header__top-bar {
    background-color: var(--color--primary-blue);
}

.nacka-theme-5.c-header .c-header__top-bar,
[class^="nacka-theme-"] .nacka-theme-5.c-header .c-header__top-bar {
    background-color: var(--color--ybc-green);
}

.nacka-theme-6.c-header .c-header__top-bar,
[class^="nacka-theme-"] .nacka-theme-6.c-header .c-header__top-bar {
    background-color: var(--color--school-blue);
}

.c-header__inner {
    display: flex;
    align-items: center;
    margin: 32px auto;
}

.c-header__translate-link,
.c-header__supplementary-links li a {
    @mixin text-style-theta;
    color: var(--type--color-default);
}

.c-header__supplementary-links {
    display: inline-block;
}

.c-header__translate-link:--hocus,
.c-header__supplementary-links li a:--hocus {
    color: color(var(--color--link-color) shade(50%));
    text-decoration: underline;
}

.c-header__icon-list--container {
    background-color: var(--color--grey--lightest);
}

.c-header__icon-list {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
}

.c-header__icon-list .c-header__link-list {
    align-items: center;
}

.c-header__icon-list .c-header__link-list li {
    margin: 0 4px;
}

.c-header__icon-link {
    display: inline-block;
    display: inline-flex;
    display: inline-flex !important;
    align-items: center;
    margin: 8px 4px;
    padding: 8px;
    text-align: center;
}

.c-header__icon-link--icon-right span {
    margin-right: 8px;
}

.c-header__icon-link--icon-right span.c-header__icon {
    margin-right: 0;
}


/*Account for lineheight of text*/

.c-header__icon-link .c-header__icon,
.c-header .c-header__icon path {
    margin-right: 8px;
}

.c-header__icon-link:--hocus .c-header--icon {
    fill: color(var(--color--link-color) shade(50%));
}

.c-header__icon-link:focus {
    outline-color: var(--color--black);
    outline-offset: 0;
}

.c-header__icon-link span {
    @mixin text-style-theta;
    color: var(--color--graphite-3);
    font-size: 18px;
    line-height: 24px;
}

.c-header__icon-link:--hocus span {
    text-decoration: underline;
}

@media (--sm-md) {
    .c-header {
        display: none;
        padding: 0;
    }

    .c-header__top-bar {
        display: none;
    }
}

@media (--lg) {
    .c-header .c-header__mobile {
        display: none;
    }
}
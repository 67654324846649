.c-contact-card {
	@mixin border-grey-light;
	overflow:hidden;
	margin:0 0 var(--spacing--horizontal-gutter);
}

.c-contact-card--no-bottom {
	margin-bottom: 0;
}

.c-contact-card--no-border {
	border: none;
}

.c-contact-card--border-bottom-only {
	border: none;
	border-bottom: 1px solid var(--color--grey-2);
}

.s-text .c-contact-card {
	margin: 0 0 var(--spacing--vertical-spacing-unit);
}

.c-contact-card__title {
	@mixin text-style-beta;
	margin:calc(var(--spacing--vertical-spacing-unit) / 2) 0;
}

.c-contact-card__title-link {
	color: var(--color--link-color);
}

.c-contact-card__title-link:--hocus {
	text-decoration: underline;
}

.c-contact-card__image-link {
	transition-property: opacity;
}

.c-contact-card__image-link:--hocus {
	opacity: 0.8;
}

.c-contact-card__text {
	@mixin text-style-nu;
	color:var(--color--grey-4);
}

.c-contact-card__title + .c-contact-card__text {
 margin-top: -calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-contact-card__message-link,
.c-contact-card__phone-number {
	@mixin text-style-reset;
	padding-left:0;
	color: var(--color--link-color);
	vertical-align: middle;
	white-space:nowrap;
}

.c-contact-card__phone-number{
	margin-right: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-contact-card__phone-number:--hocus,
.c-contact-card__message-link:--hocus {
	text-decoration:underline;
}

.c-contact-card__icon {
	margin-right: calc(var(--spacing--horizontal-gutter) / 2);
	vertical-align:-2px;
}

.c-contact-card__icon path {
	fill:var(--color--link-color);
}

.c-contact-card__primary-item {
	flex-shrink:0;
}

.c-contact-card__secondary {
	display: flex;
	justify-content: center;
}

.c-contact-card__secondary-item {
	margin: calc(var(--spacing--vertical-spacing-unit) / 2) 0;
}

.c-contact-card__secondary-item:first-child:not(:last-child) {
	margin-right: calc(var(--spacing--vertical-spacing-unit) / 2);
}

/*sm to lg*/
@media (--sm-md) {
	.o-media\@lg > .o-media__secondary {
		margin-top: calc(var(--spacing--vertical-spacing-unit) / 2);
	}
}

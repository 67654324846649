/* ====================================================================================================================
 * CONTENT SLIDER
 *
 * 1. Clear floats
 * ==================================================================================================================== */
.c-content-slider {
	position: relative;
	padding-bottom: calc(var(--spacing--vertical-spacing-unit) * 2);
	opacity: 0;
}

.o-container:container(width <= 500px) .c-content-slider {
	padding-bottom: calc(var(--spacing--vertical-spacing-unit) * 3);
}

.no-js .c-content-slider,
.c-content-slider--loaded {
	opacity: 1;
}

.c-content-slider__list {
	overflow: auto; /* [1] */
	background: var(--color--primary-blue);
}


/*
 * List item
---------------------------------------------------------------------------- */
.c-content-slider__list-item {
	position: relative;
	z-index: 2;
	width: 100%;
	margin-right: -100%;
}

.c-content-slider:not(.c-content-slider--loaded) .c-content-slider__list-item + .c-content-slider__list-item {
	z-index: 1;
	opacity: 0;
}

.no-js .c-content-slider__list-item[class][class] {
	margin-right: 0;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	opacity: 1;
}

.c-content-slider__image {
	width: 100%;
}

.no-js .c-content-slider__image {
	display: none;
}


/*
 * Caption
---------------------------------------------------------------------------- */
.c-content-slider__caption-container {
	flex-grow: 1;
	padding: var(--spacing--horizontal-gutter) var(--spacing--horizontal-gutter);
}

.o-container:container(width > 500px) .c-content-slider__caption-container {
	padding: var(--spacing--vertical-spacing-unit) calc(var(--spacing--vertical-spacing-unit) * 5);
}

.c-content-slider__caption {
	max-width: 580px;
	color: #fff;
}

.c-content-slider__caption-title {
	font-size: 32px !important;
}

.c-content-slider__caption-title + .c-content-slider__caption-button {
	margin-top: var(--spacing--vertical-spacing-unit);
}


/*
 * Arrow navigation
---------------------------------------------------------------------------- */
.c-content-slider__navigation {
	position: absolute;
	bottom: 6px;
	z-index: 3;
	width: 100%;
}

.o-container:container(width > 500px) .c-content-slider__navigation {
	bottom: 100px;
}

.no-js .c-content-slider__navigation {
	opacity: 0;
}

.c-content-slider__prev,
.c-content-slider__next {
	position: absolute;
	bottom: 0;
	width: 45px;
	height: 45px;
	font-size: 0;
}

.c-content-slider__next {
	right: 0;
}

.o-container:container(width > 500px) .c-content-slider__next {
	right: var(--spacing--horizontal-gutter);
}

.c-content-slider__prev {
	left: 0;
}

.o-container:container(width > 500px) .c-content-slider__prev {
	left: var(--spacing--horizontal-gutter);
}


/*
 * Dots navigation
---------------------------------------------------------------------------- */
.c-content-slider__control-nav {
	position: absolute;
	bottom: 13px;
	left: 50%;
	z-index: 3;
	height: 11px;
	text-align: center;
	transform: translateX(-50%);
}

.o-container:container(width <= 500px) .c-content-slider__control-nav {
	bottom: 22px;
}

.c-content-slider__control-nav li {
	display: inline-block;
	margin: 0 calc(var(--spacing--vertical-spacing-unit)/2);
}

.c-content-slider__control-paging li a {
	display: block;
	width: 11px;
	height: 11px;
	border: 1px solid var(--color--grey-3);
	border-radius: 100%;
	text-indent: -9999px;
	cursor: pointer;
}

.c-content-slider__control-paging li a:--hocus {
	background: var(--color--grey-3);
	transition-property: background-color;
}

.c-content-slider__control-paging li a.c-content-slider__active {
	background: var(--color--grey-3);
	cursor: default;
}

/* Variant */
.c-content-slider--variant {
	padding-bottom: 0;
}

.c-content-slider--variant .c-content-slider__list {
	position: relative;
	overflow: hidden;
	background: #82AA50;
}

.o-container:container(width <= 500px) .c-content-slider--variant {
	padding-bottom: 0;
}

.o-container:container(width < 500px) .c-content-slider--variant .c-content-slider__caption-button {
	margin-top: 0;
}

/*
 * List item
---------------------------------------------------------------------------- */
.c-content-slider--variant .c-content-slider__list-item {
	position: absolute ;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.c-content-slider--variant .c-content-slider__list-item .c-content-slider--no-image {
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.c-content-slider--variant .c-content-slider__list-item .c-content-slider--no-image .c-content-slider__caption-container {
	position: absolute !important;
	top: 50%;
	min-height: 400px;
	background: transparent;
	transform: translateY(-50%);
}

.c-content-slider--variant .c-content-slider__control-paging li a {
	width: 8px;
	height: 8px;
	border: none;
	background-color: rgba(255, 255, 255, 0.5);
}

.c-content-slider--variant .c-content-slider__control-paging li a.c-content-slider__active {
	background-color: var(--color--white);
}

/*
* Caption
---------------------------------------------------------------------------- */
.o-container:container(width > 500px) .c-content-slider--variant .c-content-slider__caption-container {
	padding: 0 calc(var(--spacing--vertical-spacing-unit) * 5);
}

.c-content-slider--variant .c-content-slider__caption-container {
	display: flex;
	position: absolute;
	top: 0;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	text-align: center;
}

.c-content-slider--variant .c-content-slider__caption {
	max-width: 100%;
}

.c-content-slider--variant .c-content-slider__caption-title {
	margin-bottom: 20px;
	font-size: 18px !important;
	line-height: 20px !important;
	text-transform: uppercase !important;
}

.o-container:container(width > 700px) .c-content-slider--variant .c-content-slider__caption-title {
	font-size: 40px !important;
	line-height: 42px !important;
}

.o-container:container(width < 700px) .c-content-slider--variant .c-content-slider__caption-text {
	display: none;
}

.c-content-slider--variant .c-icon-button__icon-holder {
	box-shadow: none;
	background-color: transparent !important;
}

.c-content-slider--variant .c-content-slider__caption-container.c-content-slider--no-image-set {
	background-color: transparent;
}
/*
 * Arrow navigation
---------------------------------------------------------------------------- */
.c-content-slider--variant .c-content-slider__navigation {
	top: 0;
	width: 100%;
	height: 100%;
}

.c-content-slider--variant .c-content-slider__prev, .c-content-slider--variant .c-content-slider__next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

/*
 * Dots navigation
---------------------------------------------------------------------------- */
.o-container:container(width <= 500px) .c-content-slider--variant .c-content-slider__control-nav {
	bottom: 10px;
}

.c-footer {
    width: 100%;
    overflow-x: hidden;
}

.c-footer .c-link-list__no-link-heading-wrapper {
    padding: 4px 0;
}

.c-footer .c-link-list__heading {
    color: var(--color--primary-blue);
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.c-footer h3 {
    @mixin text-style-label;
    font-size: 16px;
    line-height: 22px;
}

.c-footer .c-footer__section a {
    color: var(--color--primary-blue);
}

.c-footer .c-footer__section a:hover {
    text-decoration: underline;
}

.c-footer__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color--white);
}

.c-footer__section--inverted {
    background: var(--color--primary-blue);
    color: var(--color--white);
}

.c-footer .c-footer__section--inverted a {
    color: var(--color--white);
}

.c-footer__section-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 24px 12px;
}

.c-footer__section-inner>* {
    break-inside: avoid-column;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
}

.c-footer__section-part {
    display: inline-block;
    margin-bottom: 24px;
}

.c-footer__section-part-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
}

.c-footer__section-part p {
    @mixin text-style-body;
}

.c-footer__section-part a {
    @mixin text-style-body--link;
    text-decoration: none;
}

.c-footer__section-part a:hover,
.c-footer__section-part a:focus {
    text-decoration: underline;
}

.c-footer__section-part--icon {
    margin-bottom: 8px;
}

.c-footer__social-media-list {
    margin: -16px 0;
}

.c-footer__social-media-list a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4px;
    padding: 12px;
}

.c-footer__social-media-list a span:last-child {
    display: none;
}

.c-footer__section a:focus {
    outline: 2px solid var(--color--black);
    outline-offset: 8px;
}

.c-footer__section--inverted a:focus {
    outline-color: var(--color--white);
}

.c-footer__social-media-list {
    justify-content: center;
    width: 100%;
    margin: 0;
}

.c-footer__social-media-list a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 16px;
    padding: 0;
}

.c-footer__social-media-list a:hover {
    text-decoration: underline;
}

@media (--lg-xl) {
    .c-footer__section-inner {
        flex-direction: row;
    }

    .c-footer__section-inner .o-grid {
        width: 100%;
    }

    .c-footer__section-part {
        margin-bottom: 0;
        padding: 0 50px;
    }

    .c-footer__section-part-inner {
        flex-direction: row;
        text-align: left;
    }

    .c-footer__section-part--icon {
        margin-right: 8px;
        margin-bottom: 0;
    }

    .c-footer__section-part--icon [class*=c-icon],
    .c-footer__section-part--icon [class*=c-icon]>svg {
        width: 48px;
        height: 48px;
    }

    .c-footer__social-media-list a span:last-child {
        display: inline-block;
        margin-top: 8px;
    }
}

@media print {
    .c-footer {
        display: none;
    }
}
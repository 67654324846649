.c-tab-list {
	@mixin border-grey-light;
	position: relative;
	z-index: 1;
	border-width: 1px 0 0;
	background: var(--color--main-background);
}

@media print {
	.c-tab-list {
		display: none;
	}
}

.c-tab-list__list {
	display: flex;
	position: relative;
	top: 1px;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: stretch;
}

.c-tab-list__item {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.c-tab-list--stretch .c-tab-list__item {
	flex-grow: 1;
	flex-basis: 0;
}

.c-tab-list__link {
	@mixin text-style-epsilon;
	@mixin border-grey-light;
	display: block;
	display: flex;
	position: relative;
	flex-grow: 1;
	min-width: 90px;
	padding: calc(var(--spacing--vertical-spacing-unit) * 1.25) var(--spacing--horizontal-spacing-unit);
	border-width: 0 1px 0 0;
	text-align: center;
	transition-property: background-color, color;
}

.c-tab-list__item--stacked .c-tab-list__link {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.c-tab-list__link::before {
	position: absolute;
	top: -5px;
	right: 0;
	left: 0;
	border-top: 5px solid transparent;
	content: "";
}

.c-tab-list--stretch .c-tab-list__item:last-child .c-tab-list__link {
	border-right: 0;
}

.c-tab-list__link:not(.c-tab-list__link--active):--hocus {
	background: rgba(255, 255, 255, 0.7);
	color: var(--color--link-color);
}

.c-tab-list__link--active {
	background: #fff;
	transition-property: none;
}

.c-tab-list__link--active::before {
	border-top-color: var(--color--link-color);
}

.c-tab-list__icon {
	vertical-align: top;
}

.c-tab-list__icon {
	color: var(--color--primary-blue);
}

.c-tab-list__badge {
	position: absolute;
	top: 7px;
	left: 50%;
}

.c-tab-list__badge--inline {
	display: inline-block;
	position: static;
	top: auto;
	left: auto;
}

.c-tab-list__label--tiny {
	@mixin text-style-nu;
}

.c-tab-list__item--stacked .c-tab-list__link {
	padding: var(--spacing--vertical-spacing-unit)
		calc(0.5 * var(--spacing--vertical-spacing-unit))
		calc(0.5 * var(--spacing--vertical-spacing-unit));
}

.c-tab-list__item--stacked .c-tab-list__label {
	margin-top: calc(0.5 * var(--spacing--vertical-spacing-unit));
}


/* No border
----------------------------------------------------------------------------- */
.c-tab-list--no-border {
	top: 0;
	border-top-width: 0;
}

.c-tab-list--no-border .c-tab-list__link {
	border-width: 0;
}


/* Colors
----------------------------------------------------------------------------- */
@define-mixin tab-list-color $number, $bg, $fg {
	.c-tab-list__item--color-$(number) .c-tab-list__link {
		background-color: $bg;
		color: $fg;
	}

	.c-tab-list__item--color-$(number) .c-tab-list__link:not(.c-tab-list__link--active):--hocus {
		background: color($bg shade(20%));
		color: $fg;
	}

	.c-tab-list__item--color-$(number) .c-tab-list__link--active {
		background: #fff;
		color: var(--type--color-default);
	}

	.c-tab-list__item--color-$(number) .c-tab-list__link--active::before {
		border-top-color: $bg;
	}

	.c-tab-list__item--color-$(number) .c-tab-list__icon {
		color: $fg;
	}

	.c-tab-list__item--color-$(number) .c-tab-list__link--active .c-tab-list__icon {
		color: $bg;
	}
}

@mixin tab-list-color 1, var(--color--primary-blue), #fff;
@mixin tab-list-color 2, var(--color--primary-purple), #fff;
@mixin tab-list-color 3, var(--color--primary-orange), #fff;
@mixin tab-list-color 4, var(--color--primary-green), #fff;
@mixin tab-list-color 5, var(--color--secondary-darkblue), #fff;
@mixin tab-list-color 6, var(--color--secondary-purple), #fff;
@mixin tab-list-color 7, var(--color--primary-orange), #fff;
@mixin tab-list-color 8, var(--color--primary-green), #fff;

/* Theme colors
----------------------------------------------------------------------------- */
.nacka-theme-1 .c-tab-list__link--active::before,
[class^="nacka-theme-"] .nacka-theme-1 .c-tab-list__link--active::before {
	border-top-color: var(--color--primary-green);
}

.nacka-theme-2 .c-tab-list__link--active::before,
[class^="nacka-theme-"] .nacka-theme-2 .c-tab-list__link--active::before {
	border-top-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-tab-list__link--active::before,
[class^="nacka-theme-"] .nacka-theme-3 .c-tab-list__link--active::before {
	border-top-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-tab-list__link--active::before,
[class^="nacka-theme-"] .nacka-theme-4 .c-tab-list__link--active::before {
	border-top-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-tab-list__link--active::before,
[class^="nacka-theme-"] .nacka-theme-5 .c-tab-list__link--active::before {
	border-top-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-tab-list__link--active::before,
[class^="nacka-theme-"] .nacka-theme-6 .c-tab-list__link--active::before {
	border-top-color: var(--color--school-blue);
}

.nacka-theme-1 .c-tab-list__icon,
[class^="nacka-theme-"] .nacka-theme-1 .c-tab-list__icon {
	color: var(--color--primary-green);
}

.nacka-theme-2 .c-tab-list__icon,
[class^="nacka-theme-"] .nacka-theme-2 .c-tab-list__icon {
	color: var(--color--primary-orange);
}

.nacka-theme-3 .c-tab-list__icon,
[class^="nacka-theme-"] .nacka-theme-3 .c-tab-list__icon {
	color: var(--color--primary-purple);
}

.nacka-theme-4 .c-tab-list__icon,
[class^="nacka-theme-"] .nacka-theme-4 .c-tab-list__icon {
	color: var(--color--primary-blue);
}

.nacka-theme-5 .c-tab-list__icon,
[class^="nacka-theme-"] .nacka-theme-5 .c-tab-list__icon {
	color: var(--color--ybc-green);
}

.nacka-theme-6 .c-tab-list__icon,
[class^="nacka-theme-"] .nacka-theme-6 .c-tab-list__icon {
	color: var(--color--school-blue);
}

.c-tab-list__badge--inline {
	margin-left:calc(var(--spacing--horizontal-spacing-unit) * 0.5);
	padding:0 10px;
}

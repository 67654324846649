.c-event-teaser-short__heading {
	@mixin text-style-zeta;
}

.c-event-teaser-short__heading-link {
	color: var(--color--link-color);
}

.c-event-teaser-short__heading-link:--hocus {
	text-decoration: underline;
}

.c-event-teaser-short__meta {
	@mixin text-style-psi;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.c-event-teaser-short__meta-item {
	display: inline-flex;
	align-items: center;
	margin-right: calc(var(--spacing--horizontal-spacing-unit) / 2);
}

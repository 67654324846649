.c-news-section {
    margin: 0 auto var(--spacing--m);
    padding-bottom: var(--spacing--l);
    background-color: var(--color--white);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.c-news-section__items {
    margin: 0 auto;
}

.c-news-section__heading {
    padding: var(--spacing--l) var(--spacing--s);
}

@media (--sm) {
    .c-news-section {
        padding-top: 0;
    }

    .c-news-section .c-full-width-container-block {
        padding-top: 0;
    }
}
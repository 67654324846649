/* Tree view
-------------------------------------------------------------- */
.c-tree-view__list > .c-tree-view__list-item--no-children,
.c-tree-view__list > .c-tree-view__list-item--has-children {
	position: relative;
}

.c-tree-view__node {
	display: inline-block;
	width: 100%;
}

.c-tree-view__node {
	padding: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-tree-view--spacious .c-tree-view__node {
	padding: var(--spacing--vertical-spacing-unit);
}

.c-tree-view__list-item--activedescendant > .c-tree-view__node {
	background: var(--color--grey-2);
}

.c-tree-view__list-item--activedescendant > .c-tree-view__node--current a.c-tree-view__text {
	color: var(--color--link-color);
}

a.c-tree-view__node,
a.c-tree-view__text {
	color: var(--color--link-color);
}

a.c-tree-view__node:hover,
a.c-tree-view__text:hover {
	text-decoration: underline;
}

.c-tree-view__text {
	@mixin text-style-rho;
	display: inline-block;
	padding-right: calc((var(--spacing--horizontal-spacing-unit) / 2) + 24px);
}

.c-tree-view--spacious .c-tree-view__text {
	@mixin text-style-chi;
	padding-right: calc(var(--spacing--horizontal-spacing-unit) + 24px);
}

.c-tree-view__node {
	color: var(--color--link-color);
	cursor: pointer;
}

.c-tree-view__node--current {
	background: var(--color--primary-blue);
}

.c-tree-view__node--current .c-tree-view__text {
	color: #fff;
}

.c-tree-view__node-icon[class] {
	margin-right: 3px;
	vertical-align: -3px;
}

.c-tree-view__sub-list {
	margin-left: var(--spacing--horizontal-gutter);
}

/* Prevent flicker when page load */
.js .c-tree-view__sub-list {
	display: none;
}

.c-tree-view__list-item--expanded .c-tree-view__sub-list {
	display: block;
}

.c-tree-view__list-item {
	position: relative;
	border-top: 1px solid var(--color--grey-2);
}

.c-tree-view__list-item--current {
	border-top: 0;
}

.c-tree-view__list-item--expanded {
	background-color: var(--color--grey-1);
}

.c-tree-view__list > .c-tree-view__list-item:first-child {
	border-top: none;
}

/* The toggle button */
.c-tree-view__toggle-button {
	display: inline-block;
	position: absolute;
	top: calc(var(--spacing--vertical-spacing-unit) / 2);
	right: calc(var(--spacing--vertical-spacing-unit) / 2);
	width: 24px;
	height: 24px;
	border-radius: 100%;
	background: var(--color--primary-blue);
	cursor: pointer;
}

.c-tree-view--spacious .c-tree-view__toggle-button {
	top: var(--spacing--vertical-spacing-unit);
	right: var(--spacing--vertical-spacing-unit);
}

.c-tree-view__toggle-button {
	outline-width: 0;
}

/* Use pseudo-elements to create a plus symbol */
.c-tree-view__toggle-button::before,
.c-tree-view__toggle-button::after {
	position: absolute;
	top: 12px;
	left: 4px;
	width: 16px;
	height: 1px;
	background: #fff;
	content: "";
}

.c-tree-view__toggle-button:not(.c-tree-view__toggle-button--expanded)::after {
	transform: rotate(-90deg);
}

/* Animate the transform rotation */
.c-tree-view__toggle-button::after {
	transition: all 0.15s;
}

/* =============================================================================
 * Teaser component
============================================================================= */
:root {
	--c-teaser--border-radius:5px;
}

.c-teaser {
	display: block;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-teaser:--hocus {
	background: var(--color--grey-1);
}

.c-teaser__image-container {
	overflow: hidden;
	border-radius: var(--c-teaser--border-radius) var(--c-teaser--border-radius) 0 0;
	transition-property: opacity;
}

.c-teaser:--hocus .c-teaser__image-container {
	opacity: 0.8;
}

.c-teaser__image {
	width: 100%;
}

.c-teaser__text-content {
	@mixin border-grey-light;
	flex-grow: 1;
	padding: 20px;
	border-radius: var(--c-teaser--border-radius);
	background-color: #fff;
}

.c-teaser__image-container + .c-teaser__text-content {
	border-top:0;
	border-radius: 0 0 var(--c-teaser--border-radius) var(--c-teaser--border-radius);
}

.c-teaser__title {
	@mixin text-style-beta;
}

.c-teaser__title h2,
.c-teaser__title h2 b,
.c-teaser__title h2 p {
	font-size: 28px !important;
}

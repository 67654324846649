/* Previous setup:
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=5301abba-59d5-4400-b893-296a512f9b39");
@font-face {
	font-weight:300;
	font-family:"Gill Sans Web";
	src:url("_fonts/acd8a043-828c-4c2c-98cb-b5224f5d4cdb.eot?#iefix");
	src:url("_fonts/acd8a043-828c-4c2c-98cb-b5224f5d4cdb.eot?#iefix") format("eot"),url("_fonts/a2926047-aec5-42f4-b352-e593469518be.woff2") format("woff2"),url("_fonts/c56da29d-9c5b-4d94-900f-770cde3dd317.woff") format("woff"),url("_fonts/378182d1-8021-4674-a814-cc8f01f9a937.ttf") format("truetype"),url("_fonts/d8851d95-478d-47a9-a0b0-a8d8e4ca127a.svg#d8851d95-478d-47a9-a0b0-a8d8e4ca127a") format("svg");
}
@font-face {
	font-weight:400;
	font-family:"Gill Sans Web";
	src:url("_fonts/2cebe80c-b289-4ae0-a3b0-baa82c6c6e10.eot?#iefix");
	src:url("_fonts/2cebe80c-b289-4ae0-a3b0-baa82c6c6e10.eot?#iefix") format("eot"),url("_fonts/51318b0e-57fc-4136-b7d1-46aee6c2b565.woff2") format("woff2"),url("_fonts/2bc69477-90c2-4415-a51f-36e36eee3d5e.woff") format("woff"),url("_fonts/33447d8d-d920-48e7-b0a9-1d3dda80d6a6.ttf") format("truetype"),url("_fonts/e49c440c-7653-44ce-96d2-f775947ba9fe.svg#e49c440c-7653-44ce-96d2-f775947ba9fe") format("svg");
}
@font-face {
	font-weight:500;
	font-family:"Gill Sans Web";
	src:url("_fonts/beab4258-af94-4971-a0db-b7bc2bef74bc.eot?#iefix");
	src:url("_fonts/beab4258-af94-4971-a0db-b7bc2bef74bc.eot?#iefix") format("eot"),url("_fonts/d9f9decc-5fa8-4390-a105-4f89f6b7b055.woff2") format("woff2"),url("_fonts/cd5a93fc-2bb1-48df-8982-11119cd6e270.woff") format("woff"),url("_fonts/b6bdc93b-2720-4c92-b68d-48499456178e.ttf") format("truetype"),url("_fonts/944cce8d-20bf-41c1-ae29-2bc8199859db.svg#944cce8d-20bf-41c1-ae29-2bc8199859db") format("svg");
}
@font-face {
	font-weight:600;
	font-family:"Gill Sans Web";
	src:url("_fonts/1a6dec8e-26f9-4243-8495-835709538f92.eot?#iefix");
	src:url("_fonts/1a6dec8e-26f9-4243-8495-835709538f92.eot?#iefix") format("eot"),url("_fonts/2029e71f-067d-46a8-bc44-b5c64a258928.woff2") format("woff2"),url("_fonts/f05272f9-83e3-4de6-8423-5d57f730c87b.woff") format("woff"),url("_fonts/33f5d8d9-105f-4a49-9351-74ecae7f4a49.ttf") format("truetype"),url("_fonts/b56150eb-5caa-4385-b907-373e97ddb2ff.svg#b56150eb-5caa-4385-b907-373e97ddb2ff") format("svg");
} */

/* New project download: */
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=5301abba-59d5-4400-b893-296a512f9b39");
@font-face{
	font-weight: 400;
	/* font-family:"Avenir Next Regular"; */
	font-family:"AvenirNextLTW01-Regular";
	src:url("_fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),url("_fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff");
	font-display: swap;
}
@font-face{
	font-style: italic;
	/* font-family:"Avenir Next Italic"; */
	font-family:"AvenirNextLTW01-Italic";
	src:url("_fonts/7377dbe6-f11a-4a05-b33c-bc8ce1f60f84.woff2") format("woff2"),url("_fonts/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff") format("woff");
	font-display: swap;
}
@font-face{
	font-weight: 500;
	/* font-family:"Avenir Next Medium"; */
	font-family:"AvenirNextLTW01-Medium";
	src:url("_fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),url("_fonts/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff");
	font-display: swap;
}
@font-face{
	/* font-family:"Gill Sans W01 Light"; */
	font-weight:300;
	font-family:"Gill Sans Web";
	src:url("_fonts/a2926047-aec5-42f4-b352-e593469518be.woff2") format("woff2"),url("_fonts/c56da29d-9c5b-4d94-900f-770cde3dd317.woff") format("woff");
	font-display: swap;
}
@font-face{
	/* font-family:"Gill Sans W01 Book"; */
	font-weight:400;
	font-family:"Gill Sans Web";
	src:url("_fonts/51318b0e-57fc-4136-b7d1-46aee6c2b565.woff2") format("woff2"),url("_fonts/2bc69477-90c2-4415-a51f-36e36eee3d5e.woff") format("woff");
	font-display: swap;
}
@font-face{
	/* font-family:"Gill Sans W01 Medium"; */
	font-weight:500;
	font-family:"Gill Sans Web";
	src:url("_fonts/d9f9decc-5fa8-4390-a105-4f89f6b7b055.woff2") format("woff2"),url("_fonts/cd5a93fc-2bb1-48df-8982-11119cd6e270.woff") format("woff");
	font-display: swap;
}
@font-face{
	/* font-family:"Gill Sans W01 Bold"; */
	font-weight:600;
	font-family:"Gill Sans Web";
	src:url("_fonts/2029e71f-067d-46a8-bc44-b5c64a258928.woff2") format("woff2"),url("_fonts/f05272f9-83e3-4de6-8423-5d57f730c87b.woff") format("woff");
	font-display: swap;
}

/* Example tokeninput style #1: Token vertical list*/
.c-tag-input__list {
	z-index: 4;
	clear: left;
	height: auto !important;
	height: 1%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	border: 1px solid var(--color--primary-blue);
	list-style-type: none;
	background-color:var(--color--grey-1);
	cursor: text;
}

.c-tag-input__list:--hocus{
	border-color: var(--color--primary-orange);
}

.c-tag-input__highlighted-item{
	border-color: var(--color--primary-orange);
}

.c-tag-input__list li input {
	padding: 3px 8px;
	border: 0;
	-webkit-appearance: caret;
}

.c-tag-input__list .c-tag {
	margin: 4px;
}

.c-tag-input__input-item {
	display: inline-block;
	background-color:var(--color--grey-1);
}

.c-tag-input__dropdown {
	@mixin text-style-zeta;
	position: absolute;
	z-index:4;
}

div.c-tag-input__dropdown p {
	padding: 5px;
}


div.c-tag-input__dropdown ul li.c-tag-input__dropdown-item {
	background-color: var(--color--grey-1);
}

div.c-tag-input__dropdown ul li.c-tag-input__dropdown-item-2 {
	background-color: #fff;
}

.c-tag-input__dropdown-item-2,
.c-tag-input__dropdown-item {
	padding: calc(var(--spacing--horizontal-gutter) / 2);
	cursor: pointer;
	transition-property: background-color, color;
}

div.c-tag-input__dropdown ul li.token-input-selected-dropdown-item {
	background-color: #d0efa0;
}

.c-tag-input__dropdown-item--selected[class][class] {
	background-color: var(--color--primary-blue);
	color: #fff;
}


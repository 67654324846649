/* =============================================================================
 * Button
 *
 * 1. For disabled <input> and <button>
 * 2. For "disabled" <a>
============================================================================= */


/* Select only enabled, i.e. not disabled, buttons */

@custom-selector :--button-enabled :not([disabled], .c-button--disabled);
.c-button {
    @mixin text-style-digamma;
    display: inline-block;
    position: relative;
    width: auto;
    overflow: visible;
    padding: 8px 22px 6px;
    outline: none;
    border: 1px solid var(--color--primary-blue);
    border-radius: 100px;
    background-color: var(--color--white);
    color: red;
    color: var(--color--primary-blue);
    font-size: 16px;
    line-height: 28px;
    vertical-align: top;
    text-align: center;
    text-decoration: underline transparent;
    /*font-family: var(--type--family-default);*/
    cursor: pointer;
    transition-property: color, background-color, border-color, text-decoration-color;
}

.c-button:--button-enabled:focus::after {
    position: absolute;
    top: -8px;
    left: -8px;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    border: solid 2px var(--color--outline);
    border-radius: 9em;
    content: '';
}

input[type="submit"].c-button:focus {
    outline: solid 2px var(--color--outline);
    outline-offset: 2px;
    border-color: var(--color--outline);
}

.c-button:--button-enabled:hover {
    border-color: color(var(--color--primary-blue) tint(30%));
    background: var(--color--grey-2);
    color: color(var(--color--primary-blue) tint(30%));
    text-decoration: underline;
}

.c-button:not(.c-button--icon):--button-enabled:hover {
    color: color(var(--color--primary-blue) tint(30%));
}

.c-button[disabled]
/*[1]*/

,
.c-button--disabled
/*[2]*/

{
    opacity: 0.3;
    cursor: not-allowed;
}


/* Blank
---------------------------------------------------------------------------- */

.c-button--blank {
    padding: 0;
    border: none;
    background: transparent;
    color: inherit;
}

.c-button--blank[class]:--button-enabled:--hocus {
    background: none;
}


/* Block
---------------------------------------------------------------------------- */

.c-button--block {
    width: 100%;
}

@media (--sm) {
    .c-button--block\@sm {
        width: 100%;
    }
}

@media (--md) {
    .c-button--block\@md {
        width: 100%;
    }
}

@media (--lg) {
    .c-button--block\@lg {
        width: 100%;
    }
}

@media (--xl) {
    .c-button--block\@xl {
        width: 100%;
    }
}


/* Icons
 *
 * 1. Block buttons should have enough space to not need margin
 * 2. Center icon in "circle"
---------------------------------------------------------------------------- */

.c-button__icon-holder {
    position: absolute;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    overflow: hidden;
    background: transparent;
}

.c-button__icon-holder--large {
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
}

.c-button__icon {
    position: absolute;
    top: 50%;
    /*[2]*/
    left: 50%;
    /*[2]*/
    transform: translate(-50%, -50%);

    /*[2]*/
}

.c-button--primary .c-button__icon {
    color: var(--color--white);
}

.c-button--icon-right:not(.c-button--block)
/*[1]*/

{
    padding-right: 42px;
}

.c-button--icon-left:not(.c-button--block)
/*[1]*/

{
    padding-left: 42px;
}

.c-button--icon-right .c-button__icon-holder {
    right: 18px;
}

.c-button--icon-left .c-button__icon-holder {
    left: 18px;
}

.c-button--icon-right .c-button__icon-holder--large {
    right: 12px;
}

.c-button--icon-left .c-button__icon-holder--large {
    left: 12px;
}


/* 
* Flex icons
*/

.c-button--icon-flex {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.c-button--icon-flex .c-button__icon-holder {
    display: inline-flex;
    position: initial;
    align-items: center;
}

.c-button--icon-flex-right .c-button__icon-holder {
    margin-left: 8px;
}

.c-button--icon-flex-left {
    flex-direction: row-reverse;
}

.c-button--icon-flex-left .c-button__icon-holder {
    margin-right: 8px;
}

@media (--md-lg-xl) {
    .c-button--icon-flex {
        width: auto;
    }
}


/* Helptext
 *
 * 1. Needs to be inline-block to not get propagated text-decoration
 *    http://stackoverflow.com/a/17347691
---------------------------------------------------------------------------- */

.c-button--helptext {
    min-width: 290px;
    padding: 11px 35px 13px;
    line-height: 22px;
}

.c-button__helptext {
    @mixin text-style-kappa;
    display: inline-block;

    /*[1]*/
}

.c-button--helptext .c-button__action:first-child {
    margin-top: 2px;
}

.c-button--helptext:focus .c-button__helptext {
    text-decoration: none;
}

.c-button--helptext:hover .c-button__helptext {
    text-decoration: underline;
}

.c-button__action {
    display: block;
    line-height: inherit;
}

@media (--sm) {
    .c-button--helptext {
        min-width: 0;
    }
}


/* Primary
---------------------------------------------------------------------------- */

.c-button--primary {
    border-color: var(--color--primary-blue);
    background: var(--color--primary-blue);
    color: #fff;
}

.c-button--primary:--button-enabled:hover[class] {
    border-color: color(var(--color--primary-blue) tint(30%));
    background: color(var(--color--primary-blue) tint(30%));
    color: color(#fff tint(30%));
    text-decoration: underline;
    text-decoration-color: #fff;
}

.c-button--primary .c-button__helptext {
    @mixin text-style-lambda;
    color: inherit;
}

.c-button--primary .c-button__action {
    @mixin text-style-mu;
    color: inherit;
    text-transform: unset;
}


/* Alternative button
---------------------------------------------------------------------------- */

.c-button--alternative {
    border-radius: 100px;
    font-weight: 300;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.c-button--alternative[disabled]
/*[1]*/

,
.c-button--disabled.c-button--alternative
/*[2]*/

{
    border-color: var(--color--grey--lighter) !important;
    background: var(--color--grey--lighter) !important;
    color: var(--color--grey) !important;
    opacity: 1;
}

.c-button--alternative[disabled] .c-button__icon-holder
/*[1]*/

,
.c-button--disabled.c-button--alternative .c-button__icon-holder
/*[2]*/

{
    opacity: 0.3;
    cursor: not-allowed;
}

.c-button--primary.c-button--color-3.c-button--alternative {
    color: #FFF;
}


/* Borderless button
---------------------------------------------------------------------------- */

.c-button--borderless {
    border-color: transparent;
    text-decoration: underline;
}

.c-button--borderless:hover {
    border-color: transparent;
    background-color: var(--color--white);
    text-underline-position: under;
}

.c-button--borderless:--button-enabled:hover {
    border-color: transparent;
    background-color: var(--color--white);
    color: var(--color--primary-blue);
}

.c-button--borderless:not(.c-button--icon):--button-enabled:hover {
    color: var(--color--primary-blue);
}

.c-button--borderless[disabled] {
    border-color: transparent;
}


/* Colors
---------------------------------------------------------------------------- */

@define-mixin button-color $number,
$color {
    .c-button--color-$(number) {
        border-color: $color;
        color: $color;
    }

    .c-button--color-$(number) .c-button__icon {
        color: $color;
    }

    .c-button--color-$(number)[disabled] {
        border-color: $color;
    }

    .c-button--color-$(number):--button-enabled:hover {
        border-color: color($color tint(30%))!important;
        color: color($color tint(30%))!important;
        text-decoration-color: color($color tint(30%));
    }

    input[type="submit"].c-button--color-$(number):focus {
        border-color: $color;
    }

    .c-button--primary.c-button--color-$(number) {
        border-color: $color;
        background: $color;
        color: #fff;
    }

    .c-button--primary.c-button--color-$(number):--button-enabled:hover {
        border-color: color($color tint(30%));
        background: color($color tint(30%));
        color: color(#fff tint(30%))!important;
        text-decoration-color: color(#fff tint(30%));
    }

    .c-button--primary.c-button--alternative.c-button--color-$(number):--button-enabled:hover {
        border-color: color($color tint(30%));
        background: color($color tint(30%));
        color: color(#fff tint(30%))!important;
        text-decoration-color: color(#fff tint(30%));
    }

    .c-button--primary.c-button--alternative.c-button--color-$(number):--button-enabled:focus {
        border-color: color($color);
        background: color($color);
        text-decoration: none;
    }

    .c-button--primary.c-button--alternative.c-button--color-$(number):--button-enabled:active {
        background: color($color shade(25%));
        text-decoration: none;
    }

    .c-button--color-$(number):not(.c-button--primary):--button-enabled:hover .c-button__icon {
        color: color($color tint(30%));
    }

    .c-button--primary.c-button--color-$(number) .c-button__icon {
        color: #fff;
    }

    .c-button--primary.c-button--color-$(number):--button-enabled:hover .c-button__icon {
        color: color(#fff tint(10%));
    }

    .c-button--borderless.c-button--color-$(number) {
        border-color: transparent;
    }

    .c-button--borderless.c-button--color-$(number):--button-enabled:hover {
        border-color: transparent!important;
        background: var(--color--white);
        color: $color!important;
        text-decoration-color: $color!important;
    }

    .c-button--color-$(number):is(.c-button--borderless):--button-enabled:hover .c-button__icon {
        color: $color;
    }
}

@mixin button-color 1,
var(--color--primary-purple);
@mixin button-color 2,
var(--color--primary-orange);
@mixin button-color 3,
var(--color--primary-green);
@mixin button-color 4,
var(--color--secondary-darkblue);
@mixin button-color 5,
var(--color--secondary-eggplant);
@mixin button-color 6,
var(--color--secondary-forestgreen);
@mixin button-color 7,
var(--color--primary-purple);
@mixin button-color 8,
var(--color--white);
@mixin button-color 9,
var(--color--secondar-red);

/* Themeing buttons
---------------------------------------------------------------------------- */

@define-mixin themed-button $theme,
$bg,
$fg {
    .nacka-theme-$(theme) .c-button[class],
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button[class] {
        border-color: $bg;
        color: $fg;
    }

    .nacka-theme-$(theme) .c-button[class]:--button-enabled:--hocus,
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button[class]:--button-enabled:--hocus {
        border-color: color($bg tint(30%));
    }

    .nacka-theme-$(theme) .c-button--primary[class],
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button--primary[class] {
        border-color: $bg;
        background-color: $bg;
        color: #fff;
    }

    .nacka-theme-$(theme) .c-button--primary[class]:--button-enabled:--hocus,
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button--primary[class]:--button-enabled:--hocus {
        border-color: color($bg tint(30%));
        background-color: color($bg tint(30%));
    }

    .nacka-theme-$(theme) .c-button--primary[class] .c-button__icon,
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button--primary[class] .c-button__icon {
        color: #fff;
    }

    .nacka-theme-$(theme) .c-button[class]:not(.c-button--primary) .c-button__icon-holder,
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button[class]:not(.c-button--primary) .c-button__icon-holder {
        background-color: $bg;
    }

    .nacka-theme-$(theme) .c-button[class]:not(.c-button--primary):--button-enabled:--hocus .c-button__icon-holder,
    [class^="nacka-theme-"] .nacka-theme-$(theme) .c-button[class]:not(.c-button--primary):--button-enabled:--hocus .c-button__icon-holder {
        background-color: color($bg tint(30%));
    }
}


/* White special */

.c-button--color-8 {
    background-color: transparent;
}

.c-button--color-8:hover {
    background-color: rgba(255, 255, 255, 0.3)!important;
}

@mixin themed-button 1,
var(--color--primary-green),
var(--color--primary-green);
@mixin themed-button 2,
var(--color--primary-orange),
var(--color--primary-orange);
@mixin themed-button 3,
var(--color--primary-purple),
var(--color--primary-purple);
@mixin themed-button 4,
var(--color--primary-blue),
var(--color--primary-blue);
@mixin themed-button 5,
var(--color--ybc-green),
#000;
@mixin themed-button 6,
var(--color--school-blue),
var(--color--school-blue);
/* =============================================================================
 * Handle whitespace explicitly
============================================================================= */

/* Clear all floats */
.t-clear {
	clear:both;
}

/* Avoid all linebreaks */
.t-nowrap {
	white-space: nowrap;
}
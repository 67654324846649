/* =============================================================================
 * Divided list
============================================================================= */
.c-divided-list__item {
	border:0 solid color(black tint(90%));
	border-bottom-width: 1px;
}

.c-divided-list--max-1[class] .c-divided-list__item {
	border-bottom-width: 1px;
}

.c-divided-list--max-1[class] .c-divided-list__item:last-child {
	border-bottom-width:0;
}

@media (--sm) {
	.c-divided-list:not(.c-divided-list--max-1) .c-divided-list__item:last-child {
		border-bottom-width: 0;
	}
}

@media (--md-lg-xl) {
	.c-divided-list:not(.c-divided-list--max-1) .c-divided-list__item:nth-last-child(-n+2):nth-child(2n+1),
	.c-divided-list:not(.c-divided-list--max-1) .c-divided-list__item:nth-last-child(-n+2):nth-child(2n+1) + .c-divided-list__item {
		border-bottom-width: 0;
	}

	.c-divided-list--max-2 .c-divided-list__item:nth-child(2n-1) {
		border-right-width: 1px;
	}

	.c-divided-list--max-3 .c-divided-list__item:nth-child(2n-1) {
		border-right-width: 1px;
	}
}

@media (--lg-xl) {
	.c-divided-list--max-3 .c-divided-list__item:nth-last-child(-n+3):nth-child(3n+1),
	.c-divided-list--max-3 .c-divided-list__item:nth-last-child(-n+3):nth-child(3n+1) + .c-divided-list__item {
		border-bottom-width: 0;
	}

	.c-divided-list--max-3 .c-divided-list__item:nth-child(n) {
		border-right-width: 1px;
	}

	.c-divided-list--max-3 .c-divided-list__item:nth-child(3n+3) {
		border-right-width: 0;
	}
}
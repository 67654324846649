@keyframes c-spinner--spin {
	100% {
		transform: rotate(360deg);
	}
}

.c-spinner {
	position: absolute;
	top: calc(50% + 20px);
	left: calc(50% + 20px);
	transform: translateX(-50%) translateY(-50%);
}

.c-spinner svg{
	position:relative;
	overflow: visible;
}

.c-spinner .st3{
	transform: translate(-20px, -20px);
}


.c-spinner #dot{
	animation: c-spinner--spin 1.2s linear infinite;
}

.c-spinner img{
	top: calc(50% + 20px);
	left: calc(50% + 20px);
	transform: translateX(-50%) translateY(-50%);
}
.c-message {
	margin-bottom: var(--spacing--vertical-spacing-unit);
	border: 1px solid var((--color--secondar-red));
	background: var(--color--white);
	color: var(--type--color-default);
}

.c-message--filled {
	background-color: var(--color--secondar-red);
	color: var(--color--white);
}

.c-message--color-1 {
	border-color: var(--color--primary-blue);
}

.c-message--filled.c-message--color-1 {
	background-color: var(--color--primary-blue);
}

.c-message--color-2 {
	border-color: var(--color--primary-purple);
}

.c-message--filled.c-message--color-2 {
	background-color: var(--color--primary-purple);
}

.c-message--color-3 {
	border-color: var(--color--primary-orange);
}

.c-message--filled.c-message--color-3 {
	background-color: var(--color--primary-orange);
}

.c-message--color-4 {
	border-color: var(--color--primary-green);
}

.c-message--filled.c-message--color-4 {
	background-color: var(--color--primary-green);
}

.c-message--color-5 {
	border-color: var(--color--secondary-darkblue);
}

.c-message--filled.c-message--color-5 {
	background-color: var(--color--secondary-darkblue);
}

.c-message--color-6 {
	border-color: var(--color--secondary-purple);
}

.c-message--filled.c-message--color-6 {
	background-color: var(--color--secondary-purple);
}

.c-message--color-7 {
	border-color: var(--color--secondary-forestgreen);
}

.c-message--filled.c-message--color-7 {
	background-color: var(--color--secondary-forestgreen);
}

.c-message--color-8 {
	border-color: var(--color--primary-purple);
}

.c-message--filled.c-message--color-8 {
	background-color: var(--color--primary-purple);
}

.c-message--filled.c-message--color-9 {
	background-color: var(--color--secondary-red);
}

.c-message__text-wrapper {
	flex-basis: 20em;
}

.c-message__status {
	margin-right: var(--spacing--xs);
	font-family: "AvenirNextLTW01-Medium", 'Avenir Next', sans-serif;
	text-transform: uppercase;
}

a.c-message__link {
	font-family: var(--type--family-primary-bold);
	text-decoration: underline;
}

a.c-message__link:hover {
	color: rgba(255, 255, 255, 0.6);
}

.c-message--filled a.s-text {
    color: var(--color--white);
}

.c-feedback__title {
	@mixin text-style-beta;
}

.c-feedback__title-container[class] {
	flex-shrink:1;
}

.c-feedback__button-container {
	display:inline-block;
	margin:0 calc(var(--spacing--horizontal-gutter) / 2);
}

@media (--sm) {
    .c-feedback__button-container {
        margin: var(--spacing--m) 0;
    }
}
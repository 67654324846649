.c-process-list {
	margin-left: 0!important;
	list-style: none!important;
	counter-reset: process-list__counter;
}

.c-process-list__item {
	position:relative;
	max-width: 650px;
	padding:0 20px 20px 70px;
}

.c-process-list__item:before {
	@mixin text-style-pi;
	@mixin border-grey-light;
	position: absolute;
	top: 0;
	left: 0;
	width:50px;
	height: 50px;
	padding-top:12px;
	border-radius: 100%;
	content: counter(process-list__counter) ".";
	counter-increment: process-list__counter;
	text-align: center;
}

.c-process-list__title[class] {
	@mixin text-style-beta !important;
	display:inline-block;
	padding-top:5px;
	vertical-align:middle;
}

.c-process-list__sub-title[class] {
	@mixin text-style-epsilon;

}

@media (--sm){
	.c-process-list__item{
		padding:0;
	}

	.c-process-list__item:before {
		display: block;
		position:initial;
		margin: calc(var(--spacing--vertical-spacing-unit) * 2) auto 0;
	}
}

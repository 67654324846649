.c-autocomplete {
	position: relative;
}

.c-autocomplete__results {
	@mixin border-grey-light;
	z-index: 90;
	background-color: #fff;
}

.c-autocomplete__group {
	padding: calc(var(--spacing--horizontal-gutter) / 2);
	background: var(--color--link-color);
	color: #fff;
}

.c-autocomplete__item {
	padding: calc(var(--spacing--horizontal-gutter) / 2);
	border-bottom: 1px solid var(--color--grey-2);
	cursor: pointer;
}

.c-autocomplete__item.ui-state-focus {
	background: var(--color--grey-1);
}

.c-autocomplete__item-information {
	@mixin text-style-reset;
	padding-right: calc(var(--spacing--horizontal-gutter) * 2);
}

.c-autocomplete__item-information:last-child {
	padding-right: 0;
}

.c-autocomplete__group-heading {
	@mixin text-style-zeta;
}

.c-autocomplete__item-title {
	@mixin text-style-zeta;
	display: block;
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	color: var(--color--link-color);
}

.c-autocomplete__item-title:only-child {
	margin-bottom: 0;
}

.mp_highlighted .c-autocomplete__item-link,
.c-autocomplete__item-link:--hocus,
.c-autocomplete__item.ui-state-focus .c-autocomplete__item-link,
.mp_highlighted .c-autocomplete__item-title,
.c-autocomplete__item-title:--hocus,
.c-autocomplete__item.ui-state-focus .c-autocomplete__item-title {
	text-decoration: underline;
}

.c-autocomplete__item-link[class] {
	@mixin text-style-zeta;
	color: #fff;
}

.mp_list {
	@mixin border-grey-light;
	position: absolute;
	z-index: 99;
	width: 100%;
	margin-top: 1px;
	background-color: #fff;
}

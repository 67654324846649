.c-deparment-banner {

}

.c-department-banner__image img{
 width:100%;
 height:auto;
}

.c-department-banner__title {
	@mixin text-style-alpha;
	display: inline-block;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	word-break: break-word;
	hyphens: auto;
}

.c-department-banner__items {
	display: flex;
	align-items: center;
	flex-flow: row wrap;
}

.c-department-banner__item {
	flex: 1 0 25%;
	min-width: 250px;
	margin-bottom: var(--spacing--vertical-spacing-unit);
}
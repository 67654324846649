#header-campaign-navigation-mobile {
	width: 100%;
	padding: 20px;
}

#header-campaign-navigation-mobile .o-media__primary {
	text-align: center;
}

#header-campaign-navigation-mobile .o-media__primary .c-panel__title {
	margin-left: 24px;
	color: black;
	font-weight: 500;
}

.c-navigation-campaign-mobile {
	display: flex;
	flex-grow: 1;
}

.c-navigation-campaign-mobile__list {
	width: 100%;
	text-align: center;
}

.c-navigation-campaign-mobile__supplementary {
	margin: auto 0 calc(2 * var(--spacing--vertical-spacing-unit));
	padding: var(--spacing--horizontal-gutter) 0;
}

.c-navigation-campaign-mobile__item {
	flex-shrink: 0;
	text-transform: uppercase;
}

.c-navigation-campaign-mobile__supplementary-list-item {
	width: 100%;
	padding: calc(var(--spacing--horizontal-gutter)/2);
	border-top: 1px solid var(--color--grey-2);
	border-bottom: 1px solid var(--color--grey-2);
	text-align: center;
}

.c-navigation-campaign-mobile__supplementary-list-item:not(:first-child) {
	border-top: none;
}

.c-navigation-campaign-mobile__supplementary-list {
	margin-bottom: var(--spacing--horizontal-gutter);
}

.c-navigation-campaign-mobile__list > .c-navigation-campaign-mobile__item[aria-expanded="true"] ul:last-child,
.c-navigation-campaign-mobile__sub-list > .c-navigation-campaign-mobile__item[aria-expanded="true"] ul:last-child {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit));
}

.c-navigation-campaign-mobile__item--expanded > .o-space-between {
	padding-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-navigation-campaign-mobile__item--expanded {
	padding-bottom: 0;
}

.c-navigation-campaign-mobile__link {
	display: block;
	padding: 16px 0;
	color: black;
	font-weight: 400;
}

.c-navigation-campaign-mobile__link:--hocus {
	background-color: #E5E5E5;
}

.c-navigation-campaign-mobile__level-toggler {
	padding-right: calc(var(--spacing--horizontal-gutter)/2);
}

.c-header-campaign-navigation-mobile--close {
	float: right;
}

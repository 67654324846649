.c-header-campaign-mobile {
	position: relative;
	padding: 0;
	background-color: #fff;
}

@media (--lg-xl) {
	.c-header-campaign-mobile {
		display: none;
	}
}

@media print {
	.c-header-campaign-mobile {
		display: none;
	}
}

.c-header-campaign-mobile__inner {
	@media (--xl) {
			padding: var(--spacing--horizontal-gutter) calc(var(--spacing--horizontal-gutter) * 2) var(--spacing--horizontal-gutter) calc(var(--spacing--horizontal-gutter) * 2);
	}
	display: flex;
	position: relative;
	justify-content: space-between;
	padding: var(--spacing--horizontal-gutter);
	border-bottom: 2px solid #41748D; /* TODO variable? */
}


.c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--primary-blue);
}

.nacka-theme-1 .c-header-campaign-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-1 .c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--primary-green);
}

.nacka-theme-2 .c-header-campaign-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-2 .c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-header-campaign-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-3 .c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-header-campaign-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-4 .c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-header-campaign-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-5 .c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-header-campaign-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-6 .c-header-campaign-mobile__inner::after {
	border-top-color: var(--color--school-blue);
}


.c-header-campaign-mobile__logo {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	max-width: 140px;
}

.c-header-campaign-mobile__logo img {
	display: block;
	width: auto;
	height: 60px;
}

.c-header-campaign-mobile__profile {
	display: flex;
	justify-content: flex-end;
	text-align: right;
}

.c-header-campaign-mobile__menu-items {
	display: flex;
	align-items: center;
}

.c-header-campaign-mobile__menu-item,
.c-header-campaign-mobile__menu-item .js-toggle-scope-target {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 10px;
}
.c-header-campaign-mobile__menu-item span {
	font-size: 9px!important;
}

.c-header-campaign-mobile__menu-items > :last-child {
	margin-right: 0;
}

.c-header-campaign-mobile__profile,
.c-header-campaign-mobile__search,
.c-header-campaign-mobile__menu-button,
.c-header-campaign-mobile__logo {
	margin: 0 calc(var(--spacing--horizontal-gutter) / 6);
}

.c-header-campaign-mobile__search-form {
	padding: calc(var(--spacing--horizontal-gutter) / 2);
	border-bottom: 2px solid #796E65;
}

.c-header-campaign-mobile__search-form form {
	display:flex;
	background-color: #fff;
}

.c-header-campaign-mobile__search-form--form form {
	border: none;
}
.c-header-campaign-mobile__search-form .c-search-form__button .c-search-form__icon {
	fill: black;
}

.c-header-campaign-mobile__search-form .c-search-form__button input,
.c-header-campaign-mobile__search-form .c-search-form__field {
	min-height: auto;
	height: 40px;
}

.c-header-campaign-mobile__search-form .c-search-form__button input {
	width: 40px;
	padding-right: 0px;
	border-radius: 50%;
}
.c-header-campaign-mobile__search-form .c-search-form__button .c-button:hover {
	border-radius: 50%;
}
.c-header-campaign-mobile__search-form .c-search-form__field input {
	min-height: auto;
	height: 46px;
	border-radius: 25px;
	background: var(--color--white);
}

.c-header-campaign-mobile__search-form .c-search-form__field input:focus {
	border: 1px solid #796E65;
}

.c-header-campaign-mobile__search-form .c-search-form__field input:focus, .c-header-campaign-mobile__search-form .c-search-form__field input:hover {
    border-color: var(--color--primary-green);
}

.c-search-form__button .c-button:hover {
	border-radius: 0;
}

.c-header-campaign-mobile--close {
	display: flex !important;
	align-items: center;
}

.c-header-campaign-mobile--close:hover {
	text-decoration: underline;
}

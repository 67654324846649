/* =============================================================================
 * Full width block
 *
============================================================================= */
.c-text-block-cta {
	padding: 50px 20%; /* Not same as other full width blocks! */
	text-align: center;
}

.c-text-block-cta__title {
	@mixin text-style-beta;
	font-weight: 400;
	font-size: 36px;
	line-height: 44px;
}
.c-text-block-cta__text {
	@mixin text-style-zeta;
}

/* =============================================================================
 * Forms
============================================================================= */


/*
 * 1. Enable line-wrapping in IE8+
 * 2. Enable line-wrapping in other browsers
 */

legend {
    @mixin text-style-epsilon;
    display: table;
    /* [1] */
    white-space: normal;

    /* [2] */
}

label {
    @mixin text-style-sigma;
    margin-bottom: 8px;
}

label em {
    @mixin text-style-reset;
}

button,
input,
select,
textarea {
    color: var(--type--color-default);
    font: inherit;
    font-size: var(--type--size-default);
    transition-property: border;
}

select {
    width: 100%;
}

input:not([type]),
input[type="text"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea {
    min-height: 44px;
    width: 100%;
    padding: 0 16px;
    outline: none;
    border: 1px solid var(--color--black);
    border-radius: 0;
    background-color: var(--color--white);
    line-height: 38px;
    -webkit-appearance: none;
}

input:not([type])[disabled],
input[type="text"][disabled],
input[type="url"][disabled],
input[type="email"][disabled],
input[type="tel"][disabled],
input[type="number"][disabled],
input[type="password"][disabled],
input[type="search"][disabled],
input[type="button"][disabled],
textarea [disabled],
button[disabled] {
    border-color: var(--color--primary-blue);
    opacity: 0.3;
    cursor: not-allowed;
}


/*
 * 1. Reset button look in webkit
 */

input[type="reset"],
input[type="button"],
input[type="submit"],
button {
    -webkit-appearance: none;

    /* [1] */
}

button {
    cursor: pointer;
}

textarea:--hocus,
input[type="text"]:--hocus,
input[type="url"]:--hocus,
input[type="email"]:--hocus,
input[type="tel"]:--hocus,
input[type="date"]:--hocus,
input[type="time"]:--hocus,
input[type="number"]:--hocus,
input[type="password"]:--hocus,
input[type="search"]:--hocus {
    border-width: 2px;
    border-color: var(--color--primary-blue);
    border-left-width: 8px;
}


/*
 * 1. Reset search input look in webkit, making it possible to
 *    style it as a normal text input
 */

input[type="search"] {
    -webkit-appearance: none;

    /* [1] */
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
    display: none;

    /* [1] */
}


/*
 * 1. Placeholder styling for Gecko 18- (first two rows of selector)
 *    and Gecko 19+ (second two rows of selector)
 */

input:-moz-placeholder,
textarea:-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder {
    /* [1] */
    color: var(--color--black);
    opacity: 1;
}


/*
 * 1. Placeholder styling for webkit
 */

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: var(--color--black);

    /* [1] */
}


/*
 * 1. Placeholder styling for IE10
 */

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: var(--color--black);

    /* [1] */
}


/*
 * 1. Reset extra padding on gecko buttons
 */

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
    /* [1] */
    padding: 0;
    border: 0;
}

fieldset {
    border: none;
}

legend {
    margin-bottom: 12px;
}

input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    width: 0;
    opacity: 0;
}

input[type="radio"]:disabled~label,
input[type="checkbox"]:disabled~label {
    color: var(--color--secondary-grey);
}

input[type="radio"]:not(:disabled)~label,
input[type="checkbox"]:not(:disabled)~label {
    padding-right: 9px;
    cursor: pointer;
}

input[type="radio"]:not(:disabled)~label:hover,
input[type="checkbox"]:not(:disabled)~label:hover {
    text-decoration: underline;
}

input[type="checkbox"]:not(:disabled):focus-visible~label,
input[type="radio"]:not(:disabled):focus-visible~label {
    outline: 2px solid black;
    outline-offset: 2px;
}

input[type="radio"]:disabled~label:before,
input[type="checkbox"]:disabled~label:before {
    border-color: var(--color--secondary-grey);
    cursor: not-allowed;
}

input[type="radio"]~label:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 9px;
    border: 1px solid var(--color--black);
    border-radius: 100%;
    box-shadow: inset 0 0 0 4px white;
    background: white;
    content: '';
    vertical-align: -6px;
    cursor: pointer;
    transition: 0.15s ease;
}

input[type="radio"]:not(:disabled):--hocus~label:before {
    border-color: var(--color--primary-orange);
}

input[type="radio"]:not(:disabled):checked~label:before {
    background: var(--color--primary-blue);
}

input[type="radio"]:disabled:checked~label:before {
    background: var(--color--grey-2);
}

input[type="checkbox"]~label:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 9px;
    border: 1px solid var(--color--black);
    border-radius: 2px;
    background: white;
    content: '';
    vertical-align: -6px;
    cursor: pointer;
    transition: 0.15s ease;
}

input[type="checkbox"]:checked~label {
    color: var(--color--secondary-grey);
    font-weight: 800;
    font-family: "AvenirNextLTW01-Medium";
}

input[type="checkbox"]:checked~label:before {
    border-color: var(--color--secondary-grey);
    box-shadow: inset 0 0 0 4px var(--color--secondary-grey);
    background: url('../../components/icon/icons/checkbox-white.svg') no-repeat;
    background-color: var(--color--secondary-grey);
    background-position: 50%;
    background-size: 16px 12px;
}

input[type="checkbox"]:not(:disabled):checked~label {
    color: var(--color--primary-blue);
}

input[type="checkbox"]:not(:disabled):checked~label:before {
    border-color: var(--color--primary-blue);
    box-shadow: inset 0 0 0 4px var(--color--primary-blue);
    background-color: var(--color--primary-blue);
}

input[type="checkbox"]:not(:disabled):checked:hover~label {
    color: var(--color--hover-blue);
}

input[type="checkbox"]:not(:disabled):checked:hover~label:before {
    border-color: var(--color--hover-blue);
    box-shadow: inset 0 0 0 4px var(--color--hover-blue);
    background-color: var(--color--hover-blue);
}
.c-news-block-item {
	display: flex;
	position:relative;
	flex-direction: column;
	justify-content: stretch;
	align-items: center;
	min-height: 270px;
	height: 100%;
	margin-bottom: 18px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px 0px rgba(13,13,13,1);
	color: white;
	text-align: center;
	transition: all 0.2s ease-in-out;
}

.c-news-block-item:hover {
	box-shadow: 0px 4px 8px 0px rgba(13,13,13,1);
}

.c-news-block-item div {
	width: 100%;
}

.c-news-block-item__image {
	flex: 0 0 181px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-news-block-item:hover .c-news-block-item__image {
	opacity: 0.8;
}

.c-news-block-item__text {
	display: flex;
	flex: 1 1 auto;
	padding: 20px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: white;
}

.c-news-block-item__text h3 {
	@mixin text-style-alpha;
	width: 100%;
	color: black;
	font-weight: 400;
	font-size: 26px;
	line-height: 32px;
	text-align: left;
	text-transform: initial;
}

.c-news-block-item__sub-text p,
.c-news-block-item__text p {
	width: 100%;
	height: 35px;
	overflow: hidden;
	color: black;
	font-weight: 400;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.c-news-block-item:hover .c-news-block-item__text h3 {
	text-decoration: underline;
}

/*
.c-teaser-card__hover-overlay {
	display:none;
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color:rgba(255,255,255,0.08);
}

.c-teaser-card:hover .c-teaser-card__hover-overlay {
	display:block;
}

.c-teaser-card__image-overlay {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	box-shadow: 0px 0px 1px 0px rgba(13,13,13,0.9);
	background-color:rgba(0,0,0,0.5);
}

.c-teaser-card p {
	z-index: 1;
	font-weight: 600;
	font-size: 30px;
	line-height: 36px;
}
*/

.c-news-block-item:focus {
	outline: 4px solid #1C93FF !important;
	outline-offset: 4px;
}

/* Variant */
.c-news-block-item.c-news-block-item--variant h3 {
	color: white;
	text-align: center;
}

.c-news-block-item.c-news-block-item--variant p {
	color: var(--color---black-lighter);
	font-weight: 500;
}

.c-news-block-item__text.c-news-block-item__text--variant {
	align-items: center;
	flex: 1 0 181px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	background-color: var(--color--secondary-darkblue);
}

.c-news-block-item__sub-text {
	display: flex;
	flex: 0 1 104px;
	padding: 18px 20px 20px 20px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: white;
}

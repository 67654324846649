/* =============================================================================
 * Search form
============================================================================= */

.c-header__search {
    max-width: 752px;
    width: 100%;
    margin-left: auto;
    padding: 0 0 0 calc(var(--spacing--horizontal-gutter) * 2);
    text-align: left;
}

.c-search-form__form {
    display: flex;
    padding-left: 1px;
    background-color: var(--color--white);
}

.c-search-form__field {
    flex-grow: 2;
    min-height: 48px;
    margin-right: 24px;
    text-align: left;
}

.c-search-form--small .c-search-form__field {
    min-height: 38px;
}

.c-search-form__button {
    position: relative;
    height: 48px;
    padding-right: 52px!important;
}

.c-search-form__close {
    align-self: center;
    margin-left: 10px;
    cursor: pointer;
}

.c-search-form__button input {
    min-height: 48px;
    margin-bottom: 0;
    border: none;
    background: white;
}

.c-search-form__field input {
    min-height: 48px;
    margin-bottom: 0;
    padding-top: 2px;
    border: 1px solid var(--color--black);
    background: var(--color--white);
}

.c-search-form__field input::placeholder {
    color: var(--color--black);
}

.c-search-form__field input:--hocus {
    border-width: 2px;
    border-color: var(--color--primary-blue);
    border-left-width: 8px;
}

/* Teman */
.nacka-theme-1 .c-search-form__field input:--hocus {
    border-color: var(--color--primary-green);
}

.nacka-theme-2 .c-search-form__field input:--hocus {
    border-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-search-form__field input:--hocus {
    border-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-search-form__field input:--hocus {
    border-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-search-form__field input:--hocus {
    border-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-search-form__field input:--hocus {
    border-color: var(--color--school-blue);
}

.c-search-form__button input {
    width: 60px;
    height: 60px;
    border-radius: 0;
    text-indent: -9999px;
    cursor: pointer;
}

.c-search-form__button__icon-holder {
    top: calc(50% - 12px);
    right: 20px!important;
    width: 24px;
    height: 24px;
    border-radius: 0;
}

.c-search-form__button:focus::after {
    border-color: var(--color--black)!important;
}

.c-search-form__button:--hocus .c-search-form__icon path {
    fill: color(var(--color--link-color) shade(50%));
}
.c-profile-banner__name {
	@mixin text-style-alpha;
}

.c-profile-banner__title {
	@mixin text-style-reset;
	margin-right: calc(var(--spacing--horizontal-gutter) * 1.5);
}

.c-profile-banner__status {
	@mixin text-style-nu;
	position: relative;
}

.c-profile-banner__status:before {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 6px;
	border-radius: 50%;
	content: '';
}

.c-profile-banner__status--available:before {
 background-color: var(--color--primary-green);
}

.c-profile-banner__status--dont-disturb:before,
.c-profile-banner__status--busy:before {
	background-color: var(--color--primary-orange);
}

.c-profile-banner__status--dont-disturb:after {
	position: absolute;
	top: 10px;
	left: 3px;
	width: 6px;
	height: 2px;
	background: white;
	content: '';
}

.c-profile-banner__status--back-soon:before {
	background-color: var(--color--secondary-yellow);
}

.c-profile-banner__status-message {
	@mixin text-style-omega;
	padding-left: calc(var(--spacing--horizontal-gutter) / 2);
}

/* =============================================================================
 * Space between
 *
 * 1. Float fallback (with clearfix) for when flex is not available
============================================================================= */
@define-mixin o-space-between $container-query,  $suffix {
	$(container-query) .o-space-between$(suffix) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	$(container-query) .o-space-between$(suffix)::after /*[1]*/ {
		display: table;
		clear: both;
		content: "";
	}

	$(container-query) .o-space-between$(suffix).o-space-between--shrinkable {
		flex-wrap: nowrap;
	}

	$(container-query) .o-space-between$(suffix).o-space-between--middle {
		align-items: center;
	}

	$(container-query) .o-space-between$(suffix).o-space-between--bottom {
		align-items: flex-end;
	}

	$(container-query) .o-space-between$(suffix) > .o-space-between__item {
		flex-shrink: 0;
		max-width: 100%;
	}

	$(container-query) .o-space-between$(suffix) > .o-space-between__item:first-child {
		float: left; /*[1]*/
		flex-grow:1;
		padding-right: calc(var(--spacing--horizontal-gutter) / 2);
	}

	$(container-query) .o-space-between$(suffix) > .o-space-between__item:last-child {
		float: right; /*[1]*/
	}

	$(container-query) .o-space-between$(suffix) > .o-space-between__item:first-child:last-child {
		flex-grow: 1;
	}

	$(container-query) .o-space-between$(suffix) > .o-space-between__item--shrinkable {
		flex-shrink: 1;
	}
}

@mixin o-space-between;
@mixin o-space-between .o-container:container(width > 300px), \@300;
@mixin o-space-between .o-container:container(width > 500px), \@500;
@mixin o-space-between .o-container:container(width > 700px), \@700;
@mixin o-space-between .o-container:container(width > 900px), \@900;
@mixin o-space-between .o-container:container(width > 1100px), \@1100;

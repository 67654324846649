@import "vendor/dialog-polyfill-master/dialog-polyfill.css";

/* 1. Make room for the loading spinner */
.c-dialog {
	max-width: 95vw;
	min-height: 50px; /* [1] */
	width: 1045px;
	margin: 10px auto;
	padding: 50px 30px 30px;
	border: none;
	box-shadow: 0 6px 21px rgba(0, 0, 0, 0.35);
	background: #fff;
}

@media (--sm) {
	.c-dialog {
		padding: 10px;
	}
}

.c-dialog[open] {
	animation: c-dialog--open 0.1s ease-in-out;
}

@keyframes c-dialog--open {
	0% {
		opacity: 0;
		transform: translateY(15px) scale(0.95);
	}

	100% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
}

.c-dialog__close-button[class] {
	position: absolute;
	top: 24px;
	right: 30px;
}

@media (--sm) {
	.c-dialog__close-button[class] {
		top: -8px;
		right: -8px;
	}
}

/* Backdrop
 *
 * 1. Rules cannot be combined as older browsers might choke on
 *    the ::backdrop declaration and discard the whole rule.
-------------------------------------------------------------- */
.c-dialog::backdrop /*[1]*/ {
	background-color: rgba(0, 0, 0, 0.62);
	animation: c-dialog--backdrop 0.4s ease-in-out;
}

.c-dialog + .backdrop /*[1]*/ {
	background-color: rgba(0, 0, 0, 0.62);
	animation: c-dialog--backdrop 0.4s ease-in-out;
}

@keyframes c-dialog--backdrop {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

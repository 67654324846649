.c-news-block {
    padding: 50px 20px;
    background-color: var(--color--grey--lighter);
}

@media (--md-lg-xl) {
    .c-news-block {
        padding: 50px 20px 50px 20px;
    }
}

.c-news-block h2 {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
}

.c-news-block__items {
    display: flex;
    align-items: stretch;
    max-width: var(--width-limiter--narrow);
    margin: auto;
}

.c-news-block .c-call-to-action {
    margin-top: 12px;
}
.c-task-list {
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-task-list--indent {
	padding-left: var(--spacing--horizontal-spacing-unit);
}

.c-task-list__item {
	margin-bottom: 7px;
}

.c-task-list__item-description {
	padding: 5px 0 0;
}

.c-task-list__item-link {
	@mixin text-style-rho;
	margin-right:5px;
	color: var(--color--link-color);
	line-height: 18px;
}

.c-task-list__item-link:--hocus {
	text-decoration: underline;
}

.c-task-list__item-due {
	@mixin text-style-rho;
	color: var(--color--primary-orange);
	white-space: nowrap;
}

.c-timestamp {
	@mixin text-style-tau;
	margin-top: 3px;
	margin-bottom: 2px;
}

/*Hello*/
.c-timestamp--small {
	@mixin text-style-nu;
	color: var(--color--grey-4);
}


.c-timestamp__time {
	padding-left:9px;
}

.c-timestamp--inline {
	display: inline-block;
	margin-right:9px;
}

.c-custom-file {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-custom-file__label {
	max-width: calc(100% - 45px);
}

.c-custom-file__label-icon {
	display: inline-block;
}

.c-custom-file__label-text {
	display: inline-block;
	max-width: calc(100% - 21px);
	overflow: hidden;
	vertical-align: middle;
	text-overflow: ellipsis;
	white-space: nowrap;
}
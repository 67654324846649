.c-edit-image__image {
	position: relative;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	border: 1px solid var(--color--grey-2);
	background: #fff;
	text-align: center;
}

.c-edit-image__delete {
	position: absolute;
	top: var(--spacing--vertical-spacing-unit);
	right: var(--spacing--horizontal-spacing-unit);
}
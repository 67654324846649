.thumbnails__list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -calc(var(--spacing--horizontal-spacing-unit) / 2);
}

/*
1. Items per row will adjust automatically based on these properties
*/
.c-thumbnails__item {
	float: left;
	flex-grow: 1; /* [1] */
	min-width: 230px; /* [1] */
	padding: 0 0 calc(var(--spacing--horizontal-spacing-unit) / 2) calc(var(--spacing--horizontal-spacing-unit) / 2);
	flex-basis: 230px;/* [1] */
}

.c-thumbnails__item[aria-hidden] {
	padding-bottom: 0;
}

.c-thumbnails__link {
	display: block;
	position: relative;
	overflow: hidden;
}

.c-thumbnails__link--outline::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: inset 0 0 0 4px var(--color--primary-orange);
	content: "";
}

.c-thumbnails__image {
	width: 100%;
}

.c-thumbnails__icon {
	position: absolute;
	top: 20%;
	left: 50%;
	color: #fff;
	opacity: 0;
	transition: opacity 0.4s;
	transform: translateX(-50%);
}

.c-thumbnails__link:--hocus .c-thumbnails__icon {
	opacity: 0.8;
}

.c-thumbnails__icon svg {
	filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.5));
}

.c-thumbnails__caption {
	position: absolute;
	bottom: -100%;
	left: 0;
	width: 100%;
	padding: calc(var(--spacing--horizontal-spacing-unit) / 2);
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	opacity: 0;
	transition: all 0.4s ease;
}

.c-thumbnails__link:--hocus .c-thumbnails__caption {
	bottom: 0;
	opacity: 1;
}

.c-thumbnails__heading {
	@mixin text-style-zeta;
}

.c-thumbnails__text {
	@mixin text-style-omega;
}

.c-search-results-item {
	@mixin border-grey-light;
	display: flex;
	flex-direction: row-reverse;
	padding:var(--spacing--m);
}

.c-search-results-item--only-top-border {
	border-right-width: 0;
	border-bottom-width: 0;
	border-left-width: 0;
}

.c-search-results-item__media {
	flex-shrink: 0;
	margin-left: var(--spacing--s);
}

.c-search-results-item__media--big {
	display: none;
}

.c-search-results-item:container(width > 450) .c-search-results-item__media--big {
	display: block;
	margin-left: var(--spacing--m);
}

.c-search-results-item:container(width > 450) .c-search-results-item__media--small {
	display: none;
}

.c-search-results-item__main {
	flex-grow: 1;
}

.c-search-results-item__heading {
	@mixin text-style-zeta;
	margin-bottom: var(--spacing--xs);
	color: var(--color--link-color);
}

.c-search-results-item:container(width > 450) .c-search-results-item__heading {
	@mixin text-style-beta;
	color: var(--color--link-color);
}

.c-search-results-item__document-icon--small {
	margin-top: -4px;
}

.c-search-results-item__document-icon--big {
	display: none
}

.c-search-results-item:container(width > 450) .c-search-results-item__document-icon--small {
	display: none;
}

.c-search-results-item:container(width > 450) .c-search-results-item__document-icon--big {
	display: inline-flex;
	margin-top: -7px;
}

.c-search-results-item__excerpt {
	@mixin text-style-kappa;
	margin-top: var(--spacing--xs);
}

.c-search-results-item:container(width > 450) .c-search-results-item__excerpt {
	@mixin text-style-reset;
}

.c-search-results-item__meta {
	@mixin text-style-nu;
	margin-top: var(--spacing--xs);
	color: var(--color--grey-4);
}

.c-search-results-item__meta-item {
	margin-right: var(--spacing--xs);
	white-space: nowrap;
}

.c-search-results-item__links {
	margin-top: var(--spacing--xs);
}

.c-search-results-item__link {
	margin-right: var(--spacing--s);
	white-space: nowrap;
}

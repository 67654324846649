/* =============================================================================
 * Main navigation
 *
 * 1. Border bottom. Since it is below the white BG, this seemed easier.
============================================================================= */

.c-main-navigation {
    background-color: #fff;
    color: #000;
    text-align: center;
}

.c-main-navigation__item {
    flex-shrink: 1;
    flex: 1 1 auto;
}

.c-main-navigation__link {
    @mixin text-style-zeta;
    display: block;
    position: relative;
    margin: 0 24px;
    padding: 24px 8px;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: inherit;
    font-family: var(--type--family-default);
    line-height: 20px;
}

.c-main-navigation__link:--hocus {
    background-color: var(--color--primary-blue);
    color: var(--color--white);
    text-decoration: underline;
}

.c-main-navigation__link:focus {
    outline-color: var(--color--black);
    outline-offset: -1px;
}

.o-list-inline__item:first-child .c-main-navigation__link {
    margin-left: 0;
}

.o-list-inline__item:last-child .c-main-navigation__link {
    margin-right: 0;
}

.c-main-navigation__link--selected {
    text-decoration: underline;
}

.c-main-navigation__link--selected:focus {
    background-color: var(--color--white);
    color: var(--color--black);
}

.c-main-navigation__link--selected:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 8px solid var(--color--primary-blue);
    content: '';
}

/* Teman */
.nacka-theme-1 .c-main-navigation__link:--hocus {
    background-color: var(--color--primary-green);
}

.nacka-theme-2 .c-main-navigation__link:--hocus {
    background-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-main-navigation__link:--hocus {
    background-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-main-navigation__link:--hocus {
    background-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-main-navigation__link:--hocus {
    background-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-main-navigation__link:--hocus {
    background-color: var(--color--school-blue);
}

.nacka-theme-1 .c-main-navigation__link--selected:after,
[class^="nacka-theme-"] .nacka-theme-1 .c-main-navigation__link--selected:after {
    border-bottom-color: var(--color--primary-green)
}

.nacka-theme-2 .c-main-navigation__link--selected:after,
[class^="nacka-theme-"] .nacka-theme-2 .c-main-navigation__link--selected:after {
    border-bottom-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-main-navigation__link--selected:after,
[class^="nacka-theme-"] .nacka-theme-3 .c-main-navigation__link--selected:after {
    border-bottom-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-main-navigation__link--selected:after,
[class^="nacka-theme-"] .nacka-theme-4 .c-main-navigation__link--selected:after {
    border-bottom-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-main-navigation__link--selected:after,
[class^="nacka-theme-"] .nacka-theme-5 .c-main-navigation__link--selected:after {
    border-bottom-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-main-navigation__link--selected:after,
[class^="nacka-theme-"] .nacka-theme-6 .c-main-navigation__link--selected:after {
    border-bottom-color: var(--color--school-blue);
}

@media (--sm-md-lg) {
    .c-main-navigation__link {
        margin: 0 8px;
    }
}

.c-main-navigation.c-main-navigation-campaign .c-main-navigation__list {
    align-items: stretch;
}

.c-main-navigation.c-main-navigation-campaign .c-main-navigation__link {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 30px;
    border-radius: 0;
    line-height: 24px;
}

.c-main-navigation.c-main-navigation.c-main-navigation-campaign .c-main-navigation__link:--hocus {
    background-color: var(--color--primary-green);
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.c-main-navigation.c-main-navigation-campaign .o-list-inline__item:first-child {
    margin-left: 0;
}

.c-main-navigation.c-main-navigation-campaign .o-list-inline__item:last-child .c-main-navigation__link {
    margin-right: 0;
}

.c-main-navigation.c-main-navigation-campaign .c-main-navigation__link--selected {
    font-weight: 600;
}

.c-main-navigation.c-main-navigation-campaign .c-main-navigation__link--selected:after {
    bottom: 0;
    border-width: 6px;
    border-color: var(--color--primary-green);
}
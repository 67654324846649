.c-construction-plan-documents {
	padding-left: calc(var(--spacing--vertical-spacing-unit) * 0.5);
}

.c-construction-plan-documents__list {
	margin-top: var(--spacing--vertical-spacing-unit);
	margin-left:12px;
	border-left:3px solid #e5e5e5;
}

.c-construction-plan-documents__item {
	position:relative;
	margin-bottom: calc(var(--spacing--vertical-spacing-unit));
	margin-bottom: var(--spacing--vertical-spacing-unit);
	margin-left: -14px;
}

.c-construction-plan-documents__button {
	display:block;
	width:100%;
	border-radius:20px 0 0 20px;
	background-color:#efefef;
	text-align:left;
}

.c-construction-plan-documents__item--expanded > .c-construction-plan-documents__button {
	background-color:var(--color--primary-blue);
}

.c-construction-plan-documents__button-text {
	@mixin text-style-rho;
	margin-left: calc(var(--spacing--horizontal-spacing-unit) * 0.5);
	color: var(--color--link-color);
}

.c-construction-plan-documents__item--inactive .c-construction-plan-documents__button-text {
	margin-left: calc(var(--spacing--horizontal-spacing-unit) * 0.5 + 24);
}

.c-construction-plan-documents__item--inactive .c-construction-plan-documents__button-text:before {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:24px;
	height:24px;
	border-radius:24px;
	background:#e5e5e5;
	content:"";
}

.c-construction-plan-documents__item--collapsed > .c-construction-plan-documents__item-content {
	display:none;
}

.c-construction-plan-documents__item--expanded > .c-construction-plan-documents__button-text,
.c-construction-plan-documents__item--expanded > .c-construction-plan-documents__button > .c-construction-plan-documents__button-text {
	color:white;
	font-weight:600;
}

.c-construction-plan-documents__item-content {
	margin-top: calc(var(--spacing--horizontal-spacing-unit));
	margin-left: calc(var(--spacing--horizontal-spacing-unit) * 0.5 + 24);
}

.c-construction-plan-documents__links {
	margin-top: var(--spacing--vertical-spacing-unit);
}

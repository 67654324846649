.c-add-event__timeslot {
	margin-bottom: -1px;
	border: 1px solid var(--color--grey-2);
}

.c-add-event__button {
	margin-top: -20px;
	text-align: center;
}

.c-add-event__timeslot {
	padding: 10px 10px 0;
}
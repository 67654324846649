.c-cookie-consent {
    border-bottom: 2px solid var(--color--primary-blue);
    background: var(--color--light-blue);
}

.c-cookie-consent__inner {
    padding: 0;
}

.c-cookie-consent[class] h2.c-cookie-consent__title {
    padding-left: 0;
    text-align: left;
}

.c-cookie-consent__text {
    max-width: 630px;
}

.c-cookie-consent__link {
    @mixin text-style-body--link;
    text-decoration: none;
}

.c-cookie-consent__link:hover,
.c-cookie-consent__link:focus {
    text-decoration: underline;
}

.c-cookie-consent__link:focus {
    @mixin link-focus;
}

.c-cookie-consent__buttons button {
    margin-top: 24px;
}

@media (--md-lg-xl) {
    .c-cookie-consent__inner {
        padding: 16px 0;
    }

    .c-cookie-consent__buttons {
        display: flex;
        gap: 16px;
    }
}
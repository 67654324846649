/* ====================================================================================================================
 * HERO SLIDER
 *
==================================================================================================================== */
 .c-hero-slider {
	position: relative;
	opacity: 0;
}

.no-js .c-hero-slider,
.c-hero-slider--loaded {
	opacity: 1;
}

.c-hero-slider__list {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;	
}


/*
 * List item
---------------------------------------------------------------------------- */
.c-hero-slider__list-item {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;
	margin-right: -100%;
}

.c-hero-slider:not(.c-hero-slider--loaded) .c-hero-slider__list-item + .c-hero-slider__list-item {
	z-index: 1;
	opacity: 0;
}

.no-js .c-hero-slider__list-item[class][class] {
	margin-right: 0;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	opacity: 1;
}

.c-hero-slider__image {
	width: 100%;
}

.no-js .c-hero-slider__image {
	display: none;
}

/*
 * Background
---------------------------------------------------------------------------- */
.c-hero-slider__background {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #789D4A;
	background-position: center;
	background-size:cover;
	background-repeat: no-repeat;
}

/*
 * Caption
---------------------------------------------------------------------------- */
.c-hero-slider__caption-container {
	display: flex;
    position: relative;
    top: 0;
	justify-content: center;
	align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
	text-align: center;
}

.c-hero-slider__caption-container--no-image {
    background-color: transparent;
}

.c-hero-slider__caption {
	padding: var(--spacing--horizontal-gutter);
	color: #fff;
}

.o-container:container(width > 500px) .c-hero-slider__caption {
	padding: 18px 90px 70px 90px;
}

.c-hero-slider__caption-title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 30px !important;
    line-height: 40px !important;
    text-transform: uppercase !important;
}

.o-container:container(width > 700px) .c-hero-slider__caption-title {
    font-size: 50px !important;
    line-height: 60px !important;
}

.o-container:container(width < 700px) .c-hero-slider__caption-text {
    display: none;
}

.c-hero-slider__caption-text {
    font-size: 22px;
    line-height: 30px;
}

.c-hero-slider__caption-button {
	margin-top: 24px;
	font-size: 18px;
}


/*
 * Arrow navigation
---------------------------------------------------------------------------- */
.c-hero-slider__navigation {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.o-container:container(width > 500px) .c-hero-slider__navigation {

}

.no-js .c-hero-slider__navigation {
	opacity: 0;
}

.c-hero-slider__prev,
.c-hero-slider__next {
	position: absolute;
	bottom: 0;
	z-index: 3;
	width:100px;
	height: 100px;
	font-size: 0;
}

.o-container:container(width > 500px) .c-hero-slider__prev, 
.o-container:container(width > 500px) .c-hero-slider__next {
	height: 100%;
}

.c-hero-slider__prev {
	left: 0;
}

.c-hero-slider__next {
	right: 0;
}

.c-hero-slider__prev svg {
	padding-right: 4px;
}

.c-hero-slider__next svg {
	padding-left: 4px;
}

.c-hero-slider__prev:hover,
.c-hero-slider__next:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.c-hero-slider .c-icon-button__icon-holder {
	background-color: transparent !important;
}

/*
 * Dots navigation
---------------------------------------------------------------------------- */
.c-hero-slider__control-nav {
	position: absolute;
	bottom: 13px;
	z-index: 3;
	width: 100%;
	padding: 0 50px;
	text-align: center;
}

.o-container:container(width <= 500px) .c-hero-slider__control-nav {
	display: none;
}

.c-hero-slider__control-nav li {
	display: inline-block;
	margin: 0;
}

.c-hero-slider__control-paging li a {
	display: block;
	position: relative;
	width: 44px;
	height: 44px;
    border: none;
	text-indent: -9999px;
	cursor: pointer;
}

.c-hero-slider__control-paging li a:after {
	position: absolute;
    top: 12px;
    left: 12px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .5);
    content: '';
}

.c-hero-slider__control-paging li a:after:--hocus {
	background: var(--color--grey-3);
	transition-property: background-color;
}

.c-hero-slider__control-paging li a.c-hero-slider__active:after {
    background-color: var(--color--white);
	cursor: default;
}

.c-bordered-heading {
	display: inline-block;
	position: relative;
	width: 100%;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	text-align: center;
}

.c-bordered-heading__text {
	@mixin text-style-psi;
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0 var(--spacing--vertical-spacing-unit);
	background: #fff;
}

.c-bordered-heading:before,
.c-bordered-heading:after {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 50%;
	height: 1px;
	background: var(--color--grey-2);
	content: "";
	transform:translateY(-50%);
}

.c-bordered-heading:before {
	left:0;
}

/* =============================================================================
 * Visually hidden
 * Hidden visually, but screen readers will read it.
 * 1. Hidden only when javascript is enabled.
 * 2. Helps avoid weird focus outlines on parent elements.
============================================================================= */
@define-mixin t-visually-hidden $suffix, $container-query {
	$container-query .t-visually-hidden$(suffix),
	.js $container-query .t-visually-hidden--js /*[1]*/ {
		position: absolute;
		top: 0; /* [2] */
		left: -10000px; /* [2] */
		margin: -1px;
		width: 1px;
		height: 1px;
		overflow: hidden; /* [2] */
		clip: rect(0px, 0px, 0px, 0px);
		padding: 0;
		border: 0;
		white-space: nowrap;
		&:not(:focus):not(:active) {
			pointer-events: none;
		}
	}

	$container-query .t-visually-hidden--focusable$(suffix):active,
	$container-query .t-visually-hidden--focusable$(suffix):focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		margin: 0;
		white-space: normal;
		left: auto;
	}
}

@mixin t-visually-hidden;
@mixin t-visually-hidden \@300, .o-container:container(width > 300px);
@mixin t-visually-hidden \@500, .o-container:container(width > 500px);
@mixin t-visually-hidden \@600, .o-container:container(width > 600px);
@mixin t-visually-hidden \@700, .o-container:container(width > 700px);
@mixin t-visually-hidden \@900, .o-container:container(width > 900px);
@mixin t-visually-hidden \@1100, .o-container:container(width > 1100px);

@media (--sm) {
	@mixin t-visually-hidden \@sm;
}

@media (--sm-md) {
	@mixin t-visually-hidden \@md;
}

@media (--sm-md-lg) {
	@mixin t-visually-hidden \@lg;
}

/* =============================================================================
 * Island
============================================================================= */

.c-island {
    position: relative;
    width: 100%;
    margin-bottom: var(--spacing--vertical-spacing-unit);
    border-radius: var(--radius--default);
    background-color: #fff;
}

.c-island--no-margin {
    margin: 0;
}

.c-island--collapsable {
    border: 1px solid #e6e6e6;
    border-radius: 0;
}

.c-island__part {
    position: relative;
}


/* Set background color
---------------------------------------------------------------------------- */

@define-mixin bg-color $number,
$color {
    .c-island__part--bg-color-$(number) {
        background: $color;
    }
}

@mixin bg-color 1,
var(--color--primary-blue);
@mixin bg-color 2,
var(--color--primary-purple);
@mixin bg-color 3,
var(--color--primary-orange);
@mixin bg-color 4,
var(--color--primary-green);
@mixin bg-color 5,
var(--color--secondary-darkblue);
@mixin bg-color 6,
var(--color--secondary-purple);
@mixin bg-color 7,
var(--color--primary-orange);
@mixin bg-color 8,
var(--color--primary-green);
@mixin bg-color 9,
var(--color--primary-green);
@mixin bg-color 10,
var(--color--light-blue);
.js .c-island--collapsed .c-island__part:not(:first-child) {
    display: none;
}

.c-island__heading {
    @mixin text-style-epsilon;
}

.c-island__heading--small {
    @mixin text-style-psi;
}

.c-island__heading--inline {
    display: inline-flex;
    max-width: 300px;
    vertical-align: middle;
    white-space: normal;
}

.c-island__navigation {
    width: calc(100% - 30px);
    overflow: hidden;
    white-space: nowrap;
}

.c-island__overflow-section {
    display: inline-flex;
    visibility: hidden;
    position: relative;
    width: calc(100% - 24px);
}

.c-island--collapsed .c-island__overflow-section {
    visibility: visible;
}

.c-island__overflow-section-inner {
    overflow: hidden;
    white-space: nowrap;
}

.c-island--collapsed .c-island__navigation::after {
    position: absolute;
    top: 0;
    right: 42px;
    z-index: 1;
    width: 9px;
    height: calc(100% - 1px);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.84) 56%, rgba(255, 255, 255, 1) 67%);
    content: "";
    pointer-events: none;
}

.c-island__toggler {
    position: relative;
    z-index: 2;
    vertical-align: top;
}

.c-island__heading:last-child {
    margin-bottom: 0;
}

@media (--sm) {
    .c-island__overflow-section[class] {
        visibility: hidden;
    }
}

.c-island__part-header {
    background: var(--color--main-background);
    transition-property: background;
}

.c-island__part--collapsable .c-island__part-header {
    color: var(--color--link-color);
    cursor: pointer;
}

.c-island__part--collapsable .c-island__part-header:hover {
    background: #fff;
}

.c-island__part-content {
    display: none;
}

.c-island__part--collapsable:not(.c-island__part--collapsed) .c-island__part-content {
    display: block;
}
/* =============================================================================
 * Card
 * postcss-bem-linter: define c-card
============================================================================= */

.c-card {
    margin: 0 -var(--spacing--m) var(--spacing--l) -var(--spacing--m);
    padding: var(--spacing--l);
}

.c-card--focusable:focus-within {
    @mixin box-shadow-focus;
}

.c-card--focusable a:--hocus .c-card__heading,
.c-card--focusable:focus-within>a.c-card__heading {
    outline: none;
}

.c-card__media {
    margin-bottom: var(--spacing--l);
}

.c-card__media img {
    max-width: 160px;
    border-radius: 50%;
}


/* .c-card__link {
    @mixin text-style-link;
    text-decoration: underline;
}

.c-card__link:--hocus {
    outline: 2px solid var(--color--black);
    outline-offset: 2px;
    color: var(--color--black);
    text-decoration: none;
} */

@media (--md-lg-xl) {
    .c-card {
        margin-right: 0;
        margin-left: 0;
        padding: var(--spacing--l);
    }
}

.c-card {
    border-radius: var(--radius--default);
}

.c-card--light-blue {
    background-color: var(--color--light-blue);
}

.c-card--grey-lightest {
    background-color: var(--color--grey--lightest);
}
.c-news-card {
    display: block;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: var(--spacing--m);
    padding-bottom: var(--spacing--l);
    border-radius: 8px;
    background-color: var(--color--grey--lightest);
}

.c-news-card:focus-within {
    @mixin box-shadow-focus;
  }

.c-news-card__image {
    position: relative;
    min-height: 228px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-position: center center;
    background-size: cover;
}

.c-news-card__category {
    position: absolute;
    bottom: var(--spacing--l);
    padding: 8px var(--spacing--l);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: var(--color--primary-blue);
    color: var(--color--white);
}

.c-news-card__href {
    text-decoration: underline;
}

.c-news-card__href:focus {
    outline: none;
    color: var(--color--black);
    text-decoration: none;
}

.c-news-card__href:hover {
    color: var(--color--black);
    text-decoration: none;
}

.c-news-card__date {
    padding: var(--spacing--xs) var(--spacing--l);
    font-weight: 500;
    font-size: 15px;
    font-family:"AvenirNextLTW01-Medium";
    line-height: 24px;
}

.c-news-card__heading {
    @mixin text-style-lambda;
    margin: var(--spacing--l);
    margin-bottom: 0;
    padding: 0!important;
    color: var(--color--primary-blue);
    font-weight: 400!important;
    font-size: 24px!important;
    line-height: 32px;
    text-align: left!important;
}

.c-news-card__text {
    @mixin text-style-zeta;
    margin: 0 var(--spacing--l);
    color: var(--color--black);
    font-weight: 400;
    font-size: 16px;
}
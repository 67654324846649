.js .c-custom-checkbox ~ label:before,
.js .c-custom-checkbox ~ label:after {
	content: none;
}

.c-custom-checkbox ~ label {
	@mixin text-style-upsilon;
	color: var(--color--primary-orange);
	-moz-osx-font-smoothing: greyscale;
	-webkit-font-smoothing: antialiased;
	line-height: 20px;
}

.c-custom-checkbox ~ label:--hocus {
	text-decoration: underline;
}

.c-custom-checkbox__icon[class] {
	margin-right: calc(var(--spacing--vertical-spacing-unit) / 2);
	vertical-align: -4px;
}

.no-js .c-custom-checkbox__icon{
	display: none;
}

.c-custom-checkbox__icon path {
	fill: var(--color--primary-orange);
}
.c-header-mobile {
	position: relative;
	padding: 0;
	background-color: #fff;
}

@media print {
	.c-header-mobile {
		display: none;
	}
}

.c-header__desktop-profile span {
	margin-top: 0;
}

.c-header__desktop-profile .c-circle-image {
	position: relative;
	top: -3px;
	width: 16px;
	height: 100%;
	overflow: visible;
	border-radius: 0;
}

.c-header__desktop-profile img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.c-header-mobile__inner {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	padding: calc(var(--spacing--horizontal-gutter) / 2);
}

.nacka-theme-1 .c-header-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-1 .c-header-mobile__inner::after {
	border-top-color: var(--color--primary-green);
}

.nacka-theme-2 .c-header-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-2 .c-header-mobile__inner::after {
	border-top-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-header-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-3 .c-header-mobile__inner::after {
	border-top-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-header-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-4 .c-header-mobile__inner::after {
	border-top-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-header-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-5 .c-header-mobile__inner::after {
	border-top-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-header-mobile__inner::after,
[class^="nacka-theme-"] .nacka-theme-6 .c-header-mobile__inner::after {
	border-top-color: var(--color--school-blue);
}


.c-header-mobile__logo {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	max-width: 140px;
}

.c-header-mobile__logo img {
	display: block;
}

.c-header-mobile__menu-items {
	display: flex;
}

.c-header-mobile__menu-items > :last-child {
	margin-right: 0;
}

.c-header-mobile__profile,
.c-header-mobile__search,
.c-header-mobile__menu-button,
.c-header-mobile__logo {
	margin: 0 calc(var(--spacing--horizontal-gutter) / 6);
}

.c-header-mobile__circle-icon {
	border-color: var(--color--primary-blue);
	color: var(--color--primary-blue);
}

.nacka-theme-1 .c-header-mobile__circle-icon,
[class^="nacka-theme-"] .nacka-theme-1 .c-header-mobile__circle-icon {
	border-color: var(--color--primary-green);
	color: var(--color--primary-green);
}

.nacka-theme-2 .c-header-mobile__circle-icon,
[class^="nacka-theme-"] .nacka-theme-2 .c-header-mobile__circle-icon {
	border-color: var(--color--primary-orange);
	color: var(--color--primary-orange);
}

.nacka-theme-3 .c-header-mobile__circle-icon,
[class^="nacka-theme-"] .nacka-theme-3 .c-header-mobile__circle-icon {
	border-color: var(--color--primary-purple);
	color: var(--color--primary-purple);
}

.nacka-theme-4 .c-header-mobile__circle-icon,
[class^="nacka-theme-"] .nacka-theme-4 .c-header-mobile__circle-icon {
	border-color: var(--color--primary-blue);
	color: var(--color--primary-blue);
}

.nacka-theme-5 .c-header-mobile__circle-icon,
[class^="nacka-theme-"] .nacka-theme-5 .c-header-mobile__circle-icon {
	border-color: var(--color--ybc-green);
	color: var(--color--ybc-green);
}

.nacka-theme-6 .c-header-mobile__circle-icon,
[class^="nacka-theme-"] .nacka-theme-6 .c-header-mobile__circle-icon {
	border-color: var(--color--school-blue);
	color: var(--color--school-blue);
}

.c-header-mobile__search-form {
	padding: calc(var(--spacing--horizontal-gutter) / 2) calc(var(--spacing--horizontal-gutter) / 2) calc(var(--spacing--horizontal-gutter) / 2);
	background-color: var(--color--white);
}

.nacka-theme-1 .c-header-mobile__search-form,
[class^="nacka-theme-"] .nacka-theme-1 .c-header-mobile__search-form {
	background-color: var(--color--primary-green);
}

.nacka-theme-2 .c-header-mobile__search-form,
[class^="nacka-theme-"] .nacka-theme-2 .c-header-mobile__search-form {
	background-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-header-mobile__search-form,
[class^="nacka-theme-"] .nacka-theme-3 .c-header-mobile__search-form {
	background-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-header-mobile__search-form,
[class^="nacka-theme-"] .nacka-theme-4 .c-header-mobile__search-form {
	background-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-header-mobile__search-form,
[class^="nacka-theme-"] .nacka-theme-5 .c-header-mobile__search-form {
	background-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-header-mobile__search-form,
[class^="nacka-theme-"] .nacka-theme-6 .c-header-mobile__search-form {
	background-color: var(--color--school-blue);
}

@media (--lg-xl) {
	.c-header-mobile {
		display: none;
	}
}

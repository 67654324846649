.c-checklist[class] {
	margin-left: 0;
	list-style:none;
}

.c-checklist[class] li {
	position:relative;
	margin-bottom:20px;
	padding-left: 22px;
}

.c-checklist[class] li::before {
	position: absolute;
	top: 4px;
	left: 0;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: url(../icon/icons/_png/check.png) no-repeat center center;
	background: url(../icon/icons/check.svg) no-repeat center center;
	background-color: #98AC22;
	background-size: 10px;
	content: '';
}

.c-link-block {
    display: block;
    display: flex;
    flex-grow: 1;
    max-width: 100%;
    min-height: 104px;
    width: calc(100% - 11px);
    margin-bottom: 20px;
    border-left: 11px solid var(--color--primary-orange);
    box-shadow: 1px 6px 9px 2px rgba(0, 0, 0, 0.18);
}

.o-grid__column:nth-child(odd) .c-link-block {
    margin-left: 11px;
}

@media (--sm-md) {
    .c-link-block {
        width: calc(100% - 22px);
        margin-left: 11px;
    }
}

.c-link-block:focus {
    outline: 2px solid var(--color--graphite-3);
    outline-offset: 6px;
}

.c-link-block:hover .o-media__secondary--right {
    background-color: var(--color--primary-orange);
}

.c-link-block:hover .c-link-block__icon--sm {
    fill: var(--color--grey--lighter);
}

.c-link-block:hover .c-link-block__heading {
    text-decoration: underline;
}

.c-link-block__icon {
    vertical-align: middle;
}

.c-link-block__icon--lg {
    margin-left: 15px;
    fill: var(--color--graphite-3);
}

.c-link-block__icon--sm {
    fill: var(--color--graphite-3);
}

.c-link-block__heading {
    @mixin text-style-chi;
    margin-right: 20px;
    padding: 10px 0!important;
    color: var(--color--graphite-3);
    font-size: 25px!important;
    line-height: 34px!important;
    text-align: left!important;
}

.c-link-block__devider {
    display: inline-flex;
    order: 2;
    width: 4px;
    height: 100%;
    border-right: 2px solid var(--color--graphite-4);
}

.c-link-block .o-media__secondary--right {
    padding-right: 10px;
    padding-left: 10px;
}

@media (--sm) {
    .c-link-block {
        min-height: 80px;
    }

    .c-link-block__heading {
        font-size: 18px!important;
        line-height: 22px!important;
    }

    .c-link-block .c-icon--68x68 > svg, .c-link-block .c-icon--68x68 {
        width: 32px;
        height: 32px;
    }

    .c-link-block .o-media__secondary {
        padding-right: var(--spacing--m);
    }
}
.c-ghost-button {
	@mixin text-style-iota;
	display:inline-block;
	position: relative;
	width:auto;
	overflow:visible;
	padding:9px 20px;
	outline:none;
	border:1px solid #fff;
	border-radius: 100px;
	background-color: transparent;
	color:#fff;
	vertical-align: top;
	text-align:center;
	text-decoration:underline transparent;
	cursor:pointer;
	transition-property: color, background-color, border-color, text-decoration-color;
}

.c-ghost-button:--hocus {
	border-color: #fff;
	background-color: color(#fff a(30%));
	text-decoration: underline;
}
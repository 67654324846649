/* =============================================================================
 * Custom select
 *
 * 1. Set the height of the select element to match the replacing span element
============================================================================= */

:root {
    --c-custom-select--height: 44px;
}


/* Wrapper element to enable positioning */

.c-custom-select {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.c-custom-select--block {
    display: block;
}

.c-custom-select__original-select {
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: var(--c-custom-select--height);
    /*[1]*/
    border: 0;
    line-height: var(--c-custom-select--height);
    /*[1]*/
    opacity: 0;
    filter: alpha(opacity=0);
}


/* The span elements that are inserted underneath the real select */

.c-custom-select__fake-select {
    display: block;
    position: relative;
    z-index: 1;
}

.c-custom-select__fake-select-inner {
    @mixin text-style-body-medium;
    display: block;
    position: relative;
    height: var(--c-custom-select--height);
    /*[1]*/
    padding: 0 52px 0 16px;
    border: 1px solid var(--color--black);
    line-height: var(--c-custom-select--height);
    /*[1]*/
    transition-property: border;
}

.c-custom-select__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}

.c-custom-select__fake-select-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-custom-select__fake-select--active .c-custom-select__fake-select-inner {
    border-width: 2px;
    border-color: var(--color--primary-blue);
}

.c-custom-select__fake-select--active .c-custom-select__icon path {
    fill: var(--color--primary-blue);
}
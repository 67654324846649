/* =============================================================================
* This is where we put things we´re not completly satisfied with.
* Here is an article by Harry Roberts describing the problem more thourougly http://csswizardry.com/2013/04/shame-css/
============================================================================= */

/* Print css */
@media print {
	#epi-quickNavigator {
		display:none !important;
		visibility:hidden !important;
	}

	.c-page-intro--with-image .c-page-intro__inner-wrapper {
		min-height: auto;
	}

	/* Reset order to initial markup order in print */
	.o-grid > [data-order~="xl:1"],
	.o-grid > [data-order~="xl:2"],
	.o-grid > [data-order~="xl:3"],
	.o-grid > [data-order~="lg:1"],
	.o-grid > [data-order~="lg:2"],
	.o-grid > [data-order~="lg:3"],
	.o-grid > [data-order~="md:1"],
	.o-grid > [data-order~="md:2"],
	.o-grid > [data-order~="md:3"],
	.o-grid > [data-order~="sm:1"],
	.o-grid > [data-order="1"] {
		order:0;
    }

    /* Remove "Chatta med oss"-knappen when print*/
    #kundo-chat {
        display: none;
    }

}

/*Important to overwrite inline-styles from script that doesnt have any public methods to move list*/
.c-tag-input__dropdown {
	top: 100%!important;
	left:0!important;
}

.o-center {
    display: flex;
    justify-content: center;
}

/* Shows puzzle chat above everything else */
[data-puzzel-chat] {
	z-index: 11000;
}

/* Applies heading style on strongs with special wrapper */
.t-bold-wrapper strong {
	@mixin text-style-heading-s;
}

/* Hides Browsealoud/ReachDeck */
#__ba_panel {
	display: none!important;
}
/* Sets position on puzzel chat icon to avoid using transform & calc that lags on Safari */
div[data-puzzel-chat][data-view="bubble"] {
transition: all 0s ease 0s;
bottom: 12px!important;
right: 12px!important;
transform: none!important;
top: unset!important;
left: unset!important;
}

.special-datepicker {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 0;
}

.special-datepicker label {
	margin-bottom: 14px;
	align-self: center;
	margin-right: 4px;
	flex-flow: nowrap;
	display: flex;
	word-wrap: normal;
	white-space: nowrap;
}


@media (--sm) {
	.special-datepicker {
		padding: 0;
		flex-direction: column;
	}
	.special-datepicker label {
		margin-bottom: 0;
		align-self: flex-start;
	}
}

.c-faq__rating {
	display: flex;
	min-width: 74px;
	height: 40px;
	padding: 0 calc(var(--spacing--vertical-spacing-unit) / 2);
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	background-color: var(--color--primary-green);
}

.c-faq__container[class][class] {
	overflow: auto;
}

.nacka-theme-1 .c-faq__rating,
[class^="nacka-theme-"] .nacka-theme-1 .c-faq__rating {
	background-color: var(--color--primary-green);
}

.nacka-theme-2 .c-faq__rating,
[class^="nacka-theme-"] .nacka-theme-2 .c-faq__rating {
	background-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-faq__rating,
[class^="nacka-theme-"] .nacka-theme-3 .c-faq__rating {
	background-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-faq__rating,
[class^="nacka-theme-"] .nacka-theme-4 .c-faq__rating {
	background-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-faq__rating,
[class^="nacka-theme-"] .nacka-theme-5 .c-faq__rating {
	background-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-faq__rating,
[class^="nacka-theme-"] .nacka-theme-6 .c-faq__rating {
	background-color: var(--color--school-blue);
}

.c-faq__heading {
	@mixin text-style-chi;
	font-family: var(--type--family-default);
}

.c-faq__rating::after {
	display: inline-block;
	content: "";
}

.c-faq__rating-icon-holder {
	display: inline-block;
	width: 24px;
	height: 24px;
	border-radius: 100%;
	background-color: #fff;
	color: var(--color--primary-green);
	text-align: center;
}

.nacka-theme-1 .c-faq__rating-icon-holder,
[class^="nacka-theme-"] .nacka-theme-1 .c-faq__rating-icon-holder {
	color: var(--color--primary-green);
}

.nacka-theme-2 .c-faq__rating-icon-holder,
[class^="nacka-theme-"] .nacka-theme-2 .c-faq__rating-icon-holder {
	color: var(--color--primary-orange);
}

.nacka-theme-3 .c-faq__rating-icon-holder,
[class^="nacka-theme-"] .nacka-theme-3 .c-faq__rating-icon-holder {
	color: var(--color--primary-purple);
}

.nacka-theme-4 .c-faq__rating-icon-holder,
[class^="nacka-theme-"] .nacka-theme-4 .c-faq__rating-icon-holder {
	color: var(--color--primary-blue);
}

.nacka-theme-5 .c-faq__rating-icon-holder,
[class^="nacka-theme-"] .nacka-theme-5 .c-faq__rating-icon-holder {
	color: var(--color--ybc-green);
}

.nacka-theme-6 .c-faq__rating-icon-holder,
[class^="nacka-theme-"] .nacka-theme-6 .c-faq__rating-icon-holder {
	color: var(--color--school-blue);
}

.c-faq__rating-number {
	@mixin text-style-phi;
	padding-right: calc(var(--spacing--vertical-spacing-unit) / 2);
	color: #fff;
}

.c-faq__feedback-container {
	display: inline-block;
	width: 100%;
	text-align: center;
}

@media (--sm) {
	.c-faq__rating {
		display: inline-block;
		margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
		padding-top: 7px;
	}

	.c-faq:not(.c-faq--no-rating) .c-faq__heading {
		padding-left: calc(var(--spacing--vertical-spacing-unit) / 2);
	}
}

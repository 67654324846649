/* =============================================================================
 * Width limiter
 *
 * Limits width and centers container on page.
============================================================================= */

.o-width-limiter {
    max-width: var(--width-limiter--default);
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
}

.o-width-limiter--narrow {
    max-width: var(--width-limiter--narrow);
    margin-right: auto;
    margin-left: auto;
}

@media (--sm) {
    .o-width-limiter {
        padding: 0;
    }
}
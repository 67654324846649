.c-userbar {
	position: relative;
	flex-grow: 1;
	width: 100%;
	overflow: auto;
	padding-top: var(--spacing--vertical-spacing-unit);
	box-shadow: -8px 0px 0px 0px rgba(0,0,0,0.07);
	background: #fff;
}

@media print {
	.c-userbar {
		display: none;
	}
}

.c-userbar__header {
	@mixin text-style-beta;
	padding: 5px var(--spacing--horizontal-spacing-unit);
	background: var(--color--primary-blue);
	color: #fff;
	text-align: center;
}

.c-userbar__section-header {
	@mixin text-style-psi;
	padding: 2px var(--spacing--horizontal-spacing-unit) 2px 24px;
	background: var(--color--primary-blue);
	color: #fff;
}

.c-userbar__section-header-link {
    vertical-align: middle;
    text-decoration: underline;
}

.c-userbar__instruction-header {
	@mixin text-style-epsilon;
	margin: var(--spacing--vertical-spacing-unit) 0;
}

h3.c-userbar__section-heading {
    font-size: 20px;
    line-height: 24px;
}

.c-userbar__tips-item {
	margin-bottom: var(--spacing--vertical-spacing-unit);
	text-align: left;
}

.c-userbar__login-action {
	margin-bottom: var(--spacing--vertical-spacing-unit);
	padding: 0 17%;
}

.c-userbar__instruction {
	@mixin text-style-rho;
	padding: 0 17%;
	color: var(--color--grey-4);
}

.c-userbar__login-box {
	background: #fff;
}

.c-userbar__login-box--fixed {
	position: fixed;
	right:0;
	bottom: 0;
	z-index: 10;
	box-shadow: 0 -17px 20px -20px rgba(0, 0, 0, 0.2);
}

.c-userbar__logout {
	display: block;
	margin-top: calc(0 - var(--spacing--vertical-spacing-unit));
	padding: 2px var(--spacing--horizontal-spacing-unit);
	background: var(--color--grey-1);
	text-align: right;
}

.c-userbar__street-input {
	text-align: left;
}

/* Spinner in userbar-tabs specifics
----------------------------------------------------------------------------- */
.c-userbar .loading-panel {
	position: relative;
}

.c-userbar .loading-panel .c-spinner {
	top: 80px;
	left: 50%;
	transform: translateY(0);
}

.c-userbar .loading-panel:before {
	position: absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	content:'';
	opacity: 0.8;
}
@media (--lg-xl) {
	#userbar-mobile {
		display:none;
	}
}

.c-icon-link {
	@mixin text-style-reset;
	display: inline-block;
	display: inline-flex;
	align-items: center;
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	color: var(--color--link-color);
	vertical-align: middle;
}

.c-icon-link--no-bottom {
	margin-bottom: 0;
}

.c-icon-link:--hocus {
	outline:none;
	text-decoration:underline;
}

.c-icon-link__icon {
	margin-top:-4px;
	margin-right:8px;
	vertical-align: middle;
	vertical-align: -4px;
}

.c-icon-link__icon path {
	fill:var(--color--link-color);
}

.c-icon-link--smallcaps {
	@mixin text-style-psi;
	color: var(--color--link-color);
	text-transform: uppercase;
}

.c-icon-link--smallcaps > .c-icon-link__icon {
	margin-right: 4px;
}

.c-icon-link--inline {
	display: inline-block;
	margin-bottom: 0;
}

.c-icon-link--right .c-icon-link__icon {
	margin-right:0;
	margin-left:8px;
}

.c-icon-link--right.c-icon-link--smallcaps .c-icon-link__icon {
	margin-right:0;
	margin-left:4px;
}

.c-icon-link.disabled {
    color: var(--color--black);
    cursor: default;
    pointer-events: none;
}
.c-quote__paragraph {
	@mixin text-style-delta;
	position: relative;
	padding: 0 18px;
}

.c-quote__container {
	position: relative;
	max-width: 780px;
	width: 100%;
	margin-top: 30px;
	background-color: #fff;
}

.c-quote__arrow {
	display: inline-block;
	position: absolute;
	top: -7px;
	left: 28px;
	width: 20px;
	height: 20px;
	margin-left: -15px;
	box-shadow: -2px -2px 0 -1px rgba(0,0,0,.1);
	background: white;
	content: '';
	transform: rotate(45deg);
}

.c-quote__icon {
	position: absolute;
	top: 18px;
	left: 18px;
}

.c-quote__icon {
	color: var(--color--grey-5);
}

.c-quote.o-container:container(width > 500px) .c-quote__container,
.c-quote.o-container:container(width > 500px) .c-quote__source {
	display: inline-block;
	vertical-align: top;
}

.c-quote.o-container:container(width > 500px) .c-quote__source {
	width: 220px;
}

.c-quote.o-container:container(width > 500px) .c-quote__container {
	width: calc(100% - 226px);
	margin-top: 0;
}

.c-quote.o-container:container(width > 500px) .c-quote__paragraph {
	@mixin text-style-beta;
}

.c-quote.o-container:container(width > 500px) .c-quote__arrow {
	top: 16px;
	left: 8px;
	background: white;
	transform: rotate(-45deg);
}

/* =============================================================================
 * Mixins
============================================================================= */


/*
 * Border styles
---------------------------------------------------------------------------- */

@define-mixin border-grey-light {
    border: 1px solid var(--color--grey-2);
}


/*
 * States and effects styles
---------------------------------------------------------------------------- */

@define-mixin box-shadow-focus {
    box-shadow: 2px 2px 0 var(--color--black), -2px 2px 0 var(--color--black), 2px -2px 0 var(--color--black), -2px -2px 0 var(--color--black);
}

@define-mixin link-focus {
    outline: 2px solid var(--color--black);
    outline-offset: 8px;
}


/*
 * Text styles
---------------------------------------------------------------------------- */

@define-mixin text-style-reset {
    background: transparent;
    color: inherit;
    font: var(--type--primary);
    line-height: var(--type--line-height-default);
    text-transform: none;
    text-shadow: none;
    word-spacing: normal;
    letter-spacing: normal;
}

@define-mixin text-style-alpha {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-size: 40px;
    line-height: 46px;
}

@define-mixin text-style-beta {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.001em;
}

@define-mixin text-style-gamma {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.001em;
}

@define-mixin text-style-delta {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.001em;
}

@define-mixin text-style-epsilon {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-weight: 500;
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;
    line-height: 28px;
    text-transform: uppercase;
    letter-spacing: 0.002em;
}

@define-mixin text-style-zeta {
    @mixin text-style-reset;
    font-weight: 500;
    font-size: 18px;
}

@define-mixin text-style-eta {
    @mixin text-style-beta;
    font-weight: 600;
}

@define-mixin text-style-theta {
    @mixin text-style-reset;
    color: var(--color--link-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;
}

@define-mixin text-style-iota {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

@define-mixin text-style-kappa {
    @mixin text-style-reset;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

@define-mixin text-style-lambda {
    @mixin text-style-iota;
    text-transform: none;
}

@define-mixin text-style-mu {
    @mixin text-style-iota;
    font-weight: 600;
}

@define-mixin text-style-nu {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-weight: 500;
    font-size: 12px;
    line-height: var(--spacing--vertical-spacing-unit);
    text-transform: uppercase;
}

@define-mixin text-style-xi {
    @mixin text-style-reset;
    font-size: 54px;
    line-height: calc(var(--type--line-height-default) * 2);
}

@define-mixin text-style-omicron {
    @mixin text-style-reset;
    color: var(--color--link-color);
    font: var(--type--defaults);
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}

@define-mixin text-style-pi {
    @mixin text-style-reset;
    color: var(--color--link-color);
    font: var(--type--defaults);
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
}

@define-mixin text-style-rho {
    @mixin text-style-reset;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

@define-mixin text-style-sigma {
    @mixin text-style-reset;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

@define-mixin text-style-tau {
    @mixin text-style-reset;
    font: var(--type--primary);
    font-weight: 300;
}

@define-mixin text-style-upsilon {
    @mixin text-style-iota;
    color: var(--color--link-color);
    line-height: 22px;
}

@define-mixin text-style-phi {
    @mixin text-style-reset;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
}

@define-mixin text-style-chi {
    @mixin text-style-reset;
    font-size: 22px;
    line-height: 30px;
}

@define-mixin text-style-psi {
    @mixin text-style-reset;
    font: var(--type--defaults);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.001em;
}

@define-mixin text-style-omega {
    @mixin text-style-reset;
    font-weight: 500;
    font-size: 14px;
}

@define-mixin text-style-digamma {
    @mixin text-style-reset;
    font-size: 20px;
    font-family: var(--type--family-primary-bold);
    line-height: 28px;
}


/*
 * Text styles 2.0
---------------------------------------------------------------------------- */

@define-mixin text-style-heading-l {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 300;
    font-size: 40px;
    font-family: "Gill Sans Web";
    line-height: 48px;
}

@define-mixin text-style-heading-m {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 300;
    font-size: 32px;
    font-family: "Gill Sans Web";
    line-height: 40px;
}

@define-mixin text-style-heading-s {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 300;
    font-size: 24px;
    font-family: "Gill Sans Web";
    line-height: 32px;
}

@define-mixin text-style-heading-s--link {
    @mixin text-style-reset;
    color: var(--color--primary-blue);
    font-weight: 400;
    font-size: 24px;
    font-family: "Gill Sans Web";
    line-height: 32px;
    text-decoration: underline;
}

@define-mixin text-style-preamble {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 500;
    font-weight: bold;
    font-size: 20px;
    font-family: "AvenirNextLTW01-Medium";
    line-height: 26px;
}

@define-mixin text-style-link {
    @mixin text-style-reset;
    color: var(--color--primary-blue);
    font-weight: 400;
    font-size: 18px;
    font-family: "Gill Sans Web";
    line-height: 24px;
}

@define-mixin text-style-body {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 400;
    font-size: 16px;
    font-family: "AvenirNextLTW01-Regular";
    line-height: 22px;
}

@define-mixin text-style-body-medium {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 500;
    font-size: 16px;
    font-family: "AvenirNextLTW01-Regular";
    line-height: 22px;
}

@define-mixin text-style-body-heavy {
    @mixin text-style-reset;
    color: var(--color--black);
    font-weight: 800;
    font-size: 16px;
    font-family: "AvenirNextLTW01-Regular";
    line-height: 22px;
}

@define-mixin text-style-body--link {
    @mixin text-style-reset;
    color: var(--color--primary-blue);
    font-weight: 400;
    font-size: 18px;
    font-family: "Gill Sans Web";
    line-height: 24px;
    text-decoration: underline;
}

@define-mixin text-style-date {
    @mixin text-style-reset;
    color: var(--color--graphite-3);
    font-weight: 500;
    font-size: 16px;
    font-family: "AvenirNextLTW01-Medium";
    line-height: 22px;
}

@define-mixin text-style-label {
    @mixin text-style-reset;
    color: var(--color--graphite-3);
    font-weight: 800;
    font-weight: bold;
    font-size: 18px;
    font-family: "AvenirNextLTW01-Medium";
    line-height: 24px;
}

@define-mixin text-style-heta {
    @mixin text-style-reset;
    font-weight: 400;
    font-size: 22px;
    font-family: var(--type--family-default);
    line-height: 32px;
}

@define-mixin text-style-san {
    @mixin text-style-reset;
    font-weight: 400;
    font-size: 24px;
    font-family: var(--type--family-default);
    line-height: 32px;
}

@define-mixin text-style-stigma {
    @mixin text-style-reset;
    font-size: 18px;
}

@define-mixin text-style-koppa {
    @mixin text-style-san;
    font-weight: 300;
}
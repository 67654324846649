.c-ghost-badge {
	@mixin text-style-iota;
	display: inline-block;
	min-width: 25px;
	height: 25px;
	padding: 0 10px;
	border: 1px solid var(--color--grey-3);
	border-radius: 50px;
	color: var(--color--grey-4);
	line-height: 22px;
	text-align: center;
}

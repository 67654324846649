.c-mentions {
	position: relative;
	margin-bottom: 9px;
	background: #fff;
}

.c-mentions__textarea {
	display: block;
	position: relative;
	min-height: 38px;
	width: 100%;
	padding: 9px;
	background: transparent;
	line-height: 24px;
}

.c-mentions__autocomplete-list {
	display: none;
	position: absolute;
	right: 0;
	left: 0;
	z-index: 10000;
	margin-top: -2px;
	border: 1px solid var(--color--grey-2);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1484);
	background: #fff;
}

.c-mentions__autocomplete-list > ul {
	position: relative;
	z-index: 1000;
	margin: 0;
	padding: 0;
}

.c-mentions__autocomplete-item {
	min-height: 38px;
	width: auto;
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0 12px;
	border-bottom: 1px solid #eee;
	list-style: none;
	background-color: #fff;
	font-size: 18px;
	line-height: 38px;
	white-space: nowrap;
	cursor: pointer;
}

.c-mentions__avatar {
	float: left;
	width: 28px;
	height: 28px;
	margin-top: 5px;
	margin-right: 5px;
	border-radius: 50%;
}

.c-mentions__autocomplete-item em {
	font-weight: bold;
	font-style: normal;
}

.c-mentions__autocomplete-item:hover,
.c-mentions__autocomplete-item--active {
	background-color: var(--color--grey-1);
}

.c-mentions__autocomplete-item b {
	background-color: var(--color--link-color);
	color: #fff;
	font-weight: normal;
}

.c-mentions__input-overlay {
	position: absolute;
	top: 1px;
	right: 0;
	bottom: 0;
	left: 1px;
	overflow: hidden;
	padding: 9px;
	color: transparent;
	white-space: pre-wrap;
	word-wrap: break-word;
	pointer-events: none;
}

.c-mentions__input-overlay-inner {
	width: 100%;
	white-space: pre-wrap;
}

/* 1. The text-shadow will hide the color of the text behind the overlay, but
      still allow the caret to be visible. */
.c-mentions__input-overlay-inner > strong {
	position: relative;
	z-index: 1;
	color: var(--color--link-color);
	line-height: 24px;
	text-shadow: 0 1px #fff, 0 -1px #fff, 1px 0 #fff, -1px 0 #fff; /* [1] */
}

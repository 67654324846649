/* =============================================================================
 * Accordion
============================================================================= */

.c-accordion {
    margin-top: 8px;
}

.c-accordion:first-child {
    margin-top: 0;
}

.c-accordion__message-list {
    background-color: var(--color--secondary-red);
}

.c-accordion__message-list .c-accordion__wrapper,
.c-accordion__message-list .c-accordion__button {
    color: var(--color--white);
}

.c-accordion .c-message {
    margin-bottom: 0;
    border: none;
}

.c-accordion .c-message .c-message--padding {
    padding-right: 0;
    padding-left: 14px;
}

.c-accordion__trigger {
    margin: 0;
    padding: 20px;
    border: none;
    background-color: transparent;
    font: inherit;
}

.c-accordion__wrapper {
    margin-top: 0;
    color: var(--color--black);
}

.c-accordion__content {
    padding: 20px 30px;
}

.c-accordion__block {
    padding: 5px 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.45);
}

.c-accordion__sub-text {
    display: inline-block;
    padding-top: 4px;
    text-transform: none;
}

.c-accordion__sub-text-additional {
    display: inline-block;
    padding-left: 10px;
}

.c-accordion--is-collapsed .c-accordion__wrapper {
    display: none;
}

.c-accordion--is-expanded .c-accordion__wrapper {
    display: block;
}

.c-accordion__button {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    background-color: transparent;
    color: var(--color--black);
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.57px;
    cursor: pointer;
}

.c-accordion__icon-holder,
.c-accordion__icon-holder--large {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--color--white);
    color: var(--color---black-lighter);
}

.c-accordion__icon-holder::before {
    display: none;
}

.c-accordion__help-text .c-accordion__icon-holder--large::before {
    position: absolute;
    right: 32px;
    color: var(--color--primary-blue);
    font-size: 18px;
    font-family:"AvenirNextLTW01-Medium";
    line-height: 24px;
    content: "Öppna";
    text-decoration: underline;
    text-transform: none;
}

.c-accordion__help-text .c-accordion__trigger {
    padding: 0;
}

.c-accordion__help-text .c-icon--24x24>svg {
    fill: var(--color--primary-blue);
}

.c-accordion__help-text.c-accordion--is-expanded .c-accordion__icon-holder--large::before {
    content: "Stäng";
}

.c-accordion__help-text .c-accordion__button {
    font-size: 32px;
    line-height: 40px;
    text-transform: none;
}

.c-accordion__help-text .c-accordion__trigger {
    padding: 12px 12px 16px 12px;
}

.c-accordion__help-text .c-accordion__content,
.c-accordion__help-text .c-accordion__block {
    padding: 12px 12px 24px 12px;
    border-top: 1px solid var(--color--grey-2);
}

.c-accordion__icon,
.c-accordion__icon--large {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 11px;
    height: 11px;
    transform: translate(-50%, -50%);
}

.c-accordion__icon svg,
.c-accordion__icon--large svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (--lg-xl) {
    .c-accordion__message-list .c-accordion__trigger {
        padding: 20px 150px;
    }

    .c-accordion__message-list .c-accordion__block {
        padding: 5px 150px;
    }

    .c-accordion__sub-text {
        padding-left: 0;
    }

    .c-accordion__help-text .c-accordion__trigger {
        padding: 16px 24px;
    }

    .c-accordion__help-text .c-accordion__content,
    .c-accordion__help-text .c-accordion__block {
        padding: 24px;
        border-top: 1px solid var(--color--grey-2);
    }
}
/* =============================================================================
 * Call to action
 *
============================================================================= */
.c-call-to-action {
	display: flex;
	justify-content: center;
}
.c-call-to-action a:not(:last-child) {
	margin-right: 20px;
}

@media (--sm) {
	.c-call-to-action {
		flex-direction: column;
	}

	.c-call-to-action a,
	.c-call-to-action div,
	.c-call-to-action button {
		margin-right: 0;
		margin-bottom: 20px;
	}

	.c-call-to-action a:not(:last-child) {
		margin-right: 0px;
	}
}

/* =============================================================================
 * USERBAR WRAPPER
 * Styling for the different placeholders for the userbar.
 * 1. Negate margin on width limiter
============================================================================= */
.c-userbar-wrapper--on-page {
	display: flex;
	flex-grow: 1;
	margin-right: -20px; /* [1] */
}

@media (--xl) {
	.c-userbar-wrapper--sticky {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 340px;
		background-color: #fff;
	}
}

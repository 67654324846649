/* =============================================================================
 * Page intro
============================================================================= */

.c-page-intro {
    position: relative;
    overflow: hidden;
}

.c-page-intro--with-image {
    display: flex;
}

.c-page-intro__inner-wrapper {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(var(--spacing--horizontal-gutter) * 2) var(--spacing--horizontal-gutter);
    border-bottom: 1px solid var(--color--grey-2);
}

.c-page-intro__inner-wrapper--borderless {
    border: none;
}

.c-page-intro--with-image .c-page-intro__inner-wrapper {
    min-height: 350px;
    width: 100%;
    padding: 0;
}

.c-page-intro--with-image .c-page-intro__overlay {
    padding: var(--spacing--horizontal-gutter);
    background: rgba(255, 255, 255, 0.8);
}

.c-page-intro__heading {
    @mixin text-style-alpha;
    margin-bottom: var(--spacing--s);
    color: inherit;
}

.c-page-intro__heading+.c-timestamp {
    margin-top: 0;
}

.c-page-intro .c-page-intro__c-timestamp {
    display: inline-block;
    margin-bottom: 8px;
    background: transparent;
    color: inherit;
    font: 400 16px/24px 'AvenirNextLTW01-Regular', 'Avenir Next', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    text-shadow: none;
    word-spacing: normal;
    letter-spacing: normal
}

.c-page-intro__text-content {
    @mixin text-style-zeta;
    margin-top: var(--type--line-height-default);
    margin-top: 0;
    font-weight: 400;
    font-size: 18px;
    font-family: var(--type--family-primary-bold)!important;
    line-height: 24px;
    text-align: left;
}

.c-page-intro:container(width > 900px) .c-page-intro__text-content[class] {
    max-width: 28em;
}

.c-page-intro__image {
    width: 100%;
    height: 360px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.c-page-intro__button {
    margin-top: var(--type--line-height-default);
}

.c-page-intro:container(width > 900px) .c-page-intro__button {
    margin-top: 0;
}

.c-page-intro .o-space-between__item:first-of-type {
    align-self: flex-start;
}

@media (--sm-md) {
    .c-page-intro--with-image .c-page-intro__overlay {
        padding-right: var(--spacing--horizontal-gutter);
        padding-left: var(--spacing--horizontal-gutter);
    }
}

@media (--sm) {
    .c-page-intro__inner-wrapper {
        text-align: center;
    }

    .c-page-intro__heading {
        @mixin text-style-beta;
        word-wrap: break-word;
        hyphens: auto;
        overflow-wrap: break-word;
    }
}
/* =============================================================================
 * Skip link
============================================================================= */
.c-skip {
	position:absolute;
	top:0;
	left:-9999px;
	z-index:100;
	width:100%;
	margin:0;
}
.c-skip__link:focus,
.c-skip__link:active {
	display:block;
	position:absolute;
	top:0;
	left:9999px;
	width:100%;
	padding:20px 0;
	outline:none;
	background:#000;
	color:#fff;
	text-align:center;
}
.c-event-teaser {
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-event-teaser__date-wrapper {
	display: block;
	position: relative;
	padding-top: 50%;
}

/* 1. translateZ needed to avoid movement in the transition (in Chrome) */
.c-event-teaser__image {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	transition-property: opacity;
	transform: translateX(-50%) translateY(-50%) translateZ(0); /* [1] */
}

.c-event-teaser__date-wrapper:--hocus .c-event-teaser__image {
	opacity: 0.8;
}

.c-event-teaser__date {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	text-align: center;
	white-space: nowrap;
	transform: translateY(-50%);
}

.c-event-teaser__heading {
	@mixin text-style-delta;
	margin-bottom: calc(0.5 * var(--spacing--vertical-spacing-unit));
}

.c-event-teaser__heading-link {
	color: var(--color--link-color);
}

.c-event-teaser__heading-link:--hocus {
	text-decoration: underline;
}

.c-event-teaser__meta-data {
	@mixin text-style-psi;
}

.c-event-teaser__meta-data-icon path {
	fill: var(--color--primary-blue);
}

.nacka-theme-1 .c-event-teaser__meta-data-icon path,
[class^="nacka-theme-"] .nacka-theme-1 .c-event-teaser__meta-data-icon path {
	fill: var(--color--primary-green);
}

.nacka-theme-2 .c-event-teaser__meta-data-icon path,
[class^="nacka-theme-"] .nacka-theme-2 .c-event-teaser__meta-data-icon path {
	fill: var(--color--primary-orange);
}

.nacka-theme-3 .c-event-teaser__meta-data-icon path,
[class^="nacka-theme-"] .nacka-theme-3 .c-event-teaser__meta-data-icon path {
	fill: var(--color--primary-purple);
}

.nacka-theme-4 .c-event-teaser__meta-data-icon path,
[class^="nacka-theme-"] .nacka-theme-4 .c-event-teaser__meta-data-icon path {
	fill: var(--color--primary-blue);
}

.nacka-theme-5 .c-event-teaser__meta-data-icon path,
[class^="nacka-theme-"] .nacka-theme-5 .c-event-teaser__meta-data-icon path {
	fill: var(--color--ybc-green);
}

.nacka-theme-6 .c-event-teaser__meta-data-icon path,
[class^="nacka-theme-"] .nacka-theme-6 .c-event-teaser__meta-data-icon path {
	fill: var(--color--school-blue);
}

.c-event-teaser__tag {
	@mixin text-style-psi;
	color: var(--color--link-color);
}

.c-event-teaser__tag:--hocus {
	text-decoration: underline;
}


/* Message variant
 * Used for examples when there are no events on the selected date
---------------------------------------------------------------------------- */
.c-event-teaser--message {
	background: var(--color--grey-1);
}

.c-event-teaser__message-heading {
	@mixin text-style-epsilon;
	text-align: center;
}

.c-event-teaser__message-text {
	max-width: 185px;
	margin: 0 auto;
}

.c-event-teaser__message-icon path {
	fill: var(--color--primary-orange);
}

.nacka-theme-1 .c-event-teaser__message-icon path,
[class^="nacka-theme-"] .nacka-theme-1 .c-event-teaser__message-icon path {
	fill: var(--color--primary-green);
}

.nacka-theme-2 .c-event-teaser__message-icon path,
[class^="nacka-theme-"] .nacka-theme-2 .c-event-teaser__message-icon path {
	fill: var(--color--primary-orange);
}

.nacka-theme-3 .c-event-teaser__message-icon path,
[class^="nacka-theme-"] .nacka-theme-3 .c-event-teaser__message-icon path {
	fill: var(--color--primary-purple);
}

.nacka-theme-4 .c-event-teaser__message-icon path,
[class^="nacka-theme-"] .nacka-theme-4 .c-event-teaser__message-icon path {
	fill: var(--color--primary-blue);
}

.nacka-theme-5 .c-event-teaser__message-icon path,
[class^="nacka-theme-"] .nacka-theme-5 .c-event-teaser__message-icon path {
	fill: var(--color--ybc-green);
}

.nacka-theme-6 .c-event-teaser__message-icon path,
[class^="nacka-theme-"] .nacka-theme-6 .c-event-teaser__message-icon path {
	fill: var(--color--school-blue);
}

/* Horizontal variant
 * Used for event listing
---------------------------------------------------------------------------- */

.c-event-teaser--horizontal {
	display: flex;
	flex: 1 1 auto;
}

.c-event-teaser--horizontal .c-event-teaser__date-wrapper,
.c-event-teaser--horizontal .c-event-teaser__content-wrapper {
	display: inline-block;
}

.c-event-teaser--horizontal .c-event-teaser__date {
	position: relative;
	top: 0;
	transform: none;
}

.c-event-teaser--horizontal .c-event-teaser__date-wrapper {
	flex-shrink: 0;
	padding: 18px;
}

@media (--sm) {
	.c-event-teaser--horizontal {
		display: inline-block;
	}

	.c-event-teaser--horizontal .c-event-teaser__date-wrapper {
		padding: 18px 18px 0 18px;
	}
}

/* Small variant
 * Used in user bar
---------------------------------------------------------------------------- */

.c-event-teaser--small {
	width: 100%;
	margin: 0;
}

.c-event-teaser--small .c-event-teaser__heading {
	@mixin text-style-rho;
	margin: 4px 0 0;
	line-height: 18px;
}

.c-event-teaser--small .c-event-teaser__date-wrapper {
	padding: calc(0.5 * var(--spacing--vertical-spacing-unit));
}

/* =============================================================================
 * Carousel
 * postcss-bem-linter: define c-carousel
============================================================================= */
.c-carousel {
	position: relative;
	width: 100%;
	overflow: hidden;
    margin-bottom: var(--spacing--m);
    transition: height 150ms;
}
.c-carousel__items {
	margin: 0;
	padding: 0;
	list-style: none;
}
.c-carousel.is-inited .c-carousel__items {
	display: flex;
}
.c-carousel__wrapper {
	height: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	scroll-snap-type: mandatory;
	scroll-snap-destination: 0 100%;
	scroll-snap-points-x: repeat(100%);
}
.c-carousel__prev-button,
.c-carousel__next-button {
	background-color: var(--color--primary-blue);
	color: var(--color--white);
	text-align: center;
}
.c-carousel__prev-button:hover,
.c-carousel__next-button:hover {
	background-color: color(var(--color--primary-blue) tint(30%));
}
.c-carousel__prev-button:focus,
.c-carousel__next-button:focus {
	outline: 3px solid white;
}
.c-carousel__prev-button {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;
	width: 60px;
	height: 70px;
}
.c-carousel__next-button {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 10;
	width: 60px;
	height: 70px;
}
.c-carousel__slide {
	display: flex;
	scroll-snap-align: start;
}
.c-carousel__slide > .o-grid {
	flex: 1;
}
.c-carousel__item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 500px;
	width: 100%;
	padding: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-carousel__content {
	position: relative;
	max-width: 740px;
	margin-top: 70px;
	padding: 40px 20px;
	background-color: rgba(0, 102, 164, .85);
	color: white;
}

.c-carousel__title {
	@mixin text-style-alpha;
	margin-bottom: 20px;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	text-transform: uppercase;
}

.c-carousel__text,
.c-carousel__link {
	@mixin text-style-delta;
	font: var(--type--primary);
	font-size: 16px;
	line-height: 22px;
}

.c-carousel__link {
	text-decoration: underline;
}

.c-carousel__link::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
}

.c-carousel__nav {
	margin-top: 0;
}

.c-carousel__nav-list {
	margin: 0;
	margin-top: -40px;
	padding: 0;
	list-style: none;
	text-align: center;
}

.c-carousel__nav-item {
	display: inline-block;
	margin: 0 3px;
}

.c-carousel__nav-button {
	position: relative;
	width: 16px;
	height: 16px;
	overflow: hidden;
	padding: 0;
	border: 1px solid var(--color--white);
	border-radius: 50%;
	background: none;
	text-indent: -9999px;
	cursor: pointer;
}
.c-carousel__nav-button:focus {
	outline: 2px solid var(--color--white);
}
.c-carousel__nav-button:hover::after {
	opacity: 0.5;
}
.c-carousel__nav-button[aria-current]::after{
	opacity: 1;
}
.c-carousel__nav-button::after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: var(--color--white);
	content: "";
	opacity: 0;
	transition: opacity 150ms ease;
	transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
	.c-carousel__wrapper {
		overflow-x: hidden;
		overflow-y: hidden;
	}

	.c-carousel__content {
		margin-right: 50px;
		margin-left: 50px;
		padding: 40px 50px;
	}

	.c-carousel__item-content {
		width: calc(100% - 300px);
	}

	.c-carousel__item {
		min-height: 590px;
		padding: 0 50px;
	}

	.c-carousel__title {
		@mixin text-style-alpha;
		margin-bottom: 20px;
		font-weight: 500;
		text-transform: uppercase;
	}

	.c-carousel__text,
	.c-carousel__link {
		font-size: 24px;
		line-height: 35px;
	}

	.c-carousel__prev-button {
		position: absolute;
		top: 70px;
		left: 0;
		z-index: 10;
		width: 60px;
		height: 76%;
	}

	.c-carousel__next-button {
		position: absolute;
		top: 70px;
		right: 0;
		z-index: 10;
		width: 60px;
		height: 76%;
	}
}

.c-carousel__background {
	display: none;
}

.no-js .c-carousel__background {
	display: block;
    position: absolute;
    left: 0;
	min-height: 590px;
    width: 100%;
}
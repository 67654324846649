/* Document listing
-------------------------------------------------------------- */
.c-document {
	@mixin text-style-zeta;
	color: var(--color--link-color);
}

.c-document:--hocus .c-document__text {
	text-decoration: underline;
	cursor: pointer;
}

.c-document__name {
	display: block;
	position: relative;
}

.c-document__type,
.c-document__meta {
	@mixin text-style-psi;
	color: var(--color--grey-4);
}

.c-document__meta {
	padding-right: var(--spacing--horizontal-gutter);
}

.c-employee__name {
	@mixin text-style-zeta;
}

.c-employee__link {
	color: var(--color--link-color);
}

.c-employee__title {
	@mixin text-style-nu;
}

.c-employee__unit {
	@mixin text-style-nu;
}

.c-employee__tel {
	color: #777;
}

.c-employee__link:--hocus {
	text-decoration: underline;
}

.c-icon-input {
	position: relative;
}

.c-icon-input__icon {
	position: absolute;
	top: 4px;
	left: 12px;
}

.c-icon-input label {
	position: relative;
	z-index: 2;
}

.c-icon-input__input,
.c-icon-input__input:not([type]),
.c-icon-input__input[type="text"],
.c-icon-input__input[type="url"],
.c-icon-input__input[type="email"],
.c-icon-input__input[type="tel"],
.c-icon-input__input[type="date"],
.c-icon-input__input[type="number"],
.c-icon-input__input[type="password"],
.c-icon-input__input[type="search"] {
	padding-left:32px;
}
.c-get-more {
    margin: calc(2 * var(--spacing--vertical-spacing-unit)) 0;
    border-top: 1px solid var(--color--grey-2);
    text-align: center;
}

@media print {
    .c-get-more {
        display: none;
    }
}

.c-get-more--less-bottom {
    margin-bottom: 12px;
}

.c-get-more--no-border {
    border-color: transparent;
}

.c-get-more__info {
    display: block;
    margin-bottom: var(--spacing--m);
}

.c-get-more__button {
    margin-top: -20px;
}

.c-get-more__button--disabled {
    opacity: 0.2;
}

.c-get-more__animate-in {
    animation: get-more-animate 0.3s ease-in-out;
}

@keyframes get-more-animate {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.c-get-more--with-count {
    margin: 0;
    margin-bottom: 18px;
    padding: var(--spacing--m) var(--spacing--s);
    border: none;
    border-radius: var(--radius--default);
    background-color: #fff;
}

.c-get-more--with-count .c-get-more__button {
    margin: 0;
}

@media (--lg-xl) {
    .c-get-more__info {
        margin-bottom: var(--spacing--l);
    }

    .c-get-more--with-count {
        padding: var(--spacing--l);
    }
}
/* =============================================================================
 * Media
============================================================================= */
@define-mixin o-media $container-query, $suffix {
	$container-query .o-media$(suffix) {
		display: flex;
		width: 100%;
	}

	$container-query .o-media$(suffix)::after {
		display: table;
		clear: both;
		content: "";
	}

	$container-query .o-media$(suffix).o-media--middle > .o-media__secondary,
	$container-query .o-media$(suffix).o-media--middle > .o-media__primary {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	$container-query .o-media$(suffix) > .o-media__primary {
		order:1;
		flex-grow: 1;
		overflow: auto;
	}

	$container-query .o-media$(suffix) > .o-media__secondary {
		float: left;
		flex-shrink: 0;
		padding-right:calc(var(--spacing--horizontal-gutter) * 2);
	}

	.o-media$(suffix) > .o-media__secondary--shrinkable {
		flex-shrink: 1;
	}

	$container-query .o-media$(suffix) > .o-media__secondary--tight {
		padding-right:var(--spacing--horizontal-gutter);
	}

	.o-media$(suffix) > .o-media__secondary--tighter {
		padding-right:calc(0.5 * var(--spacing--horizontal-gutter));
	}

	$container-query .o-media$(suffix) > .o-media__secondary--right {
		float: right;
		order:3;
		padding-right: 0;
		padding-left:calc(var(--spacing--horizontal-gutter) * 2);
	}

	$container-query .o-media$(suffix) > .o-media__secondary--right.o-media__secondary--tight {
		padding-left:var(--spacing--horizontal-gutter);
	}

	.o-media$(suffix) > .o-media__secondary--right.o-media__secondary--tighter {
		padding-left:calc(0.5 * var(--spacing--horizontal-gutter));
	}
}

@mixin o-media;
@mixin o-media .o-container:container(width > 300px), \@300;
@mixin o-media .o-container:container(width > 500px), \@500;
@mixin o-media .o-container:container(width > 600px), \@600;
@mixin o-media .o-container:container(width > 700px), \@700;
@mixin o-media .o-container:container(width > 900px), \@900;
@mixin o-media .o-container:container(width > 1100px), \@1100;

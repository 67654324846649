.c-map {
	position: relative;
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

/* The purpose of this is to set the aspect ratio */
.c-map::before {
	display: block;
	content:"";
}

.c-map--landscape::before {
	padding-top: 56.25%;
}

.c-map--square::before {
	padding-top: 100%;
}

.c-map--portrait::before {
	padding-top: 125%;
}

.c-map__map {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background:var(--color--grey-1);
}

.c-map__map-data {
	display: none;
}

/* Aspect ratios for small screens
---------------------------------------------------------------------------- */
@media (--sm) {
	.c-map--landscape\@sm::before {
		padding-top: 56.25%;
	}

	.c-map--square\@sm::before {
		padding-top: 100%;
	}

	.c-map--portrait\@sm::before {
		padding-top: 125%;
	}
}

/* Aspect ratios for medium screens
---------------------------------------------------------------------------- */
@media (--md) {
	.c-map--landscape\@md::before {
		padding-top: 56.25%;
	}

	.c-map--square\@md::before {
		padding-top: 100%;
	}

	.c-map--portrait\@md::before {
		padding-top: 125%;
	}
}

/* Aspect ratios for large screens
---------------------------------------------------------------------------- */
@media (--lg) {
	.c-map--landscape\@lg::before {
		padding-top: 56.25%;
	}

	.c-map--square\@lg::before {
		padding-top: 100%;
	}

	.c-map--portrait\@lg::before {
		padding-top: 125%;
	}
}

/* Aspect ratios for extra large screens
---------------------------------------------------------------------------- */
@media (--xl) {
	.c-map--landscape\@xl::before {
		padding-top: 56.25%;
	}

	.c-map--square\@xl::before {
		padding-top: 100%;
	}

	.c-map--portrait\@xl::before {
		padding-top: 125%;
	}
}

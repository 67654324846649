/* =============================================================================
 * Text scope
 *
 * Adds styling to elements when inside the scope .s-text, e.g. margin
 * or another font-weight than 'normal'.
============================================================================= */

/* Margins
 ---------------------------------------------------------------------------- */
h1.s-text,
h2.s-text,
h2.s-text,
h3.s-text,
h4.s-text,
h5.s-text,
h6.s-text {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
}


img.s-text,
ul.s-text,
ol.s-text,
dl.s-text,
blockquote.s-text,
p.s-text,
address.s-text,
hr.s-text,
table.s-text,
fieldset.s-text,
figure.s-text,
pre.s-text {
	margin-bottom:var(--spacing--vertical-spacing-unit);
}

/*.s-text:last-child {
	margin-bottom: 0;
}*/

/* Headings
 ---------------------------------------------------------------------------- */
h1,
h1.s-text {
	@mixin text-style-alpha;
	font-family: var(--type--family-default);
	word-wrap: break-word;
}
h2,
h2.s-text {
	@mixin text-style-beta;
	font-family: var(--type--family-default);
}
h3,
h3.s-text {
	@mixin text-style-epsilon;
	font-weight: 400;
	font-size: 24px;
	font-family: var(--type--family-default);
	text-transform: initial;
}
h4,
h4.s-text {
	@mixin text-style-zeta;
	font-family: var(--type--family-default);
}

/* Text link
 ---------------------------------------------------------------------------- */

a.s-text,
.s-text a {
	color: var(--color--link-color);
	font-weight: inherit;
	font-size: inherit;
	text-decoration:none;
}

a.s-text:--hocus,
.s-text a:--hocus {
	text-decoration:underline;
}

/* Inline typography
 ---------------------------------------------------------------------------- */
i.s-text,
em.s-text {
	font-style:italic;
}

b.s-text,
strong.s-text {
	font-weight:bold;
	font-size: inherit;
	text-transform: inherit;
}

p.s-text {
	@mixin text-style-reset;
}


/* Lists
 *
 * 1. Remove trailing margins from nested lists.
 ---------------------------------------------------------------------------- */
ul.s-text {
	list-style:disc;
}
ol.s-text {
	list-style:decimal;
}
ul.s-text,
ol.s-text,
dd.s-text {
	margin-left:18px;
}
li.s-text {
	margin-bottom:6px;
}
li.s-text > ul,
li.s-text > ol {
	margin-top:6px;
	margin-bottom:0; /* [1] */
}

dl.s-text{
	@mixin text-style-reset;
}


/* Tables
 *
 * 1. To counter-act top padding on th
 * 2. Needs space on account for background-color
 ---------------------------------------------------------------------------- */

tr.s-text > :first-child {
	text-indent: 6px; /*[2]*/
}

th.s-text + td {
	background: var(--color--primary-blue);
	text-indent: 6px;
}

table.s-text {
	margin-top:-5px; /* [1] */
}

table.s-text.zebra tbody tr:nth-child(even) td {
	background: var(--color--grey-1);
}

caption.s-text {
	padding-bottom:12px;
	font-weight:500;
	text-transform:uppercase;
}

th.s-text {
	padding:5px 20px 5px 0;
	border-bottom:1px solid var(--color--primary-blue);
	color:black;
	font-weight:400;
}

td.s-text,
tbody th.s-text {
	padding:12px 18px 12px 0;
	border-bottom:1px solid var(--color--grey-2);
}

tfoot.s-text {
	background: var(--color--grey-2);
	font-weight:400;
}

/*Key value list with table*/

table.o-key-value-list--separated th:last-child,
table.o-key-value-list--separated td:last-child{
	text-align: right;
}

table.o-key-value-list--separated th,
table.o-key-value-list--separated td{
	padding:0;
	border-bottom-width: 0;
}

/* Inverted variant
 * For use on darker backgrounds
 ---------------------------------------------------------------------------- */
.s-text--inverted {
	color:#fff;
}

.s-text--inverted a {
	color: inherit;
	text-decoration:underline;
}

.s-text--inverted a:--hocus {
	text-decoration:none;
}

.c-list-item-extended {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 40px;
    padding: 16px;
    border-radius: 8px;
    background: var(--color--white);
}

.c-list-item-extended--compact {
    padding: 24px 0;
    border-radius: 0;
}

.c-list-item-extended:focus-within {
    @mixin box-shadow-focus;
}

.c-list-item-extended--no-media {
    display: block;
}

.c-list-item-extended__media-container {
    flex: 0 0 auto;
    margin: -16px -16px 0 -16px;
}

.c-list-item-extended--no-media .c-list-item-extended__media-container {
    margin: 0 0 0 -16px;
}

.c-list-item-extended--compact .c-list-item-extended__media-container {
    margin: 0 -18px 0 -18px;
}

.c-list-item-extended--compact.c-list-item-extended--no-media .c-list-item-extended__media-container {
    margin: 0 0 0 -18px;
}

.c-list-item-extended__media {
    width: 100%;
    height: auto;
}

.c-list-item-extended__media img {
    width: 100%;
    height: 100%;
}

.c-list-item-extended__tag {
    position: relative;
    float: left;
    margin-top: -56px;
    padding: 8px 32px 8px 16px;
    border-radius: 0 24px 24px 0;
    background: var(--color--primary-blue);
    color: var(--color--white);
}

.c-list-item-extended--no-media .c-list-item-extended__tag {
    display: inline-block;
    float: none;
}

.c-list-item-extended--compact .c-list-item-extended__tag {
    padding-left: 18px;
}

.c-list-item-extended__content {
    margin-top: 8px;
}

.c-list-item-extended--compact .c-list-item-extended__content {
    margin-top: 12px;
}

.c-list-item-extended--no-media .c-list-item-extended__content {
    margin-top: 12px;
}

.c-list-item-extended__title {
    @mixin text-style-heading-s;
    color: var(--color--link-color);
    text-decoration: underline;
}

.c-list-item-extended__title:--hocus,
.c-list-item-extended:focus-within>.c-list-item-extended__title {
    outline: none;
    color: var(--color--black);
    text-decoration: none;
}

.c-list-item-extended__title h3 {
    margin-bottom: 16px;
}

.c-list-item-extended__timestamp {
    @mixin text-style-date;
    display: inline-block;
    margin-bottom: 8px;
}

.c-list-item-extended__text {
    @mixin text-style-body;
}

@media (--md-lg-xl) {
    .c-list-item-extended {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 24px;
        padding: 0 24px 24px 24px;
    }

    .c-list-item-extended--compact {
        padding: 24px 0;
    }

    .c-list-item-extended__media-container {
        margin: 0;
        margin-top: 24px;
        margin-right: 24px;
    }

    .c-list-item-extended--no-media .c-list-item-extended__media-container {
        margin: 0;
    }

    .c-list-item-extended--compact .c-list-item-extended__media-container {
        margin: 0;
        margin-top: 18px;
        margin-right: 18px;
    }

    .c-list-item-extended--compact.c-list-item-extended--no-media .c-list-item-extended__media-container {
        margin: 0;
    }

    .c-list-item-extended__media {
        max-width: 311px;
    }

    .c-list-item-extended__tag {
        margin-left: -24px;
        padding: 8px 24px;
    }

    .c-list-item-extended__content {
        flex: 1 0 60%;
        margin-top: 24px;
    }

    .c-list-item-extended--compact .c-list-item-extended__content {
        margin-top: 12px;
    }

    .c-list-item-extended--no-media.c-list-item-extended--compact .c-list-item-extended__content {
        margin-top: 18px;
    }

    .c-list-item-extended__title {
        @mixin text-style-san;
        color: var(--color--link-color);
    }

    .c-list-item-extended--no-media .c-list-item-extended__tag {
        margin-top: 24px;
    }

    .c-list-item-extended--compact .c-list-item-extended__tag {
        margin-left: -18px;
        padding: 8px 24px 8px 18px;
    }

    .c-list-item-extended--no-media.c-list-item-extended--compact .c-list-item-extended__tag {
        margin-top: 18px;
    }

    .c-list-item-extended--no-media .c-list-item-extended__content {
        margin-top: 16px;
    }

    .c-list-item-extended--compact {
        padding: 0 0 18px 0;
    }
}

.c-list-item-extended--compact {
    margin-bottom: 0;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0;
}
/* =============================================================================
 * Share links component
============================================================================= */

.c-share-links {
    display: inline-flex;
    align-items: center;
}

.c-share-links h2 {
    @mixin text-style-stigma;
}

.c-share-links__link {
    margin: 4px;
    padding: 12px;
}

.c-share-links__link:focus {
    outline: 2px solid #000;
    outline-offset: 2px;
}

.c-share-links__link [class*=icon] {
    fill: var(--color--primary-blue);
}

@media (--lg-xl) {
    .c-share-links__link {
        margin: 0 0 0 16px;
        padding: 0;
    }
}
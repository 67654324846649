.o-key-value-list:after {
    display: table;
    clear: both;
    content: "";
}

.o-key-value-list--separated .o-key-value-list__key {
    float: left;
    clear: left;
}

.o-key-value-list--separated .o-key-value-list__value {
    float: right;
    clear: right;
}

.o-key-value-list--joined .o-key-value-list__key {
    float: left;
    width: 33%;
    text-align: right;
}

.o-key-value-list--joined .o-key-value-list__value {
    float: left;
    width: 66%;
    padding-left: var(--spacing--horizontal-gutter);
}

.o-key-value-list--airy .o-key-value-list__key,
.o-key-value-list--airy .o-key-value-list__value {
    margin-bottom: calc(0.5 * var(--type--line-height-default));
}

.o-key-value-list--weighted .o-key-value-list__key {
    font-weight: 800;
}

.o-key-value-list--weighted .o-key-value-list__value {
    font-weight: 500;
}

.o-key-value-list--table .o-key-value-list__value {
    margin-bottom: 16px;
}

.o-key-value-list--table.o-key-value-list--less-bottom-margin .o-key-value-list__value {
    margin-bottom: 8px;
}

.o-key-value-list--table div:last-child .o-key-value-list__value {
    margin-bottom: 0;
}

@media (--md-lg-xl) {
    .o-key-value-list--table div {
        display: table-row;
    }

    .o-key-value-list--table .o-key-value-list__key {
        display: table-cell;
        white-space: nowrap;
    }

    .o-key-value-list--table .o-key-value-list__value {
        margin-left: 16px;
    }
}
.c-circle-date {
	display: inline-flex;
}

.c-circle-date--bottom-spacing {
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-circle-date__container {
	@mixin border-grey-light;
	display: flex;
	position: relative;
	float:left;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width:70px;
	height: 70px;
	border-radius: 100%;
	background: white;
}

.c-circle-date__container {
	text-align: center;
}

.c-circle-date__day {
	@mixin text-style-pi;
	display:block;
	z-index: 2;
}


.c-circle-date__day--color-1 {
	color: var(--color--primary-blue);
}

.c-circle-date__day--color-2 {
	color: var(--color--primary-green);
}

.c-circle-date__day--color-3 {
	color: var(--color--primary-purple);
}

.c-circle-date__day--color-4 {
	color: var(--color--primary-orange);
}

.nacka-theme-1 .c-circle-date__day,
[class^="nacka-theme-"] .nacka-theme-1 .c-circle-date__day {
	color: var(--color--primary-green)
}

.nacka-theme-2 .c-circle-date__day,
[class^="nacka-theme-"] .nacka-theme-2 .c-circle-date__day {
	color:var(--color--primary-orange);
}

.nacka-theme-3 .c-circle-date__day,
[class^="nacka-theme-"] .nacka-theme-3 .c-circle-date__day {
	color:var(--color--primary-purple);
}

.nacka-theme-4 .c-circle-date__day,
[class^="nacka-theme-"] .nacka-theme-4 .c-circle-date__day {
	color:var(--color--primary-blue);
}

.nacka-theme-5 .c-circle-date__day,
[class^="nacka-theme-"] .nacka-theme-5 .c-circle-date__day {
	color:var(--color--ybc-green);
}

.nacka-theme-6 .c-circle-date__day,
[class^="nacka-theme-"] .nacka-theme-6 .c-circle-date__day {
	color:var(--color--school-blue);
}

/* Need to change this */
.c-circle-date__month {
	display:block;
	color: var(--color--grey-4);
	font-weight: 500;
	-webkit-font-smoothing: auto;
	font-size: 10px;
	line-height: 18px;
	text-transform: uppercase;
}

.c-circle-date__container + .c-circle-date__container {
	margin-left: -20px;
}

.c-circle-date__container + .c-circle-date__container .c-circle-date__day:before {
	position: absolute;
	top: 50%;
	left: -1px;
	width: 12px;
	height: 44px;
	padding-top: 4px;
	padding-left: 5px;
	background-color: #fff;
	content: " -";
	transform: translateY(-50%);
}


/* Large variant
---------------------------------------------------------------------------- */
.c-circle-date--large .c-circle-date__container {
	width: 140px;
	height: 140px;
}

/* Need to change this */
.c-circle-date--large .c-circle-date__day {
	font-weight: 500;
	font-size: 52px;
	line-height: 52px;
}

.c-circle-date--large .c-circle-date__month {
	@mixin text-style-nu;
	color: var(--color--grey-4);
}

.c-circle-date--large .c-circle-date__container + .c-circle-date__container {
	margin-left: -45px;
}

.c-circle-date--large .c-circle-date__container + .c-circle-date__container .c-circle-date__day::before {
	width: 30px;
	height: 90px;
	padding-top: 10px;
	padding-left: 9px;
}


/* Small variant
---------------------------------------------------------------------------- */
.c-circle-date--small .c-circle-date__container {
	width: 50px;
	height: 50px;
}

.c-circle-date--small .c-circle-date__day {
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
}

.c-circle-date--small .c-circle-date__month {
	line-height: 12px;
}

.c-circle-date--small .c-circle-date__container + .c-circle-date__container {
	margin-left: -12px;
}

.c-circle-date--small .c-circle-date__container + .c-circle-date__container .c-circle-date__day::before {
	left: -2px;
	width: 14px;
	height: 30px;
	padding-top: 0;
	padding-left: 1px;
	line-height: 15px;
}

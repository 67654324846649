.c-youtube-block {
	@mixin border-grey-light;
	margin:0 0 var(--spacing--horizontal-gutter);
	background-color: var(--color--white);
}

.c-youtube-block__video-container {
	overflow: auto;
}

.c-youtube-block iframe {
	max-width: 100%;
}

.c-youtube-block__movie-info {
	@mixin text-style-psi;
	text-transform: initial;
}

.c-youtube-block__movie-length {
	@mixin text-style-psi;
	margin-left: calc(var(--spacing--horizontal-gutter) / 2);
	color: var(--color--grey-4);
}

.c-youtube-block__description {
	@mixin text-style-upsilon;
	color: black;
	text-transform: initial;
}

.c-youtube-block__link {
	@mixin text-style-upsilon;
	font-size: 18px;
}

.c-youtube-block__link:--hocus {
	text-decoration: underline;
}

@media (--lg-xl){
	.c-youtube-block__movie-info,
	.c-youtube-block__description {
		display:block;
		max-width: 350px;
	}
}

.c-top-task-link {
	display: block;
	display: flex;
	flex-grow: 1;
	max-width:100%;
	padding:var(--spacing--vertical-spacing-unit);
}

.c-top-task-link--border {
	@mixin border-grey-light;
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-top-task-link:--hocus {
	background: var(--color--grey-1);
}

.c-top-task-link--border:--hocus {
	border-color: var(--color--link-color);
}

.c-top-task-link__heading {
	@mixin text-style-beta;
	color:var(--color--link-color);
	font-size: 30px;
	line-height: 36px;
}

.c-top-task-link__heading + .c-top-task-link__text{
	margin-top: calc( var(--spacing--vertical-spacing-unit) / 2);
}

.c-top-task-link__icon {
	display: block;
}

.c-top-task-link__icon {
	color:var(--color--link-color);
}

 @media (--sm) {
 	.c-top-task-link .c-circle-number {
 		margin-bottom: calc( var(--spacing--vertical-spacing-unit) / 2);
 	}
 }

.c-panic-button {
    position: fixed;
    top: 0;
    left: 10px;
    z-index: 1000;
    margin: 0;
    padding: 10px 10px 0 10px;
    background:#c65300;
}

.c-panic-button a {
    color: #fff;
}

.c-panic-button .text {
    padding-right: 10px;
}

@media (--sm-md) {
    .c-panic-button {
        display: none;
    }
}
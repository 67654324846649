.c-separated-list {
	display: inline-flex;
	max-width: 100%;
	margin-left: var(--spacing--horizontal-gutter);
}

.c-separated-list__item-wrapper {
	display: flex;
	align-items: center;
	min-width:160px;
	width: 160px;
	padding: 0 calc(var(--spacing--horizontal-gutter) / 2);
	border-left: 1px solid var(--color--grey-2);
}

.c-separated-list__link {
	@mixin text-style-theta;
	padding: 0 calc(var(--spacing--horizontal-gutter)/2) 0 0;
	white-space: normal;
}

.c-separated-list__link:--hocus {
	text-decoration: underline;
}

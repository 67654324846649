/* =============================================================================
 * Header
============================================================================= */
.c-header-campaign {
	padding:0;
	border-bottom:2px solid #41748D; /* TODO variable?*/
	background-color:#fff;
}

@media print {
	.c-header-campaign {
		display: none;
	}
}

.c-header-campaign .c-logo__image {
	width: auto;
	height: 60px;
}

.nacka-theme-1.c-header-campaign,
[class^="nacka-theme-"] .nacka-theme-1.c-header-campaign {
	border-top-color: var(--color--primary-green)
}

.nacka-theme-2.c-header-campaign,
[class^="nacka-theme-"] .nacka-theme-2.c-header-campaign {
	border-top-color:var(--color--primary-orange);
}

.nacka-theme-3.c-header-campaign,
[class^="nacka-theme-"] .nacka-theme-3.c-header-campaign {
	border-top-color:var(--color--primary-purple);
}

.nacka-theme-4.c-header-campaign,
[class^="nacka-theme-"] .nacka-theme-4.c-header-campaign {
	border-top-color:var(--color--primary-blue);
}

.nacka-theme-5.c-header-campaign,
[class^="nacka-theme-"] .nacka-theme-5.c-header-campaign {
	border-top-color:var(--color--ybc-green);
}

.nacka-theme-6.c-header-campaign,
[class^="nacka-theme-"] .nacka-theme-6.c-header-campaign {
	border-top-color:var(--color--school-blue);
}

.c-header-campaign__inner {
	display:flex;
	align-items:center;
	margin: 0 40px;
}

.c-header-campaign__inner .c-header__logo {
	flex-shrink: 0;
	margin-right: 10px;
}

.c-header-campaign__search {
	margin-left: auto;
}

.c-header-campaign__search span {
	font-size: 9px!important;
}

.c-header-campaign__menu-item .js-toggle-scope-target {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (--sm-md) {
	.c-header-campaign {
		display:none; /* Hide until we get time for #482 */
	}
}

.js .c-panel {
	display: flex;
	position: absolute;
	top: 0;
	z-index: 90;
	flex-direction: column;
	max-width: 100vw;
	width: 340px;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	outline: none;
	background: #fff;
	text-align: left;
	transition: all 0.4s;
	transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
	transition-property: transform;
	transform: translateX(-100%);
	-webkit-overflow-scrolling: touch;
}

@media (--lg) {
	.js .c-panel {
		width: 360px;
	}
}

.js .c-panel--expanded {
	box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.2);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform: translateX(0);
}

.js .c-panel--right {
	right: 0;
	transform: translateX(100%);
}

.js .c-panel--right.c-panel--expanded {
	box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.2);
	transform: translateX(0);
}

.c-panel__header {
	display: block;
	flex-shrink: 0;
	width: 100%;
	padding: 13px var(--spacing--horizontal-gutter);
	outline: none;
	background-color: var(--color--primary-blue);
}

.nacka-theme-1 .c-panel__header,
[class^="nacka-theme-"] .nacka-theme-1 .c-panel__header {
	background-color: var(--color--primary-green);
}

.nacka-theme-2 .c-panel__header,
[class^="nacka-theme-"] .nacka-theme-2 .c-panel__header {
	background-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-panel__header,
[class^="nacka-theme-"] .nacka-theme-3 .c-panel__header {
	background-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-panel__header,
[class^="nacka-theme-"] .nacka-theme-4 .c-panel__header {
	background-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-panel__header,
[class^="nacka-theme-"] .nacka-theme-5 .c-panel__header {
	background-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-panel__header,
[class^="nacka-theme-"] .nacka-theme-6 .c-panel__header {
	background-color: var(--color--school-blue);
}

.c-panel__title {
	@mixin text-style-gamma;
	color: #fff;
	line-height: 48px;
	vertical-align: middle;
}

.js-panel-closer:focus:not(:focus-visible) {
  outline: none;
}

.js-panel-closer:focus-visible .c-circle-icon--48x48 {
  outline: 2px solid #fff;
  outline-offset: 4px;
  border-radius: 50%;
}
@keyframes target {
	100% {
		box-shadow: 0 0 0 transparent;
	}
}

.c-discussion-entry {
	position: relative;
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-discussion-entry:target {
	z-index: 1;
	box-shadow: 0 0 20px var(--color--link-color);
	animation: 1s 1s target forwards;
}

.c-discussion-entry__user {
	@mixin text-style-zeta;
	color: var(--color--link-color);
}

.c-discussion-entry__user:--hocus {
	text-decoration: underline;
}

.c-discussion-entry__action-item + .c-discussion-entry__action-item {
	margin-left: var(--spacing--horizontal-spacing-unit);
}

.c-discussion-entry__replies {
	position: relative;
	text-align: center;
}

.c-discussion-entry__replies:before {
	display: inline-block;
	position: absolute;
	top: -8px;
	left: calc(50% + 12px);
	width: 20px;
	height: 20px;
	margin-left: -15px;
	box-shadow: -2px -2px 0 -1px rgba(0,0,0,.1);
	background: var(--color--grey-1);
	content: '';
	transform: translateX(-50%) rotate(45deg);
}

.c-discussion-entry__reply {
	position: relative;
	margin-left: var(--spacing--horizontal-spacing-unit);
	padding-bottom: var(--spacing--vertical-spacing-unit);
	border-left: 1px solid var(--color--grey-2);
	background: var(--color--grey-1);
	text-align: left;
}

.c-discussion-entry__reply:target {
	z-index: 1;
	box-shadow: 0 0 20px var(--color--link-color);
	animation: 1s 1s target forwards;
}

.c-discussion-entry__replies:before {
	display: inline-block;
	position: absolute;
	top: -8px;
	left: calc(50% + 12px);
	width: 20px;
	height: 20px;
	margin-left: -15px;
	box-shadow: -2px -2px 0 -1px rgba(0,0,0,.1);
	background: var(--color--grey-1);
	content: '';
	transform: translateX(-50%) rotate(45deg);
}

.c-discussion-entry__reply-text {
	height: 44px;
}

.c-discussion-entry__toggle-replies {
	@mixin text-style-psi;
	display:block;
	position: relative;
	margin: -17px auto 0;
	padding: 0 var(--spacing--horizontal-spacing-unit);
	border: 1px solid var(--color--grey-2);
	border-radius: 20px;
	background: #fff;
	color: var(--color--link-color);
	line-height: 32px;
	text-transform: uppercase;
}

.c-discussion-entry__toggle-replies:--hocus {
	text-decoration: underline;
}

/* Animations
----------------------------------------------------------------------------- */
.c-discussion-entry--new {
	background-color: #fff;
	animation: c-discussion-entry--new 1s ease-in-out forwards;
}

@keyframes c-discussion-entry--new {
	0% {
		background-color: #fff;
		transform: scale(1);
	}

	25% {
		background-color: #e0fabf;
		transform: scale(1.1);
	}

	50% {
		transform: scale(1);
	}

	100% {
		background-color: #fff;
	}
}

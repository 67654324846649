.c-alphabetic-index__anchors {
	display: flex;
	flex-wrap: wrap;
	align-items:center;
	padding: calc(var(--spacing--vertical-spacing-unit) / 2);
	background-color: var(--color--primary-blue);
}

.nacka-theme-1 .c-alphabetic-index__anchors,
[class^="nacka-theme-"] .nacka-theme-1 .c-alphabetic-index__anchors {
	background-color: var(--color--primary-green)
}

.nacka-theme-2 .c-alphabetic-index__anchors,
[class^="nacka-theme-"] .nacka-theme-2 .c-alphabetic-index__anchors {
	background-color:var(--color--primary-orange);
}

.nacka-theme-3 .c-alphabetic-index__anchors,
[class^="nacka-theme-"] .nacka-theme-3 .c-alphabetic-index__anchors {
	background-color:var(--color--primary-purple);
}

.nacka-theme-4 .c-alphabetic-index__anchors,
[class^="nacka-theme-"] .nacka-theme-4 .c-alphabetic-index__anchors {
	background-color:var(--color--primary-blue);
}

.nacka-theme-5 .c-alphabetic-index__anchors,
[class^="nacka-theme-"] .nacka-theme-5 .c-alphabetic-index__anchors {
	background-color:var(--color--ybc-green);
}

.nacka-theme-6 .c-alphabetic-index__anchors,
[class^="nacka-theme-"] .nacka-theme-6 .c-alphabetic-index__anchors {
	background-color:var(--color--school-blue);
}

.c-alphabetic-index__anchor-wrapper {
	flex:1 1 auto;
	text-align: center;
}

.c-alphabetic-index__anchor {
	@mixin text-style-delta;
	display: inline-block;
	width:32px;
	height:32px;
	border-radius:50%;
	color:white;
	font-weight:400;
	line-height:32px;
	transition-property: color, background-color;
}

.c-alphabetic-index__anchor:not(.c-alphabetic-index__anchor--disabled):--hocus {
	background:white;
	color: var(--color--primary-blue);
}

.c-alphabetic-index__anchor--disabled {
	opacity:0.5;
	cursor:not-allowed;
	pointer-events:none;

}

.c-alphabetic-index__sections {
	columns: 2;
	column-gap:0;
	column-rule: 1px solid var(--color--grey-2);
}

.c-alphabetic-index__section {
	border-top: 1px solid var(--color--grey-2);
	break-inside: avoid-column;
}

.c-alphabetic-index__section-title {
	@mixin text-style-alpha;
	margin: var(--spacing--vertical-spacing-unit) 0;
}

@media (--sm-md-lg) {
	.c-alphabetic-index__anchor {
			width: 30px;
			height: 30px;
			line-height:30px;
	}
}

@media (--sm-md) {
	.c-alphabetic-index__sections {
		columns: 1;
	}
}
.t-color-1[class][class][class] {
	color: var(--color--primary-green);
}

.t-color-2[class][class][class] {
	color: var(--color--primary-orange);
}

.t-color-3[class][class][class] {
	color: var(--color--primary-purple);
}

.t-color-4[class][class][class] {
	color: var(--color--primary-blue);
}

.t-color-5[class][class][class] {
	color: #fff;
}

.t-color-6[class][class][class] {
	color: var(--color--secondary-red);
}

.t-color-7[class][class][class] {
	color: var(--color--grey-6);
}

.t-color-8[class][class][class] {
    color: var(--color--secondary-darkblue);
}

.t-color-9[class][class][class] {
    color: var(--color--graphite-3);
}
.t-absolute {
	position: absolute;
}

.t-absolute--top {
	top: 0;
}

.t-absolute--left {
	left: 0;
}

.t-absolute--right {
	right: 0;
}

.t-absolute--middle {
	top: 50%;
	transform: translateY(-50%);
}

.t-absolute--bottom {
	top: auto;
	bottom: 0;
}
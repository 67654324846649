.c-discussion-entry-form {
	background: var(--color--grey-1);
}

.c-discussion-entry-form .c-icon-input__icon {
	top: -20px;
}

.c-discussion-entry-form__content {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	background: #fff;
}

.c-discussion-entry-form__checkbox {
	margin: var(--spacing--vertical-spacing-unit) 0;
}

.c-discussion-entry-form__warning {
	@mixin text-style-kappa;
	margin-top: var(--spacing--vertical-spacing-unit);
}

.c-discussion-entry-form__image-edit {
	position: relative;
	margin-bottom: var(--spacing--vertical-spacing-unit);
	border: 1px solid var(--color--grey-2);
	background: #fff;
	text-align: center;
}

.c-discussion-entry-form__delete-image {
	position: absolute;
	top: var(--spacing--vertical-spacing-unit);
	right: var(--spacing--horizontal-spacing-unit);
}

.c-discussion-entry-form--small {
	background: none;
}

.c-discussion-entry-form--small .c-discussion-entry-form__content {
	height: 40px;
	background: var(--color--grey-1);
}

.c-discussion-entry-form--small.c-discussion-entry-form--expanded .c-discussion-entry-form__content {
	height: 80px;
}

.c-discussion-entry-form--small.c-discussion-entry-form--expanded .c-icon-input__icon {
	top: 10px;
}

.c-discussion-entry-form__container[class][class] {
	overflow: visible;
}


/* Specifics for mentions in userbar */
.c-discussion-entry-form--small .c-icon-input .c-mentions__input-overlay {
	padding-left: 32px;
}
.c-discussion-entry-form--small:not(.c-discussion-entry-form--expanded) .c-icon-input .c-mentions__input-overlay,
.c-discussion-entry-form--small:not(.c-discussion-entry-form--expanded) .c-mentions .c-discussion-entry-form__content {
	height: 40px!important;
}

.c-teaser-card {
	display: flex;
	position:relative;
	justify-content: center;
	align-items: center;
	min-height: 395px;
	margin-bottom: 18px;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px 0px rgba(13,13,13,1);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
	text-align: center;
	transition: all 0.2s ease-in-out;
}

.c-teaser-card:hover {
	box-shadow: 0px 4px 8px 0px rgba(13,13,13,1);
}

.c-teaser-card__hover-overlay {
	display:none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color:rgba(255,255,255,0.08);
}

.c-teaser-card:hover .c-teaser-card__hover-overlay {
	display:block;
}

.c-teaser-card__image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	box-shadow: 0px 0px 1px 0px rgba(13,13,13,0.9);
	background-color:rgba(0,0,0,0.5);
}

.c-teaser-card p {
	z-index: 1;
	font-weight: 600;
	font-size: 30px;
	line-height: 36px;
}

.c-teaser-card:focus {
	outline: 4px solid #1C93FF !important;
	outline-offset: 4px;
}

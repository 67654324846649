.c-construction-plan-banner__image img {
	width:100%;
	height:auto;
}

.c-construction-plan-banner__intro {
	position:relative;
	margin:var(--spacing--vertical-spacing-unit) var(--spacing--horizontal-spacing-unit);
	padding:5px;
	background:#fff;
}

.c-construction-plan-banner:container(width > 700px) .c-construction-plan-banner__image {
	margin-bottom:-54px;
}

.c-pop-out {
	@mixin border-grey-light;
	display: inline-block;
	position:absolute;
	z-index: 2;
	min-height: 72px;
	margin-left: -300px;
	padding: calc(var(--spacing--vertical-spacing-unit)) 0 0;
	outline: 0;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: 3px;
	box-shadow: 0 -10px 30px rgba(0,0,0,.1);
	background-color: white;
	color: var(--type--color-default);
}

.c-pop-out__close-button {
	position: absolute;
	top: calc(var(--spacing--vertical-spacing-unit) / 2);
	right: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-pop-out__arrow {
	display: inline-block;
	position: absolute;
	top: -8px;
	right: 140px;
	width: 20px;
	height: 20px;
	margin-left: -15px;
	box-shadow: -2px -2px 0 -1px rgba(0, 0, 0, 0.1);
	background: #fff;
	content: "";
	transform: rotate(45deg);
}

.c-pop-out__content {
	text-align: left;
}

.c-link-list  {
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

.c-link-list__item {
	display: flex;
	position:relative;
	align-items: center;
	border-bottom:1px solid transparent;
}

.c-link-list--indent .c-link-list__item {
	padding-right: var(--spacing--horizontal-spacing-unit);
	padding-left: var(--spacing--horizontal-spacing-unit);
}

.c-link-list--indent .c-link-list__item {
	padding-right: var(--spacing--horizontal-spacing-unit);
	padding-left: var(--spacing--horizontal-spacing-unit);
}

.c-link-list--indent.c-link-list--sortable .c-link-list__item {
	padding-left: 0;
}

.c-link-list__item .o-space-between__item {
	margin:0;
}

.c-link-list__item--placeholder {
	background: var(--color--grey-1);
}

.c-link-list__item--border,
.c-link-list__item--border + .c-link-list__item--placeholder {
	border-bottom:1px solid var(--color--grey-2);
}

.c-link-list__handle {
	align-self: stretch;
	width: 24px;
	background: url(images/handle.svg) 50% 50% no-repeat;
	opacity: 0.3;
	cursor: grab;
	transition-property: opacity;
}

.no-js .c-link-list__handle {
	display: none;
}

.c-link-list__item--grabbed .c-link-list__handle {
	cursor: grabbing;
}

.c-link-list--sortable:hover .c-link-list__handle {
	opacity: 0.5;
}

.c-link-list__item:hover .c-link-list__handle,
.c-link-list__handle[class]:focus {
	opacity: 1;
}

.c-link-list__description {
	margin-right: 0.3em;
}

.c-link-list__link {
	display:flex;
	align-items: center;
	padding:5px 0 3px 0;
	color:var(--color--primary-blue);
}

.c-link-list--extra-padding .c-link-list__link {
	padding:9px 0 7px 0;
}

.c-link-list--extra-spacing .c-link-list__item {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit)* 1.5);
}

.c-link-list--extra-spacing .c-link-list__link {
	padding:0;
}

.c-link-list__item--border .c-link-list__link {
	flex-grow: 1;
}

.c-link-list__link:--hocus {
	text-decoration:underline;
}

.c-link-list__icon {
    margin-left: auto;
}

.c-link-list__icon.c-icon--8x8 {
    justify-content: flex-end;
    width: 16px;
}

.c-link-list__icon path {
	fill:var(--color--primary-blue);
}

/* =============================================================================
 * Reset
 *
 * 1. This will give all elements the same transition feel, but will not cause
 *    all elements to have a transition, as we set the property to 'none'.
============================================================================= */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	font: inherit;
	font-weight: inherit;
	font-style: inherit;
	text-align: inherit;
	transition: none 0.15s ease; /* [1] */
}

/* Disable focus outline when using mouse */
html.mouse *:focus {
	outline: none;
}

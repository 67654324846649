/* =============================================================================
 * Editor scope
 *
 * Used ONLY in the WYSIWYG editor inside Episerver. Should not be included
 * in the main css file.
============================================================================= */

@import "..\..\config\config.custom-media.css";
@import "../text/scopes.text.css";
@import "..\..\trumps\absolute\trumps.absolute.css";

@import "../../components/checklist/components.checklist.css";

.s-text,
.s-editor {
	@mixin text-style-reset;
}

	/* Outlines
================================================================================ */
	.s-editor p,
	.s-editor td {
		padding: 10px;
		border: 1px solid #eee;
	}


		/* Warnings
================================================================================ */
		/* Incorrectly nested elements */
		.s-editor a a,
		.s-editor strong h1,
		.s-editor strong h2,
		.s-editor strong h3,
		.s-editor strong h4,
		.s-editor strong h5,
		.s-editor strong h6,
		.s-editor strong table,
		.s-editor h1 p,
		.s-editor h2 p,
		.s-editor h3 p,
		.s-editor h4 p,
		.s-editor h5 p,
		.s-editor h6 p,
		.s-editor p h1,
		.s-editor p h2,
		.s-editor p h3,
		.s-editor p h4,
		.s-editor p h5,
		.s-editor p h6,
		/* Empty Attributes */
		.s-editor *[class=""],
		.s-editor a[href=""],
		.s-editor *[id=""],
		.s-editor *[title=""],
		/* Disallowed attributes */
		.s-editor *[background],
		.s-editor *[border],
		.s-editor *[cellpadding],
		.s-editor *[cellspacing],
		.s-editor table[height],
		.s-editor form[name],
		.s-editor img[name],
		.s-editor *[valign],
		.s-editor *[alink],
		.s-editor *[bgcolor],
		.s-editor img[border],
		.s-editor br[clear],
		.s-editor *[color],
		.s-editor *[compact],
		.s-editor *[hspace],
		.s-editor *[language],
		.s-editor *[link],
		.s-editor *[noshade],
		.s-editor *[nowrap],
		.s-editor hr[size],
		.s-editor *[start],
		.s-editor *[vlink],
		.s-editor *[vspace],
		.s-editor *[bgproperties],
		.s-editor *[bordercolor],
		.s-editor *[bordercolordark],
		.s-editor *[bordercolorlight],
		/* Empty Elements */
		.s-editor span:empty,
		.s-editor div:empty,
		.s-editor p:empty,
		.s-editor li:empty,
		.s-editor th:empty,
		.s-editor ol:empty,
		.s-editor ul:empty,
		/* Disallowed elements */
		.s-editor applet,
		.s-editor basefont,
		.s-editor blink,
		.s-editor center,
		.s-editor dir,
		.s-editor font,
		.s-editor isindex,
		.s-editor menu,
		.s-editor nobr,
		.s-editor s,
		.s-editor strike,
		.s-editor u,
		.s-editor wbr {
			border: 3px solid red !important;
		}

/*Key value list with table*/

table.o-key-value-list--separated th:last-child,
table.o-key-value-list--separated td:last-child {
	text-align: right;
}

table.o-key-value-list--separated th,
table.o-key-value-list--separated td {
	padding: 0;
	border-bottom-width: 0;
}

.s-editor .c-checklist[class] {
	margin-left: 0;
	padding-left: 0;
}

.c-notification-badge {
	@mixin text-style-mu;
	display: inline-block;
	min-width: 25px;
	height: 25px;
	padding: 0 5px;
	border: 2px solid #fff;
	border-radius: 50px;
	background: var(--color--secondary-red);
	color: #fff;
	line-height: 22px;
	text-align: center;
}

/* Small variant
----------------------------------------------------------------------------- */
.c-notification-badge--small {
	min-width: 0;
	width: 12px;
	height: 12px;
	border: none;
}

/* Animations
----------------------------------------------------------------------------- */
.c-notification-badge--disappear {
	animation: c-notification-badge--disappear 0.4s ease-in-out forwards;
}

.c-notification-badge--appear {
	animation: c-notification-badge--appear 0.4s ease-in-out forwards;
}

@keyframes c-notification-badge--disappear {
	from {
		transform: scale(1);
	}

	to {
		transform: scale(0);
	}
}

@keyframes c-notification-badge--appear {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}

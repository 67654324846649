.c-page-tools {
    border-bottom: 1px solid var(--color--grey-2);
    color: var(--color--primary-blue);
}

.c-page-tools .c-breadcrumbs {
    display: none;
}

.c-page-tools__list {
    display: flex;
    border-top: 1px solid var(--color--grey-2);
}

.c-page-tools__item {
    width: 100%;
    text-align: center;
}

.c-page-tools__item:last-child {
    border-left: 1px solid var(--color--grey-2);
}

.c-page-tools__item-inner {
    display: inline-block;
}

.c-page-tools__link {
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 8px;
    color: var(--color--primary-blue);
    white-space: nowrap;
}

.c-page-tools__link:--hocus {
    text-decoration: underline;
}

.c-page-tools__link:focus-within {
    @mixin box-shadow-focus;
}

.c-page-tools__link--back {
    flex-direction: row-reverse;
    padding: 16px;
    white-space: inherit;
}

.c-page-tools__link--back>span {
    margin-right: 8px;
}

#print {
    display: none !important;
}

@media (--lg-xl) {
    .c-page-tools {
        display: flex;
        justify-content: space-between;
    }

    .c-page-tools .c-breadcrumbs {
        display: block;
        flex: 1 1 0px;
        padding: 16px 16px 8px;
    }

    .c-page-tools>ul {
        border: none;
    }

    .c-page-tools__item {
        width: auto;
    }

    .c-page-tools__item {
        border-left: 1px solid var(--color--grey-2);
    }

    .c-page-tools__link {
        flex-direction: row;
        padding: 16px;
    }

    .c-page-tools__icon {
        margin-left: 8px;
    }

    #page-tools-back {
        display: none;
    }

    #print {
        display: list-item !important;
    }
}

@media print {
    .c-page-tools {
        display: none;
    }
}
/* =============================================================================
 * Sub navigation
 *
 * 1. Place on top of buffer border. Might need to be a variant later on.
============================================================================= */
.c-sub-navigation {
	order:1;
	flex:1;
	margin-left: -1px; /* [1] */
}

.c-sub-navigation__link,
.c-sub-navigation__link:visited {
	@mixin text-style-rho;
	display:block;
	position: relative;
	padding: calc(var(--spacing--vertical-spacing-unit) / 3) var(--spacing--horizontal-gutter);
	border-bottom: 1px solid var(--color--grey-2);
	color: var(--color--link-color);
}

.c-sub-navigation__link:--hocus{
	text-decoration:underline;
}

.c-sub-navigation__list--level-1 > .c-sub-navigation__item {
	border-left: calc(var(--spacing--horizontal-gutter) / 2) solid transparent;
}

.c-sub-navigation__heading {
	@mixin text-style-epsilon;
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	padding-left: calc(var(--spacing--horizontal-gutter) * 1.5);
}

/* Page with expanded sub pages
----------------------------------------------------------------------------- */

.c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--primary-blue);
}

.nacka-theme-1 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded,
[class^="nacka-theme-"] .nacka-theme-1 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--primary-green);
}

.nacka-theme-2 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded,
[class^="nacka-theme-"] .nacka-theme-2 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--primary-orange);
}

.nacka-theme-3 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded,
[class^="nacka-theme-"] .nacka-theme-3 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--primary-purple);
}

.nacka-theme-4 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded,
[class^="nacka-theme-"] .nacka-theme-4 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--primary-blue);
}

.nacka-theme-5 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded,
[class^="nacka-theme-"] .nacka-theme-5 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--ybc-green);
}

.nacka-theme-6 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded,
[class^="nacka-theme-"] .nacka-theme-6 .c-sub-navigation__list--level-1 > .c-sub-navigation__item--expanded {
	border-left-color: var(--color--school-blue);
}

.c-sub-navigation__list--level-1 .c-sub-navigation__list {
	
}

.c-sub-navigation__list--level-2 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 2);
}

.c-sub-navigation__list--level-3 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 3);
}

.c-sub-navigation__list--level-4 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 4);
}

.c-sub-navigation__list--level-5 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 5);
}

.c-sub-navigation__list--level-6 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 6);
}

.c-sub-navigation__list--level-7 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 7);
}

.c-sub-navigation__list--level-8 > .c-sub-navigation__item > .c-sub-navigation__link {
	padding-left: calc(var(--spacing--horizontal-gutter) * 8);
}

/* Selected page link
----------------------------------------------------------------------------- */
.c-sub-navigation__item--has-children > .c-sub-navigation__link:after {
	display: block;
	position: absolute;
	top: 50%;
	right: calc(var(--spacing--horizontal-gutter) / 2);
	width: 12px;
	height: 12px;
	margin-top: -6px;
	background: url('../../icon/icons/untailed-arrow-right.svg') no-repeat 50% 50%;
	background-size: 5px 9px;
	content: '';
}
.c-sub-navigation__item--expanded > .c-sub-navigation__link:after {
	background: url('../../icon/icons/untailed-arrow-down.svg') no-repeat 50% 50%;
	background-size: 9px 9px;
}

.c-sub-navigation__link--selected,
.c-sub-navigation__link--selected:visited {
	background: var(--color--grey-5);
	color: black;
}


@media (--sm-md) {
	.c-sub-navigation{
		float:none;
		order:0;
		border:none;
		border-bottom:1px solid var(--color--grey-2);
	}
}

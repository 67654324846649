.c-navigation-mobile {
	display: flex;
	flex-grow: 1;
}

.c-navigation-mobile__list {
	display: flex;
	flex-direction: column;
	align-content: stretch;
	align-items: stretch;
	flex-grow: 1;
	flex-shrink: 0;
	width: 100%;
	background-color: #fff;
	text-align: left;
}

.c-navigation-mobile__supplementary {
	margin: auto 0 var(--spacing--vertical-spacing-unit);
	padding: var(--spacing--horizontal-gutter) 0;
}

.c-navigation-mobile__item {
	flex-shrink: 0;
	padding: calc(var(--spacing--horizontal-gutter)/2) 0 7px var(--spacing--horizontal-gutter);
}

.c-navigation-mobile__item--no-children,
.c-navigation-mobile__item--has-children {
	border-top: 1px solid var(--color--grey-2);
}

.c-navigation-mobile__list > .c-navigation-mobile__item:last-child {
	border-bottom: 1px solid var(--color--grey-2);
}

.c-navigation-mobile__supplementary-list-item {
	width: 100%;
	padding: 16px 0;
}

.c-navigation-mobile__supplementary-list-item span {
	padding-right: 6px;
}

.c-navigation-mobile__supplementary-list-item a {
	padding: 16px;
	color: var(--color---black-lighter);
	font-weight: 500;
	text-transform: uppercase;
}

.c-navigation-mobile__link {
	color: var(--color--link-color);
}

.c-navigation-mobile__supplementary-list-item a span {
	margin-top: -5px;
}

.c-navigation-mobile__supplementary-list-item a:--hocus {
	color: color(var(--color--link-color) shade(50%));
	text-decoration: underline;
}


.c-navigation-mobile__supplementary-list-item:not(:first-child) {
	border-top: none;
}

.c-navigation-mobile__supplementary-list {
	margin-bottom: var(--spacing--horizontal-gutter);
}

.c-navigation-mobile__list > .c-navigation-mobile__item[aria-expanded="true"] ul:last-child,
.c-navigation-mobile__sub-list > .c-navigation-mobile__item[aria-expanded="true"] ul:last-child {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit));
}

.c-navigation-mobile__item--expanded > .o-space-between {
	padding-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
}

.c-navigation-mobile__item--expanded {
	padding-bottom: 0;
}

.c-navigation-mobile__translate-link:--hocus,
.c-navigation-mobile__link:--hocus {
	text-decoration: underline;
}

.c-navigation-mobile__level-toggler {
	padding-right: calc(var(--spacing--horizontal-gutter)/2);
}

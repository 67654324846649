/* =============================================================================
 * BODY
 * This component wraps the entire page inside <body>, and is for styling that
 * can't be applied directly to the body element.
============================================================================= */
.c-body {
	position: relative;
	max-width: 100%;
	min-height: 100vh;
	width: 100vw;
	overflow-x: hidden;
}

.c-body--locked {
	height: 100vh;
	overflow: hidden;
}

/* =============================================================================
 * Completely hidden content, even for screen readers
============================================================================= */
.t-hidden {
	display:none !important;
	visibility:hidden !important;
}

.js .t-js-hidden {
	display:none;
}

@media (--sm) {
	.t-hidden\@sm {
		display:none !important;
		visibility:hidden !important;
	}
}

@media (--md) {
	.t-hidden\@md {
		display:none !important;
		visibility:hidden !important;
	}
}

@media (--lg) {
	.t-hidden\@lg {
		display:none !important;
		visibility:hidden !important;
	}
}

@media (--xl) {
	.t-hidden\@xl {
		display:none !important;
		visibility:hidden !important;
	}
}

@media print {
	.t-hidden\@print {
		display:none !important;
		visibility:hidden !important;
	}
}
